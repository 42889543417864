import { Button } from "@mui/material";
import { IoMdSave } from "react-icons/io";
import { Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "../../utils/tokenUtils";
import {
  showSuccessfulToastAlert,
  showFailedToastAlert,
} from "../../toastAlert.js/toastalert";
import { baseUrl } from "../../api/url";

const Information = ({user}) => {

  const [userInfo,setUserInfo] = useState<any>([])
  const [EmailNotification,setEmailNotification] = useState<any>([]);

  useEffect(()=>{
    axios.get(`${baseUrl}api/v1/admin/customers/${user.uid}/show` , {
      headers: {
        'Authorization': `Bearer 1|HzhREUG5vJv8zHrWPF2uJIFr5g4pwigmdmV3J1Me`
      }
    }).then(response => {
      // Handle the successful response here
      const data = response?.data;
      console.log(data.data)
      setUserInfo(data.data.customer.customer)
      setEmailNotification(JSON.parse(data.data.customer.customer.notifications))
    })
    .catch(error => {
      // Handle the error here
      console.error(error);
    });
  },[user])
  
  const [userCredential, setUserCredential] = useState({
    phone: userInfo.phone,
    company: userInfo.company,
    website: userInfo.website,
    address: userInfo.country ,
    city: userInfo.city,
    state:  userInfo.state,
    postcode: userInfo.postcode,
    country:userInfo.country,
    financial_address: userInfo.financial_address,
    financial_city: userInfo.financial_city,
    financial_postcode: userInfo.financial_postcode,
    tax_number: userInfo.tax_number,
    login: EmailNotification.login,
    senderId: EmailNotification.sender_id,
    keyword: EmailNotification.keyword,
    subscription: EmailNotification.subscription,
    promotion: EmailNotification.promotion,
    profile: EmailNotification.profile,
  });

  console.log(userInfo)

  useEffect(()=>{
    userCredential.phone= userInfo.phone
    userCredential.company= userInfo.company
    userCredential.website= userInfo.website
    userCredential.address= userInfo.country 
    userCredential.city= userInfo.city
    userCredential.state=  userInfo.state
    userCredential.postcode= userInfo.postcode
    userCredential.country=userInfo.country
    userCredential.financial_address= userInfo.financial_address
    userCredential.financial_city= userInfo.financial_city
    userCredential.financial_postcode= userInfo.financial_postcode
    userCredential.tax_number= userInfo.tax_number
    userCredential.login= EmailNotification.login==="yes"?true:false
    userCredential.senderId= EmailNotification.sender_id==="yes"?true:false
    userCredential.keyword= EmailNotification.keyword==="yes"?true:false
    userCredential.subscription= EmailNotification.subscription==="yes"?true:false
    userCredential.promotion= EmailNotification.promotion==="yes"?true:false
    userCredential.profile= EmailNotification.profile==="yes"?true:false
  },[userInfo,EmailNotification])

  const token = getToken();

  //   Handling Changes
  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setUserCredential({ ...userCredential, [name]: value });
  };

  //  Handling switches
  const handleEmail = (name: any) => {
    console.log(name);
    setUserCredential({ ...userCredential, [name]: !userCredential?.[name] });
  };

  console.log(userCredential)

  //   Handling Submit
  const handlSubmit = (event: any) => {
    event.preventDefault();
    console.log(userCredential);
    // Submitting Form

    let data = JSON.stringify({
      _token:"00UJ3kv1kBhtAq06ck8FuIvFFfUCma4S21QKem6P",
      phone: userCredential.phone,
      company: userCredential.company,
      website: userCredential.website,
      address: userCredential.country,
      city: userCredential.city,
      state:  userCredential.state,
      postcode: userCredential.postcode,
      country:userCredential.country,
      financial_address: userCredential.financial_address,
      financial_city: userCredential.financial_city,
      financial_postcode: userCredential.financial_postcode,
      tax_number: userCredential.tax_number,
      "notifications[login]": userCredential.login===true?"yes":"no",
      "notifications[sender_Id]": userCredential.senderId===true?"yes":"no",
      "notifications[keyword]": userCredential.keyword===true?"yes":"no",
      "notifications[subscription]" : userCredential.subscription===true?"yes":"no",
      "notifications[promotion]" : userCredential.promotion===true?"yes":"no",
      "notifications[profile]" : userCredential.profile===true?"yes":"no"
    });

    console.log(data)
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/users/account/update-information`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data;
        console.log(data)
        if (data.status === "success") {
          showSuccessfulToastAlert(data?.message);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.message);
        console.log(error);
      });
  };

  return (
    <div>
      <form
        action=""
        className="flex p-5 gap-10"
        id="Information form"
        onSubmit={handlSubmit}
      >
        <div className="w-1/2 flex-col flex gap-5">
          <h3>Personal Information</h3>
          <div className="flex flex-col gap-3">
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Phone
              </p>
              <input
                name="phone"
                value={userCredential.phone}
                type="tel"
                minLength={10}
                required
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
                onChange={handleChange}
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Company
              </p>
              <input
                name="company"
                value={userCredential.company}
                onChange={handleChange}
                type="text"
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Website
              </p>
              <input
                onChange={handleChange}
                type="text"
                value={userCredential.website}
                name="website"
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
          </div>
          <h3 className="mt-2">Address</h3>
          <div className="flex flex-col gap-3">
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Address
              </p>
              <input
                type="text"
                required
                value={userCredential.address}
                name="address"
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                City
              </p>
              <input
                type="text"
                name="city"
                value={userCredential.city}
                required
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                State
              </p>
              <input
                name="state"
                type="text"
                value={userCredential.state}
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Postcode
              </p>
              <input
                type="text"
                name="postcode"
                value={userCredential.financial_postcode}
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Country
              </p>
              <input
                type="text"
                required
                value={userCredential.country}
                name="country"
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex flex-col gap-5">
          <h3>Billing Address</h3>
          <div className="flex flex-col gap-3">
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Address
              </p>
              <input
                type="text"
                name="billAddress"
                value={userCredential.financial_address}
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                City
              </p>
              <input
                type="text"
                name="billCity"
                value={userCredential.financial_city}
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Postcode
              </p>
              <input
                type="text"
                name="billPostCode"
                value={userCredential.financial_postcode}
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Tax Number
              </p>
              <input
                type="text"
                name="billTaxNumber"
                value={userCredential.tax_number}
                onChange={handleChange}
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
          </div>
          <h3>Email Notifications</h3>
          <div>
            <div className="form-control w-[200px]">
              <label className="cursor-pointer label flex gap-2 justify-start">
                <input
                  type="checkbox"
                  name="login"
                  className="toggle bg-white toggle-primary"
                  checked={userCredential.login}
                  onChange={() => handleEmail("login")}
                />
                <span className="label-text">Login</span>
              </label>
              <label className="cursor-pointer label flex gap-2 justify-start">
                <input
                  type="checkbox"
                  name="senderId"
                  className="toggle bg-white toggle-primary"
                  checked={userCredential.senderId}
                  onChange={() => handleEmail("senderId")}
                />
                <span className="label-text">Sender Id</span>
              </label>
              <label className="cursor-pointer label flex gap-2 justify-start">
                <input
                  type="checkbox"
                  name="keyword"
                  className="toggle bg-white toggle-primary"
                  checked={userCredential.keyword}
                  onChange={() => handleEmail("keyword")}
                />
                <span className="label-text">Keyword</span>
              </label>
              <label className="cursor-pointer label flex gap-2 justify-start">
                <input
                  type="checkbox"
                  name="subscription"
                  className="toggle bg-white toggle-primary"
                  checked={userCredential.subscription}
                  onChange={() => handleEmail("subscription")}
                />
                <span className="label-text">Subscriptions</span>
              </label>
              <label className="cursor-pointer label flex gap-2 justify-start">
                <input
                  type="checkbox"
                  name="promotion"
                  className="toggle bg-white toggle-primary"
                  checked={userCredential.promotion}
                  onChange={() => handleEmail("promotion")}
                />
                <span className="label-text">Promotions</span>
              </label>
              <label className="cursor-pointer label flex gap-2 justify-start">
                <input
                  type="checkbox"
                  name="profile"
                  className="toggle bg-white toggle-primary"
                  checked={userCredential.profile}
                  onClick={() => handleEmail("profile")}
                />
                <span className="label-text">Profile</span>
              </label>
            </div>
          </div>
          <button
            className="self-end hover:cursor-pointer"
            id="Information form"
            type="submit"
          >
            <Stack direction="row" spacing={2}>
              <Button variant="contained" endIcon={<IoMdSave />}>
                Save
              </Button>
            </Stack>
          </button>
        </div>
      </form>
    </div>
  );
};

export default Information;
