import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { baseUrl } from "../../api/url";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../utils/tokenUtils";
import { useParams } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
import { deleteContact } from "../../api/delete-contact/DeleteContact";

const ViewContact = () => {
  const [contactsGroupsData, setContactGroupsData] = useState<any>();
  const [contactId, setContactId] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const token = getToken();
  const navigate = useNavigate();
  const route = useParams();
  const handleBackgroundClick = (event: React.MouseEvent) => {
    // Check if the click occurred on the black background
    if (event.target === event.currentTarget) {
      // Call closePopup when clicking outside of the modal
      setShowModal(false);
    }
  };
  const handleDeletion = async (e) => {
    e.preventDefault();
    const id = contactId;
    try {
      const response = await deleteContact({ id });
      if (response.data.status === "success") {
        setShowModal(false);
        showSuccessfulToastAlert(response.data.message);
        window.location.reload();
      } else {
        showFailedToastAlert(response.data.message);
      }
    } catch (error: any) {
      showFailedToastAlert(`Error Deleting ${error?.message}`);
    }
  };
  const toggleDeleteModal = (id: any) => {
    setContactId(id);
    setShowModal(true);
  };

  useEffect(() => {
    console.log("useEffect woking");
    // Getting ContactGroupsData
    let data = JSON.stringify({
      length: 1000000000,
      start: 0,
      orderBy: "id",
      orderDir: "desc",
      search: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/contacts/${route.uid}/search`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          console.log("contact data test: ", data?.data?.data);
          setContactGroupsData(data);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message);
        showFailedToastAlert(`Error getting Contact Groups ${error?.message}`);
      });
  }, []);
  const columns: any = [
    { name: "Name", selector: (datas) => datas.name },

    { name: "Phone Number", selector: (datas) => datas.phone },
    {
      name: "Edit",
      selector: (datas) => {
        console.log("datas", datas);
        return (
          <div className="my-2 flex  items-center gap-3">
            <div
              className="cursor-pointer"
              onClick={() => {
                navigate(`/contact/update/${datas.id}`);
              }}
            >
              <div className="cursor-pointer">
                <svg
                  width="20"
                  height="24"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {" "}
                  <path
                    d="M13.0207 5.82839L15.8491 2.99996L20.7988 7.94971L17.9704 10.7781M13.0207 5.82839L3.41405 15.435C3.22652 15.6225 3.12116 15.8769 3.12116 16.1421V20.6776H7.65669C7.92191 20.6776 8.17626 20.5723 8.3638 20.3847L17.9704 10.7781M13.0207 5.82839L17.9704 10.7781"
                    stroke="#008000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />{" "}
                </svg>
              </div>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                toggleDeleteModal(datas.id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#FF0000"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />{" "}
                <path
                  fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {/* modal for delete	 */}
      {showModal && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div
            className="fixed top-0 left-0 w-full h-full bg-black opacity-50"
            onClick={handleBackgroundClick}
          ></div>
          <div className="relative">
            <button
              className="absolute top-6 right-2 text-gray-600 text-xl bg-transparent"
              onClick={() => setShowModal(false)}
            >
              <RxCross1 />
            </button>
            <div className="bg-white mt-[20px]  rounded-[5px] pb-[38px]  p-12">
              <p className="text-xl">
                Are you sure you want to delete the contact?
              </p>
              <div className="mt-8">
                <button
                  className="bg-red-400 text-white font-bold max-w-[70%] sm:max-w-[38%]  px-4 py-2 rounded  "
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeletion}
                  className="bg-green-400 text-white font-bold max-w-[70%] sm:max-w-[38%] px-4 py-2 rounded  ml-4"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="my-8">
        <DataTable
          columns={columns}
          data={contactsGroupsData?.data?.data}
          pagination
          selectableRows
          className="mt-[2px]"
        />
      </div>
    </>
  );
};

export default ViewContact;
