import React from "react";
import Pagetitle from "../../reusable/pagetitle";
import LogsTransactions from "./LogsTransactions";

const Transactions = () => {
  return (
    <div className="pt-10">
      <Pagetitle title="Transactions" />

      <div className="font-extralight mt-5">
        All your
        <strong className="mx-2 text-[var(--primary-color)]">
          payment transactions
        </strong>
        are logged here. Let check them for details of your
        <strong className="mx-2 text-[var(--primary-color)]">billing</strong>
        history.
      </div>
      <LogsTransactions />
    </div>
  );
};

export default Transactions;
