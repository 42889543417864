import React, { useEffect, useState } from "react";

import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/searchicon.png";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import withAuthHeader from "../globals/Auth";
import axios from "axios";
import { apiBaseUrl } from "../globals/baseUrl";
import { Numbers } from "../redux/action/smsTemplete";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../utils/tokenUtils";
import { baseUrl } from "../api/url";
import parser from "html-react-parser";

const columns: any = [
  {
    name: "Number",
    selector: (row) => row.number,
  },
  {
    name: "Price",
    selector: (row) => row.price,
  },
  {
    name: "Status",
    selector: (row) => (
      <p
        className={` ${
          row.status === "assigned" ? "bg-green-500 " : "bg-red-500"
        } capitalize font-normal text-[12px]  text-white px-3 py-1 rounded-[5px] `}
      >
        {row.status}
      </p>
    ),
  },
  {
    name: "Capabilities",
    selector: (row) => JSON.parse(row.capabilities),
  },
  {
    name: "Action",
    selector: (row) => (
      <div className=" flex items-center justify-center ">
        <div>
          <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.625 16.5C2.14375 16.5 1.73192 16.3261 1.3895 15.9782C1.0465 15.6298 0.875 15.2111 0.875 14.7222V3.16667H0V1.38889H4.375V0.5H9.625V1.38889H14V3.16667H13.125V14.7222C13.125 15.2111 12.9538 15.6298 12.6114 15.9782C12.2684 16.3261 11.8562 16.5 11.375 16.5H2.625ZM11.375 3.16667H2.625V14.7222H11.375V3.16667ZM4.375 12.9444H6.125V4.94444H4.375V12.9444ZM7.875 12.9444H9.625V4.94444H7.875V12.9444Z"
              fill="#EF4444"
            />
          </svg>
        </div>
      </div>
    ),
  },
];

const Number = () => {
  const dispatch = useDispatch();
  const token = getToken();
  const [number, setNumber] = useState([]);
  useEffect(() => {
    // Getting Numbers
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/numbers`,
      headers: {
        Authorization: `Bearer Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data?.data;
        setNumber(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  console.log(number);

  return (
    <div className="bg-white">
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Numbers
          </h2>
        </div>
        <div className="flex gap-[15px]">
          <NavLink to="/senderid/senderidreq">
            <div className="flex items-center bg-[#003D7E] text-white gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
              <p className="text-[12px] leading-[14.06px]">Buy a Number</p>
              <img src={addnew} alt="" />
            </div>
          </NavLink>
        </div>
      </div>

      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] bg-white text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] bg-white pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      <DataTable
        columns={columns}
        data={number}
        selectableRows
        className="px-[10px]"
      />
    </div>
  );
};

export default Number;
