import React, { useEffect, useState } from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { getTransaction } from "../../../../api/transactions/FetchTransaction";

interface DataType {
  id: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const columns: ColumnsType<DataType> = [
  {
    title: "ID",
    dataIndex: "payment_transaction_id",
    key: "payment_transaction_id",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Payment Method",
    dataIndex: "payment_method",
    key: "payment_method",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Date",
    dataIndex: "created_at",
    key: "created_at",
  },
];

const Transactions: React.FC = () => {
  useEffect(() => {
    fetchTransactions();
  }, []);
  const [data, setData] = useState([]);

  const fetchTransactions = async () => {
    try {
      const response = await getTransaction({
        length: 10,
        start: 0,
        orderBy: "id",
        orderDir: "desc",
        search: "",
      });
      if (response.status === 200) {
        setData(response.data.data.data);
      }
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Table columns={columns} dataSource={data} />
    </>
  );
};

export default Transactions;
