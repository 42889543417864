import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import withAuthHeader from "../globals/Auth";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { CLIENT_RENEG_LIMIT } from "tls";
import { group } from "console";

import { useNavigate } from "react-router-dom";
import { baseUrl } from "../api/url";

const NewContactGroup = () => {
  const [groupName, setName] = useState("");
  const navigate = useNavigate();

  const submitName = async (e: any) => {
    // console.log(groupName, "hhk");
    e.preventDefault();
    const res = await axios.post(
      `${baseUrl}/contacts`,
      { name: groupName },
      { headers: withAuthHeader() }
    );

    if (res.data.status === "success") {
      toast.success(res?.data?.message);
      navigate("/contact");
    }
    // console.log(res);
  };

  return (
    <>
      <div className="bg-white mt-[20px] max-w-[549px] rounded-[5px] pb-[42px]">
        <div className="py-[23px] pl-[20px] border-b-[1px] border-[#D9D9D9] flex items-center justify-between pr-[18px]">
          <h2 className="text-[#515151] text-[16px] leading-[18.75px] font-bold">
            New Contact Group
          </h2>
        </div>
        <form action="" onSubmit={submitName}>
          <div className="pl-[37px] pr-[38px] mt-[35px]">
            <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Name*
            </p>
            <input
              type="text"
              value={groupName}
              onChange={(e) => setName(e.target.value)}
              className="border mt-[4px]  w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
            />
          </div>

          <div className="pt-[19px] pl-[40px]">
            <h2 className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal text-center">
              SETTINGS
            </h2>
            <div className="flex gap-[20px]">
              <div>
                <p className="text-[#0092D7] text-[13px] leading-[15.23px] font-bold mt-[36px]">
                  Send welcome messages ?
                </p>
                <p className="text-[12px] leading-[19.2px] text-[#5F6368] font-normal w-[423px] mt-[10px] mb-[9px]">
                  When people opt-in to your list, send them a sms welcoming
                  them to your group. You can update welcome message from{" "}
                </p>
                <p className="text-[#F1602B] text-[12px] leading-[14.06px] font-normal bg-[#F1602B1A] inline-block px-[3px] py-[4px]">
                  Contact Groups - Manage Button - Message Tab
                </p>
              </div>
              <div className="pt-[50px] pl-[150px] sm:pl-[0px]">
                <Switch defaultChecked />
              </div>
            </div>
            <div className="flex items-center">
              <div className="mt-[38px]">
                <p className="text-[#0092D7] text-[13px] leading-[15.23px] font-bold mt-[36px]">
                  Send unsubscribe notification to subscribers ?{" "}
                </p>
                <p className="text-[12px] leading-[19.2px] text-[#5F6368] font-normal w-[423px] mt-[10px] mb-[9px]">
                  Send subscribers a{" "}
                  <span className="bg-[#F1602B1A] text-[#F1602B] px-[3px] py-[4px] mr-[4px]">
                    GoodBye{" "}
                  </span>{" "}
                  sms to let them know they have unsubscribed.
                </p>
              </div>
              <div className="pt-[20px] pl-[20px]">
                <Switch defaultChecked />
              </div>
            </div>
          </div>
          <div className="bg-[#0092D7] w-[107px] h-[34px] rounded-[2px] text-white flex items-center justify-center mx-auto cursor-pointer mt-[34px]">
            <button type="submit">
              {" "}
              <p className="text-[14px] leading-[16.41px] font-normal">save</p>
            </button>
          </div>
        </form>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default NewContactGroup;
