import React, { ChangeEvent, useEffect, useState } from "react";
import addnew from "../assets/addnew.png";
import search from "../assets/searchicon.png";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import axios from "axios";
import { baseUrl } from "../api/url";
import { getToken } from "../utils/tokenUtils";
import { Button, Modal } from "antd";
import Swal from "sweetalert2";

const SenderId = () => {
  const token = getToken();

  const [senderid, setSenderId] = useState<any>([]);
  const [searchText, setSearchText] = useState<any>("");
  const [modalVisible, setModalVisible] = useState(false);

  const data = senderid
    ?.filter((val) => {
      if (searchText === "") {
        return val;
      } else if (
        val.sender_id.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return val;
      }
    })
    .map((val) => ({
      id: val.uid,
      sender_id: val.sender_id,
      frequency_amount: val.frequency_amount,
      frequency_unit: val.frequency_unit,
      status: val.status,
    }));

  // Data table colomns
  const columns: any = [
    {
      name: "Sender ID",
      selector: (row) => row.sender_id,
    },
    {
      name: "Price",
      selector: (row) => (
        <div>
          <div>Rs</div>
          <div className="flex">
            <div>{row.frequency_amount}</div>
            <div>{row.frequency_unit}</div>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className={`  ${
            row.status === "active" ? "bg-green-500 " : "bg-red-500"
          } capitalize font-normal text-[12px]  text-white px-3 py-1 rounded-[5px] w-[100px] text-center`}
        >
          {row.status}
        </p>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className=" flex text-[22px] items-center justify-center ">
          <div className="flex gap-3 items-center justify-center">
           
            <div onClick={() => handleDelete(row?.id)}>
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.625 16.5C2.14375 16.5 1.73192 16.3261 1.3895 15.9782C1.0465 15.6298 0.875 15.2111 0.875 14.7222V3.16667H0V1.38889H4.375V0.5H9.625V1.38889H14V3.16667H13.125V14.7222C13.125 15.2111 12.9538 15.6298 12.6114 15.9782C12.2684 16.3261 11.8562 16.5 11.375 16.5H2.625ZM11.375 3.16667H2.625V14.7222H11.375V3.16667ZM4.375 12.9444H6.125V4.94444H4.375V12.9444ZM7.875 12.9444H9.625V4.94444H7.875V12.9444Z"
                  fill="#EF4444"
                />
              </svg>
            </div>
          </div>
          
          {/* <Modal
            title="Confirm Deletion"
            open={modalVisible}
            onCancel={() => setModalVisible(false)}
            footer={[
              <Button key="no" onClick={() => setModalVisible(false)}>
                No
              </Button>,
              <Button key="yes" onClick={() => {
              handleDelete(row.id);
            }}>
                Yes
              </Button>,
            ]}
          >
            Are you sure you want to delete?
          </Modal>
          <AiFillDelete
            className="text-red-600"
            onClick={() => {
              handleDeleteConfirmation(row.id);
            }}
          /> */}
          {/* <AiFillEdit /> */}
        </div>
      ),
    },
  ];
const handleDeleteConfirmation = (id) =>{
setModalVisible(true);
}
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result)=>{
      if(result.isConfirmed){
        let config = {
        method: "delete",
        maxBodyLength: Infinity,
        url: `${baseUrl}/customers/senderid/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      axios
        .request(config)
        .then((response) => {
          const data = response?.data;
          if (data?.status === "success") {
            showSuccessfulToastAlert(data?.message);
            getAllSenderId();
          } else {
            showFailedToastAlert(data?.message);
          }
          setModalVisible(false);
        })
        .catch((error) => {
          showFailedToastAlert(error?.message);
          setModalVisible(false);
        });


      }
    })
    // try {
    //   let config = {
    //     method: "delete",
    //     maxBodyLength: Infinity,
    //     url: `${baseUrl}/customers/senderid/${id}`,
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "Content-Type": "application/json",
    //     },
    //   };
    //   axios
    //     .request(config)
    //     .then((response) => {
    //       const data = response?.data;
    //       if (data?.status === "success") {
    //         showSuccessfulToastAlert(data?.message);
    //         getAllSenderId();
    //       } else {
    //         showFailedToastAlert(data?.message);
    //       }
    //       setModalVisible(false);
    //     })
    //     .catch((error) => {
    //       showFailedToastAlert(error?.message);
    //       setModalVisible(false);
    //     });
    // } catch (error: any) {
    //   showFailedToastAlert(error?.message);
    //   setModalVisible(false);
    // }
  };

  // get all sender id.
  const getAllSenderId = () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/customers/senderid`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      axios
        .request(config)
        .then((response) => {
          const data = response?.data;
          if (data?.status === "success") {
            console.log(data);
            setSenderId(data.data);
          } else {
            showFailedToastAlert(data?.message);
          }
        })
        .catch((error) => {
          showFailedToastAlert(error?.message);
        });
    } catch (error: any) {
      showFailedToastAlert(error?.message);
    }
  };

  useEffect(() => {
    getAllSenderId();
  }, []);

  return (
    <div className="bg-white">
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex items-center">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Sender ID
          </h2>
        </div>
        <div className="flex gap-[15px]">
          {/* <div className="flex items-center bg-[#F1602B1A] gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px] text-[#F1602B]">
              Bulk Action
            </p>
            <img src={bulkaction} alt="" />
          </div> */}
          <NavLink to="request">
            <div className="flex items-center bg-[#003D7E] text-white gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
              <p className="text-[12px] leading-[14.06px]">
                Request for New One
              </p>
              <img src={addnew} alt="" />
            </div>
          </NavLink>
        </div>
      </div>

      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] bg-white text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="pt-[14px] pl-[14px] pb-[13px] bg-white outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data}
        selectableRows
        className="px-[10px]"
      />
    </div>
  );
};

export default SenderId;
