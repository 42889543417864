import { useState } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import usePostData from "../hooks/usePostData";
import { baseUrl } from "../api/url";
import { getToken } from "../utils/tokenUtils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import { Oval } from "react-loader-spinner";

const SenderIdreq = () => {
  const [requestedSenderId, setRequestedSenderId] = useState("");
  const [planDuration, setPlanDuration] = useState<any>();
  const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

  console.log(requestedSenderId);
  console.log(typeof Number(planDuration) + "  plan");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    console.log(requestedSenderId, planDuration);
    let Data: any = JSON.stringify({
      sender_id: requestedSenderId,
      plan: Number(planDuration),
      status: "active",
      price: 0,
      billing_cycle: "monthly",
      user_id: 2,
      currency_id: 2,
      frequency_unit: "day",
    });

    const token = getToken();
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/senderid`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: Data,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        const data: any = response.data;
        if (data.status === 200) {
          showSuccessfulToastAlert(data.message);
          navigate("/senderid");
        } else {
          showFailedToastAlert(data.message);
          
        }
      })
      .catch((error) => {
        setLoading(false);
        showFailedToastAlert(error);
      });
  };

  return (
    <div className=" h-[70vh]">
      <div className="bg-white mt-[20px] max-w-[513px] rounded-[5px] pb-[30px]">
        <ToastContainer />
        <div className="border-b-[1px] border-[#D9D9D9]">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
            Request for new one
          </h2>
        </div>
        <form action="" className="pl-[31px]" onSubmit={(e) => handleSubmit(e)}>
          <div className="mt-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Sender ID *
            </h2>
            <input
              style={{ border: "2px solid black" }}
              type="text"
              name="sender_id"
              value={requestedSenderId}
              onChange={(e) => setRequestedSenderId(e.target.value)}
              placeholder="Required"
              className="border bg-[#fff] border-r-8 pl-[14px] outline-none  h-[38px] w-[452px] mt-[4px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
            />
          </div>
          <div className="mt-[24px] w-[452px] overflow-hidden">
            <h2 className="text-[#545454]  text-[13px] leading-[15.23px] font-normal ">
              Select Plan *
            </h2>
            <select
              name="plan"
              style={{
                width: "100%",
                height: "38px",
                border: "2px solid black",
              }}
              onChange={(e: any) => {
                setPlanDuration(e.target?.value);
              }}
              className="bg-[#fff]"
            >
              <option></option>
              <option
                value={1}
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                1 year (RS)
              </option>
            </select>
          </div>

          <div className=" w-[100%] h-[34px] flex items-center justify-end text-white rounded-[2px] mt-[40px] cursor-pointer ">
            <button
              type="submit"
              className=" w-[100px] mr-4 bg-[#0092D7] h-full"
            >
              save
            </button>
          </div>
        </form>
        {loading ? (
          <div className="fullWindow-Spinner">
            <div>
              <Oval visible={loading}></Oval>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting...
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SenderIdreq;
