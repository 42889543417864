export const checkUnicode = (message: string): boolean => {
  const nepaliScriptRange: RegExp = /[\u0900-\u097F]/;

  for (let i = 0; i < message.length; i++) {
    if (nepaliScriptRange.test(message[i])) {
      return true;
    }
  }
  return false;
};
