import axios from "axios";
import { baseUrl } from "../url";
import { getToken } from "../../utils/tokenUtils";

export const sendExcelFile = async (payload) => {
  const token = getToken();
  let config = {
    method: "POST",
    url: `${baseUrl}/customers/contacts/import?type=${
      payload.isNumberOnly ? "number" : "number_name"
    }&group_id=${payload.groupId}}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
      Accept: "Application/json",
    },
    data: payload.formData,
  };
  return await axios.request(config);
};
