import React, { useState } from "react";
import { baseUrl } from "../../api/url";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../utils/tokenUtils";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";

const AddPhoneNumber = () => {
  const [phone, setPhone] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  const { uid } = useParams();
  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const token = getToken();
    const data = JSON.stringify({
      phone: phone,
      first_name: firstName,
      last_name: lastName,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/contacts/${uid}/store`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        if (data?.status === "success") {
          showSuccessfulToastAlert(data?.message);
          navigate("/contact");
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.response?.data?.message);
      });
  };
  return (
    <>
      <div className="bg-white mt-[20px] max-w-[549px] rounded-[5px] pb-[42px]">
        <div className="py-[23px] pl-[20px] border-b-[1px] border-[#D9D9D9] flex items-center justify-between pr-[18px]">
          <h2 className="text-[#515151] text-[16px] leading-[18.75px] font-bold">
            Add Phone Number
          </h2>
        </div>
        <form
          action=""
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="pl-[37px] pr-[38px] mt-[35px]">
            <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Phone*
            </p>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="border mt-[4px]  w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              required
            />
          </div>
          <div className="pl-[37px] pr-[38px] mt-[35px]">
            <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              First Name
            </p>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="border mt-[4px]  w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
            />
          </div>
          <div className="pl-[37px] pr-[38px] mt-[35px]">
            <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Last Name
            </p>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="border mt-[4px]  w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
            />
          </div>

          <div className="bg-[#0092D7] w-[107px] h-[34px] rounded-[2px] text-white flex items-center justify-center mx-auto cursor-pointer mt-[34px]">
            <button type="submit">
              {" "}
              <p className="text-[14px] leading-[16.41px] font-normal">Save</p>
            </button>
          </div>
        </form>
      </div>
      {/* <ToastContainer /> */}
    </>
  );
};

export default AddPhoneNumber;
