import { Input } from "antd";
import React from "react";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const Security = (props: any) => {
  const { handleChangePassword, userCredentials, handleChanges } = props;

  return (
    <div className="pt-[43px]">
      <form action="" onSubmit={handleChangePassword}>
        <div className="grid grid-cols-2 pb-[49px] gap-[52px]">
          <div className="grid grid-cols-1 gap-y-[21px]">
            <div className="bg-[]">
              <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                Current Password
              </p>
              {/* <input type="email" className='border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal' /> */}
              <Input.Password
                name="current_password"
                value={userCredentials.current_password}
                onChange={handleChanges}
                placeholder="current password"
                className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div>
              <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                Set New Password
              </p>
              {/* <input type="email" className='border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal' /> */}
              <Input.Password
                name="new_password"
                value={userCredentials.new_password}
                onChange={handleChanges}
                minLength={8}
                placeholder="New password"
                className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div>
              <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                Confirm New Password
              </p>
              {/* <input type="email" className='border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal' /> */}
              <Input.Password
                name="confirm_new_password"
                value={userCredentials.confirm_new_password}
                onChange={handleChanges}
                minLength={8}
                placeholder="confirm password"
                className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
            <div className="flex items-center justify-end pt-[21px]">
              <button
                type="submit"
                className="bg-[#0092D7] py-[12px] px-[26px] w-[103px] rounded-[5px] text-[13px] leading-[15.73px] font-normal text-white  inline-block   cursor-pointer"
              >
                {" "}
                Change
              </button>
            </div>
          </div>
          <div className="pt-[27px]">
            <p className="text-[#5F6368] text-[12px] leading-[21.6px] font-normal ">
              Please use a{" "}
              <span className="text-[#F1602B]  text-[12px] leading-[14.06px] font-normal py-[2px] px-[3px] bg-[#F1602B1A]">
                Combination
              </span>{" "}
              of :
            </p>
            <ul className="mt-[10px] list-disc pl-[15px] mb-[6px]">
              <li className="text-[#5F6368] text-[12px] leading-[21.6px] font-normal ">
                upper and lower case alphabets
              </li>
              <li className="text-[#5F6368] text-[12px] leading-[21.6px] font-normal ">
                Numbers
              </li>
              <li className="text-[#5F6368] text-[12px] leading-[21.6px] font-normal ">
                Special Characters{" "}
                <span className="text-[#F1602B]  text-[12px] leading-[14.06px] font-normal py-[2px] px-[3px] bg-[#F1602B1A]">
                  !@#$%^&*()_+
                </span>
              </li>
            </ul>
            <p className="text-[#5F6368] text-[12px] leading-[21.6px] font-normal ">
              for better security
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Security;
