import React, { useEffect, useState } from "react";
import Pagetitle from "../../reusable/pagetitle";
import { getToken } from "../../../utils/tokenUtils";
import axios from "axios";
import { apiBaseUrl } from "../../../globals/baseUrl";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../../toastAlert.js/toastalert";
import { baseUrl } from "../../../api/url";

const Preferences = () => {
  //getting token from localstorage
  const token = getToken();

  // state variable for Options
  const [logOptions, setlogOptions] = useState<any>();
  const [endPeriodLastDays, setendPeriodLastDays] = useState<any>();

  const [Preferences, setPrefernces] = useState<any>({
    _token: "Ra2il7PQThCG1phum8jZlMmwTAIRUGcqkdQVQV7A",
    end_period_last_days: "",
    credit: "",
    credit_notify: "",
    credit_warning: "",
    subscription_notify: "",
    subscription_warning: "",
  });

  // intialized intial value when this component renders first time
  useEffect(() => {
    setPrefernces((prev) => ({
      ...prev,
      credit: logOptions?.credit,
      credit_notify: logOptions?.credit_notify,
      credit_warning: logOptions?.credit_warning,
      end_period_last_days: endPeriodLastDays,
      subscription_notify: logOptions?.subscription_notify,
      subscription_warning: logOptions?.subscription_warning,
    }));
  }, [logOptions]);

  // handle onchange function for all the inputs fields
  const onChangeFun = (e) => {
    const { name, value } = e.target;
    setPrefernces((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // handle onchange functions for switch
  const handleSwitchChange = (name) => {
    setPrefernces((prevState) => ({
      ...prevState,
      [name]: !Preferences?.[name],
    }));
  };

  // Getting data from api when componets renders
  useEffect(() => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/customers/subscriptions/62cd65cd8cdf6/logs`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      axios.request(config).then((res) => {
        console.log(res?.data);
        setlogOptions(JSON.parse(res?.data?.data?.options));
        setendPeriodLastDays(res?.data?.data?.end_period_last_days);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // const handleClick = (e)=>{
  //   e.preventDefault()
  //   console.log(Preferences,'lol')
  // }

  // To handle prefernces-form-submission
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const data = JSON.stringify(Preferences);
      const response = await axios.post(
        `${baseUrl}/customers/subscriptions/62cd65cd8cdf6/preferences`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
  };

  console.log(logOptions, "logOptions");
  console.log(apiBaseUrl);
  // const label = { inputProps: { "aria-label": "Size switch demo" } };
  return (
    <div className="">
      <form className="flex flex-col gap-5">
        {/* Credit Warning */}
        <div className="flex justify-between">
          <div className="">
            <div className="flex items-center mb-3">
              {/* <Switch
                {...label}
                onChange={(checked)=>handleChange(checked,'credit_warning')}
                checked={Preferences.credit_warning}
              /> */}
              <input
                onChange={() => handleSwitchChange("credit_warning")}
                checked={Preferences.credit_warning}
                className="toggle bg-white toggle-bg-blue "
                type="checkbox"
              />
              <Pagetitle title="Credit Warning" />
            </div>
            <span className="">
              Send an email or SMS anytime your credit runs below the listed
              amount
            </span>
          </div>
          <div className="mt-2 flex flex-col w-1/2 mb-5">
            <p>
              When Credit falls below<sup className="text-red-500">*</sup>
            </p>
            <input
              name="credit"
              type="text"
              placeholder=""
              className="border border-[#D9D9D9] bg-white pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              value={Preferences?.credit}
              onChange={onChangeFun}
            />
            <div>
              <p>
                Notify me by<sup className="text-red-500">*</sup>
              </p>
              {/* <Select options={[{ value: "" }]} style={{ width: "100%" }} /> */}
              <select
                name="credit_notify"
                onChange={onChangeFun}
                value={Preferences?.credit_notify}
                className="w-full border outline-none border-[#D9D9D9] h-[38px] bg-white"
              >
                <option value="sms">SMS</option>
                <option value="email">EMAIL</option>
                <option value="both">BOTH</option>
              </select>
            </div>
          </div>
        </div>
        {/* Subscription Warning */}
        {/* Credit Warning */}
        <div className="flex justify-between">
          <div className="">
            <div className="flex items-center mb-3">
              {/* <Switch
                {...label}
                // onChange={(checked) =>
                //   handleChange("subscription_warning", checked)
                // }
                checked={Preferences.subscription_warning}
              /> */}
              <input
                onChange={() => handleSwitchChange("subscription_warning")}
                checked={Preferences?.subscription_warning}
                className="toggle bg-white toggle-bg-blue "
                type="checkbox"
              />
              <Pagetitle title="Subscription Warning" />
            </div>
            <span className="">
              Send an email or SMS anytime when your subscription going to be
              expired
            </span>
          </div>
          <div className="mt-2 flex flex-col w-1/2 mb-5">
            <p>
              When Credit falls below<sup className="text-red-500">*</sup>
            </p>
            <input
              name="credit"
              type="text"
              placeholder=""
              className="border bg-white border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal none"
              value={Preferences?.credit}
              onChange={onChangeFun}
            />
            <div>
              <p>
                Notify me by<sup className="text-red-500">*</sup>
              </p>
              {/* <Select options={[{ value: "" }]} style={{ width: "100%" }} /> */}
              <select
                name="subscription_notify"
                onChange={onChangeFun}
                value={Preferences?.subscription_notify}
                className="w-full border outline-none border-[#D9D9D9] h-[38px] bg-white"
              >
                <option value="sms">SMS</option>
                <option value="email">EMAIL</option>
                <option value="both">BOTH</option>
              </select>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-end">
            <button
              onClick={handleClick}
              className="flex items-center gap-2  bg-orange-600 p-2 px-4 rounded-lg text-white"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Preferences;
