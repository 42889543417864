import React from "react";
import { MdAutorenew } from "react-icons/md";
import { FaExchangeAlt } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import PlanDetails from "./subscriptionComponents/PlanDetails";
import Transactions from "./subscriptionComponents/Transactions";

const Subscription = () => {
  return (
    <div className="flex flex-col">
      {/* Current Plan */}
      <div className="flex flex-col">
        <div className="text-2xl">
          <h3 className="py-3">Current Plan</h3>
          <hr />
        </div>
        <div className="flex flex-col mt-5 text-lg">
          <div className="">
            <span>Your Current Plan is</span>
            <span className="font-extrabold ml-2">Ecommerce test API</span>
          </div>
          <div>
            <span>Active until </span>
            <span className="font-extrabold ml-2">Jan 13th, 2023 4:56 PM</span>
          </div>
          <span className="font-extralight">{`We will send you a notification upon Subscription expiration`}</span>
        </div>
        <span className="my-3">Rs Per day</span>
        <div className="flex gap-5">
          <span className="flex items-center gap-2 bg-[var(--primary-color)] p-2 px-4 rounded-lg text-white">
            <span className="text-[18px]">
              <MdAutorenew />
            </span>
            <span>Renew</span>
          </span>
          <span className="flex items-center gap-2  bg-orange-600 p-2 px-4 rounded-lg text-white">
            <span className="text-[18px]">
              <FaExchangeAlt />
            </span>
            <span>Change Plan</span>
          </span>
          <span className="flex items-center gap-2  bg-red-600 p-2 px-4 rounded-lg text-white">
            <span className="text-[18px]">
              <TiCancel />
            </span>
            <span>Cancel</span>
          </span>
        </div>
      </div>

      {/* Plan Details */}
      <PlanDetails />

      {/* Transactions */}
      <Transactions />
    </div>
  );
};

export default Subscription;
