import React from "react";
import ContactAPI from "./ContactAPI";

const DevDocuments = () => {
  const allApi = [
    "contacts",
    "contactGroups",
    "sms",
    "voice",
    "mms",
    "whatsApp",
    "profile",
  ];

  return (
    <div className="h-[100vh] w-[80rem] m-auto">
      <div className="flex flex-col items-center my-5">
        <h1 className="text-[40px]  text-[var(--primary-color)]">
          Welcome To EasySMS Docs
        </h1>
        <p className="text-[20px]">
          Get familiar with our APIs and technical resources in your favourite
          languages.
        </p>
      </div>
      <div className="flex gap-10 ">
        <div className="bg-white p-5 rounded-xl shadow-sm shadow-black">
          <h2 className="mb-2 text-[var(--primary-color)]">EASYSMS API</h2>
          <div className="flex flex-col w-[200px] opacity-60 gap-5">
            <span className="border-b-2 border-black border-opacity-8 text-orange-600">
              Contacts API
            </span>
            <span className="border-b-2 border-black border-opacity-8">
              Contact groups API
            </span>
            <span className="border-b-2 border-black border-opacity-8">
              SMS API
            </span>
            <span className="border-b-2 border-black border-opacity-8">
              Voice
            </span>
            <span className="border-b-2 border-black border-opacity-8">
              MMS API
            </span>
            <span className="border-b-2 border-black border-opacity-8">
              WhatsApp API
            </span>
            <span className="border-b-2 border-black border-opacity-8">
              Profile API
            </span>
          </div>
        </div>
        <div className="bg-white p-5 rounded-xl  shadow-sm shadow-black">
          <ContactAPI />
        </div>
      </div>
    </div>
  );
};

export default DevDocuments;
