import axios from "axios";
import { getToken } from "../../utils/tokenUtils";
import { baseUrl } from "../url";

export const getDocuments = async () => {
  const token = getToken();
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/users/documents`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return await axios.request(config);
};
