import { Image } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { setToken } from "../../redux/userSlice";
import { BsPersonFill } from "react-icons/bs";
import Balance from "./balance/Balance";
import { Button, Drawer } from "antd";
import Notification from "./Notification/Notification";

// import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { MdCommentsDisabled, MdOutlineMms } from "react-icons/md";
// import { NavLink, useNavigate } from "react-router-dom";
import { AiFillWechat } from "react-icons/ai";
import { Outlet } from "react-router-dom";
import Sending from "../SideNavigation/Sending/index";
import Reports from "../SideNavigation/Reports/index";
import SMS from "../SideNavigation/SMS/index";
import Voice from "../SideNavigation/Voice/index";
import Mms from "../SideNavigation/MMS/mms";
import WhatsApp from "../SideNavigation/Whatsapp/index";
import { HiUserGroup } from "react-icons/hi";
import { GoGraph } from "react-icons/go";
import { BsCodeSquare } from "react-icons/bs";
import { useSelector } from "react-redux";
import {
  setActiveNav,
  setDropDown,
} from "../../redux/sideNavSlice/SideNavSlice";
import { ImBlocked } from "react-icons/im";
import { showFailedToastAlert } from "../../toastAlert.js/toastalert";
import axios from "axios";
import Cookies from "js-cookie";
import { getToken } from "../../utils/tokenUtils";
import { withCookies } from "react-cookie";
import { imageBaseUrl } from "../../globals/baseUrl";
import { baseUrl } from "../../api/url";

const UpperNav = (props: any) => {
  // const dispatch = useDispatch();
  const [profileImage, setProfileImage] = useState<any>("");
  const [avatar, setAvatar] = useState("");
  const navigate = useNavigate();

  const {
    logo,
    lang,
    notification,
    showProfile,
    // profileImage,
    online,
    user,
    arrow,
    show,
    profile,
    profile2,
    biling,
    logout,
    setShow,
    setActiveSidebar,
  } = props;

  const languages = [
    "English",
    // "German",
    // "French",
    // "Portugese",
    // "Arabic",
    // "Spanish",
    // "Italian",
    // "Korean",
    // "Slovenian",
    // "Chinese",
  ];

  const [open, setOpen] = useState(false);
  // const [show, setShow] = useState(false)
  const [mobileDropDown, setMobileDropDown] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    setActiveSidebar(true);
  };

  const onClose = () => {
    setOpen(false);
    setActiveSidebar(false);
  };

  const { setDropdown, dropDown } = props;
  const {
    color,
    showDropDown,
    arrow_right,
    rotate,
    arrRef,
    dropdown,
    color2,
    sendingg,
    rotate1,
    sendings,
    color3,
    opensms,
    rotate2,
    smss,
  } = props;

  const dispatch = useDispatch();
  const token = getToken();
  const handleReportsClick = (navName: string) => {
    let value = !dropDown[`${navName}`];
    // console.log("value", value, navName);
    dispatch(
      setDropDown({
        reportDropDown: false,
        sendingDropDown: false,
        smsDropDown: false,
        voiceDropDown: false,
        mmsDropdown: false,
        whatsapp: false,
        [navName]: !dropDown?.[navName],
      })
    );
  };

  // const active = useSelector((state:any)=>state.side)
  const active = useSelector((state: any) => state?.sideNavSlice?.activeNav);

  useEffect(() => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/users/avatar`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log("Avatar", response.data);
          const data = response?.data;
          setProfileImage(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {
      showFailedToastAlert(`Getting profile image error ${error?.message}`);
    }
  }, []);

  // set profile
  useEffect(() => {
    let temp = "";
    if (profileImage.length) {
      temp = profileImage.replace("/home/smsvendor/public_html", "");
      setAvatar(temp);
    }
  }, [profileImage]);

  console.log(avatar);

  return (
    <div className="md:pl-[55px] px-[12px] md:px-0 border gap-[8px] md:gap-0 bg-white  flex items-center justify-between md:pr-[40px] sticky z-10 top-0 w-full shadow ">
      <div
        className=" border-[#EDEDED] rounded-[2px] h-[28px] w-[28px] flex items-center justify-center cursor-pointer lg:hidden mr-[10px]"
        onClick={showDrawer}
      >
        <svg
          width="22"
          height="21"
          viewBox="0 0 27 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="27" height="3" rx="1.5" fill="#97A6B7" />
          <rect y="9" width="27" height="3" rx="1.5" fill="#97A6B7" />
          <rect y="18" width="27" height="3" rx="1.5" fill="#97A6B7" />
        </svg>
      </div>
      <NavLink to="/dashboard">
        {/* <Image
          src={logo}
          alt="logo"
          className="cursor-pointer   hidden mobile:block"
        /> */}
        <img
          src={logo}
          alt="logo"
          className="cursor-pointer py-1  hidden mobile:block"
          height={100}
          width={100}
        />
      </NavLink>
      <div className="flex items-center md:gap-[24px] gap-[10px] ">
        <select className="bg-white border-[2px] border-[#22c55e] p-[5px]  rounded text-[12px] md:text-base  ">
          {languages?.map((val, index) => (
            <option key={index} selected={index === 0 ? true : false}>
              {val}
            </option>
          ))}
        </select>

        <Notification />
        <Balance />

        {/* PROFILE */}

        <div
          className="flex items-center relative cursor-pointer"
          onClick={showProfile}
        >
          {/* <div className="relative cursor-pointer"> */}
          {/* <img
              src=""
              alt="error loading"
              className="mr-[12px] w-[32px] h-[32px] rounded-full cursor-pointer"
            /> */}
          {/* </div> */}
          <p className="mr-[7px] text-[12px] leading-[14.06px] font-medium text-[#5F6368] cursor-pointer hidden md:block">
            {`${user?.first_name || ""} ${user?.last_name || ""}`}
          </p>
          {/* <div className="mr-[12px] w-[32px] h-[32px] flex items-center justify-center text-[var(--primary-color)] rounded-full cursor-pointer border-2 border-[var(--primary-color)]">
            <BsPersonFill />
          </div> */}
          <img
                      // src={`http://localhost:8000/images/profile/profile.jpg`}

            src='/test_profile.jpg'
            alt="Profile image"
            className="mr-[12px]  min-w-[32px] large_mobile:w-[32px]  h-[32px] flex items-center justify-center text-[var(--primary-color)] rounded-full cursor-pointer border-2 border-[var(--primary-color)]"
          />
          <img
            src={online}
            alt=""
            className="absolute bottom-[1px] right-[13px]"
          />
          {/* <img src={arrow} alt="" /> */}

          {show && (
            <div className="absolute top-[50px] right-[-2px] bg-white pl-[16px] py-[13px] w-[144px] flex flex-col gap-[16px] border border-[#EDEDED] rounded-[2px]">
              <div
                className="flex items-center gap-[6px] cursor-pointer "
                onClick={() => navigate("/profile")}
              >
                <img src={profile2} alt="" />

                <p className="text-[#5F6368] text-[12px] leading-[14.52px] font-normal">
                  Profile
                </p>
              </div>
              <div
                className="flex items-center gap-[6px] cursor-pointer"
                onClick={() => navigate("billing")}
              >
                <img src={biling} alt="" />
                <p className="text-[#5F6368] text-[12px] leading-[14.52px] font-normal">
                  Billing
                </p>
              </div>
              <div
                className="flex items-center gap-[6px] cursor-pointer"
                onClick={async () => {
                  await localStorage.removeItem("token");
                  await Cookies.remove("Token");
                  navigate("/login");
                }}
              >
                <img src={logout} alt="" />
                <p className="text-[#F1592A] text-[12px] leading-[14.52px] font-normal">
                  Logout
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withCookies(UpperNav);
