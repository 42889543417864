// FileUpload.tsx
import React, { useState, ChangeEvent } from "react";
import * as XLSX from "xlsx";
import { phoneRegex } from "../utils/PhoneRegex";

interface FileUploadProps {
  onFileSelect: (file: File) => void;
  setValidPhoneNumbers: (validNumbers) => void;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileSelect,
  setValidPhoneNumbers,
}) => {
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState<string[]>([]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.type === "text/plain") {
        onFileSelect(file);
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target?.result as string;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0]; // Assuming data is in the first sheet
          const worksheet = workbook.Sheets[sheetName];
          const records: any[][] = XLSX.utils.sheet_to_json(worksheet, {
            header: 1,
          });

          const phoneColumnIndex = 0; // Assuming phone numbers are in the first column

          const phoneNumbers = records
            .slice(1)
            .map((record) => record[phoneColumnIndex]);

          // Filter valid phone numbers (10 digits)
          const validNumbers = phoneNumbers.filter((phoneNumber) => {
            if (phoneNumber.length === 10) {
              return phoneNumber;
            }
          });
          const invalidNumbers = phoneNumbers.filter((phoneNumber) => {
            if (phoneNumber.length !== 10) {
              return phoneNumber;
            }
          });
          console.log("Valid Numbers", validNumbers);

          setValidPhoneNumbers(validNumbers);
          setInvalidPhoneNumbers(invalidNumbers);
        };

        reader.readAsBinaryString(file);
      }
    }
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white rounded-lg shadow mt-[20px]">
      <label className=" text-sm xl:text-base  ">
        Choose a File to Upload (Text | CSV | XLSX)
      </label>
      <input
        type="file"
        className="hidden"
        onChange={handleFileChange}
        accept=".csv, .xlsx, .txt, text/plain"
      />
      <label className="bg-blue-500 text-white py-1 px-4 text-sm rounded-md cursor-pointer hover:bg-blue-600 my-2">
        Browse
        <input
          type="file"
          className="hidden"
          onChange={handleFileChange}
          accept=".csv, .xlsx, .txt, text/plain"
        />
      </label>
      {invalidPhoneNumbers.length > 0 ? (
        <div>
          <h2 className="text-red-500">Invalid Phone Numbers:</h2>
          <p className="text-red-500">{invalidPhoneNumbers.join(", ")}</p>
        </div>
      ) : (
        <p className="text-sm text-green-500">No invalid Phonenumbers</p>
      )}
    </div>
  );
};

export default FileUpload;
