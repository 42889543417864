import { SET_BLOCK_LIST, SET_SMS_TEMPLATE } from "../constant/constant";

let initialState = {
  data: [],
};

export default function blockListReducer(state = initialState, action: any) {
  if (action.type === SET_BLOCK_LIST) {
    return {
      ...state,
      data: action.payload,
    };
  }
  return state;
}
