import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dropdown: {
    profileDropDown: false,
  },
};

export const dropDownSlice = createSlice({
  name: "dropDown",
  initialState,
  reducers: {
    setProfileDropdown: (state, action) => {
      state.dropdown = action.payload;
    },
  },
});

export const { setProfileDropdown } = dropDownSlice.actions;

export default dropDownSlice.reducer;
