import axios from "axios";
import { baseUrl } from "../url";
import { getToken } from "../../utils/tokenUtils";

export const getTemplate = async (id) => {
  const token = getToken();
  const config = {
    method: "GET",
    maxBodyLength: Infinity,
    url: `${baseUrl}/customers/templates/${id}/show`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
  };
  return await axios.request(config);
};
