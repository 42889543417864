import axios from "axios";
import { getToken } from "../../utils/tokenUtils";
import { baseUrl } from "../url";

export const storeDocuments = async (payload: any) => {
  const { businessId, businessImage, citizenshipId, citizenshipImage } =
    payload;
  const token = getToken();

  const formData = new FormData();
  formData.append("business_image", businessImage);
  formData.append("citizenship_image", citizenshipImage);
  console.log(businessImage, citizenshipImage);
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/users/documents?business_id=${businessId}&citizenship_id=${citizenshipId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  };
  return await axios.request(config);
};
