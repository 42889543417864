import React, { useEffect, useRef, useState } from "react";
import Pagetitle from "./reusable/pagetitle";
import { Input } from "antd";
import { BiCart } from "react-icons/bi";
import { getToken } from "../utils/tokenUtils";
import axios from "axios";
import { baseUrl } from "../api/url";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";

const TopUp = () => {
  const [countries, setCountries] = useState<any>([{ name: "Nepal" }]);
  const [showDetail, setShowDetail] = useState(false);
  const [userData, setUserData] = useState<any>();
  const token = getToken();
  const [inputValue, setInputValue] = useState();
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [unitPrice, setUnitPrice] = useState(0);

  const handleOptionSelect = (optionId) => {
    setSelectedPaymentOption(optionId);
    setForm((prev) => ({ ...prev, payment_methods: optionId }));
  };
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  //getting countries from admin api
  // useEffect(() => {
  //   try {
  //     let data = JSON.stringify({
  //       name: "required|unique:countries,name",
  //       iso_code: "required|min:2|max:2|unique:countries,iso_code",
  //       country_code: "required|min:1|max:3",
  //       status: "required|boolean",

  //       length: 232,
  //       start: 0,
  //       orderBy: "name",
  //       orderDir: "asc",
  //       search: "",
  //     });

  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `${baseUrl}/admin/countries/search`,
  //       headers: {
  //         Authorization: `Bearer 16|7vfYr1n7tAwftWxEjwWvtpoZcZnYQpd4dYQ7NWYd`,
  //         "Content-Type": "application/json",
  //       },
  //       data: data,
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         //   console.log(response.data);
  //         const data = response?.data;
  //         if (data?.status === "success") {
  //           console.log(data.data.data);
  //           setCountries(data?.data.data);
  //         } else {
  //           showFailedToastAlert(
  //             `Getting Countries Number error ${data?.message}`
  //           );
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(`Getting Countries Number error ${error?.message}`);
  //       });
  //   } catch (error) {}
  // }, []);

  //hide/show logic for top-up detail tab
  const [show, setShow] = useState("cart");
  const handleHide = (par) => {
    setShow(par);
  };

  //handleNextPrevBtn
  const handleBtn = (direct) => {
    if (direct === "next") {
      if (show === "cart") {
        setShow("address");
      } else if (show === "address") {
        setShow("payment");
      }
    } else {
      if (show === "address") {
        setShow("cart");
      } else if (show === "payment") {
        setShow("address");
      }
    }
  };

  //handle on submit form of unit
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${baseUrl}/users/account/top-up`,
        {
          add_unit: inputValue,
          _token: "OOfEaP31NQyM30aQKKnE0MKSMw7d58JfLSpuzJu9",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status == "success") {
        setUnitPrice(response.data.data.unit_price);
      }
      setShowDetail(!showDetail);
    } catch (error) {
      setShowDetail(false);
      showFailedToastAlert("Failed");
    }
  };

  //state of topup tab
  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    postcode: "",
    country: "Nepal",
    sms_unit: inputValue,
    payment_methods: "",
  });
  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      first_name: userData?.data.user.first_name,
      last_name: userData?.data.user.last_name,
      email: userData?.data.user.email,
      sms_unit: inputValue,
      phone: userData?.data.customer.phone,
      address: userData?.data.customer.address,
      city: userData?.data.customer.city,
      postcode: userData?.data.customer.postcode,
    }));
  }, [userData, inputValue]);
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  //onchange function for selectbox of countries
  const onChangeSelectBox = (e) => {
    setForm((prev) => {
      return {
        ...prev,
        country: e.target.value,
      };
    });
  };

  // handling form submit ----
  const handleForm2Submit = async (e) => {
    e.preventDefault();
    axios
      .post(
        `${baseUrl}/users/account/pay-top-up`,
        { ...form },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (form.payment_methods == "khalti") {
          const paymentUrl = response.data.data.payment_url;
          window.location.href = paymentUrl;
        } else {
          showSuccessfulToastAlert(response.data.message);
        }
      })
      .catch((e) => {
        showFailedToastAlert(e.message);
      });
  };

  useEffect(() => {
    axios
      .get(`${baseUrl}/users/account`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the successful response here
        const data = response?.data;
        console.log("top-up",response)
        setUserData(data);
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });
  }, []);
  const paymentOptions = [
    { id: "offline_payment", name: "Offline Payment", icon: "credit-card" },
    { id: "khalti", name: "Khalti", icon: "Khalti" },
    // Add more payment options as needed
  ];

  return (
    <div className="h-[70vh] ">
      {!showDetail && (
        <div className="w-[600px] shadow-sm shadow-black p-5 mt-10 rounded-lg">
          <Pagetitle title="Add Unit to your account" />
          <span>
            Please use only
            <span className="ml-2 text-[#d63384] bg-[#eee] p-x-1">numeric</span>
            <span className="ml-2">number not</span>
            <span className="ml-2 text-[#d63384] bg-[#eee] p-x-1">decimal</span>
            <span className="ml-2">number. Example: Accepted:</span>
            <span className="ml-2 text-[#d63384] bg-[#eee] p-x-1">1, 2, 3</span>
            <span className="ml-2">Not Accepted:</span>
            <span className="ml-2 text-[#d63384] bg-[#eee] p-x-1">
              0.025, 1.025, 1.00, 1.5
            </span>
            <br />
            <br />
            <span className="">
              Per unit price = Rs {userData?.data.unit_price ?? "0"}{" "}
              <sup className="text-red-600">*</sup>
            </span>
          </span>
          <form action="" onSubmit={(e) => handleFormSubmit(e)}>
            <div className="relative">
              <Input
                name="units"
                placeholder=""
                onChange={(e) => handleChange(e)}
                required
              />
              <span className="absolute right-2 top-1.5 ">{inputValue} Rs</span>
            </div>
            <button
              type="submit"
              className="flex items-center gap-2 mt-2 bg-orange-600 w-[130px] py-2 px-4 text-white rounded-md cursor-pointer"
            >
              <BiCart className="text-md" />
              Checkout
            </button>
          </form>
        </div>
      )}

      {showDetail && (
        <div>
          <div className="">
            <div className="flex gap-4 mt-8 md:flex-row ml-4 flex-wrap">
              <div className="" onClick={() => handleHide("cart")}>
                <div className="bg-[white] text-[grey] flex justify-center items-center gap-2 cursor-pointer">
                  <span
                    className={`${
                      show !== "cart" ? "bg-[#c9c8ff]" : "bg-[#14169c]"
                    } w-[40px] flex justify-center items-center rounded`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="40"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-shopping-cart font-medium-3"
                    >
                      <circle cx="9" cy="21" r="1"></circle>
                      <circle cx="20" cy="21" r="1"></circle>
                      <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                    </svg>
                  </span>
                  <div className="">
                    <p className="">
                      {" "}
                      <b>Cart</b>{" "}
                    </p>
                    <p className="text-sm">Top up</p>
                  </div>
                  <div className="line text-[#5639d4]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-chevron-right font-medium-2"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </div>
              </div>

              <div
                className="step"
                data-target="#address"
                role="tab"
                id="address-trigger"
                onClick={() => handleHide("address")}
              >
                <div className="bg-[white] text-[grey] flex justify-center items-center gap-2 cursor-pointer">
                  <span
                    className={`${
                      show !== "address" ? "bg-[#c9c8ff]" : "bg-[#14169c]"
                    } w-[40px] flex justify-center items-center rounded`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="40"
                      width="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-map-pin font-medium-3"
                    >
                      <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                      <circle cx="12" cy="10" r="3"></circle>
                    </svg>
                  </span>
                  <div className="">
                    <p className="">
                      <b>Address</b>
                    </p>
                    <p className="text-sm">Billing Address</p>
                  </div>
                  <div className="line text-[#87CEEB]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-chevron-right font-medium-2"
                    >
                      <polyline points="9 18 15 12 9 6"></polyline>
                    </svg>
                  </div>
                </div>
              </div>

              <div
                className="step"
                data-target="#payment"
                role="tab"
                id="payment-trigger"
                onClick={() => handleHide("payment")}
              >
                <div className="bg-[white] text-[grey] flex justify-center items-center gap-2 cursor-pointer">
                  <span
                    className={`${
                      show !== "payment" ? "bg-[#c9c8ff]" : "bg-[#14169c]"
                    } w-[40px] flex justify-center items-center rounded`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="40"
                      width="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="feather feather-credit-card font-medium-3"
                    >
                      <rect
                        x="1"
                        y="4"
                        width="22"
                        height="16"
                        rx="2"
                        ry="2"
                      ></rect>
                      <line x1="1" y1="10" x2="23" y2="10"></line>
                    </svg>
                  </span>
                  <div className="">
                    <p className="">
                      {" "}
                      <b>Payment</b>{" "}
                    </p>
                    <p className="text-sm">Pay Payment</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bs-stepper-content">
              {show === "cart" && (
                <div
                  id="cart"
                  className=" mt-6 bg-[white] shadow-sm shadow-black"
                  role="tabpanel"
                  aria-labelledby="cart-trigger"
                >
                  <div className="pt-4 pl-4">
                    <h5 className="mb-0 text-[grey]">
                      <b>Top up</b>
                    </h5>
                    <small>Top up Your Account</small>
                  </div>
                  <div className="row pb-1 pt-4 pl-4">
                    <h5 className="text-[grey]">
                      <b>
                        {" "}
                        Add Unit:{" "}
                        <span className="text-primary">{inputValue}</span>
                      </b>
                    </h5>
                    <h5 className="text-[grey]">
                      <b>
                        {" "}
                        Price:{" "}
                        <span className="text-primary">{unitPrice!} Rs </span>
                      </b>
                    </h5>
                  </div>

                  <div className="flex px-4 justify-between py-4">
                    <button className="btn text-[white]  float-left" disabled>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather  feather-arrow-left align-middle me-sm-25 me-0"
                      >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="12 19 5 12 12 5"></polyline>
                      </svg>
                      <span className="align-middle d-sm-inline-block d-none">
                        Previous
                      </span>
                    </button>
                    <button
                      className="btn btn-primary  float-right"
                      onClick={() => handleBtn("next")}
                      type="button"
                    >
                      <span className="align-middle d-sm-inline-block d-none">
                        Next
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather  feather-arrow-right align-middle ms-sm-25 ms-0"
                      >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              {show === "address" && (
                <div
                  id="address"
                  className=" mt-6 bg-[white] shadow-sm shadow-black"
                  role="tabpanel"
                  aria-labelledby="address-trigger"
                >
                  <div className="pt-4 pl-4">
                    <h5 className="mb-0 text-[grey]">
                      <b>Address</b>
                    </h5>
                    <small>Billing Address</small>
                  </div>
                  <form
                    className="mx-2"
                    onSubmit={(e) => {
                      handleBtn("next");
                    }}
                  >
                    <div className="bottom my-4  p-2 flex flex-col md:flex-row flex-wrap">
                      <div className="left md:w-[50%] w-[100%] md:pr-2">
                        <div className="flex flex-col mb-[16px] ">
                          <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                            First Name<span className="text-red-600"> *</span>
                          </label>
                          <input
                            type="text"
                            name="first_name"
                            value={form.first_name}
                            onChange={(e) => handleFormChange(e)}
                            required
                            className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                          />
                        </div>

                        <div className="flex flex-col  mb-[16px]  ">
                          <div className="  flex flex-col">
                            <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                              Email<span className="text-red-600"> *</span>
                            </label>
                            <input
                              type="email"
                              name="email"
                              value={form.email}
                              required
                              onChange={(e) => handleFormChange(e)}
                              className="rounded-[0.357rem] relative text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem]"
                            />
                          </div>
                        </div>
                        <div className="flex flex-col mb-[16px]  ">
                          <div className=" flex flex-col">
                            <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                              Address<span className="text-red-600"> *</span>
                            </label>
                            <input
                              type="text"
                              name="address"
                              value={form.address}
                              onChange={(e) => handleFormChange(e)}
                              required
                              className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] pr-[2.5rem] "
                            />
                          </div>
                        </div>
                        <div className="flex flex-col mb-[16px] ">
                          <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                            Postcode
                          </label>
                          <input
                            type="text"
                            name="postcode"
                            value={form.postcode}
                            onChange={(e) => handleFormChange(e)}
                            className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                          />
                        </div>
                      </div>
                      <div className="Right md:w-[50%] w-[100%] md:pl-2">
                        <div className="flex flex-col mb-[16px] ">
                          <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            value={form.last_name}
                            onChange={(e) => handleFormChange(e)}
                            className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                          />
                        </div>
                        <div className="flex flex-col mb-[16px] ">
                          <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                            Phone<span className="text-red-600"> *</span>
                          </label>
                          <input
                            type="number"
                            name="phone"
                            value={form.phone}
                            onChange={(e) => handleFormChange(e)}
                            required
                            className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                          />
                        </div>

                        <div className="flex flex-col mb-[16px] ">
                          <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                            City<span className="text-red-600"> *</span>
                          </label>
                          <input
                            type="text"
                            name="city"
                            value={form.city}
                            onChange={(e) => handleFormChange(e)}
                            required
                            className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem] "
                          />
                        </div>

                        <div className="flex flex-col mb-[16px] ">
                          <label className="text-[0.857rem] text-[#5e5873] mb-[0.2857rem] ">
                            Country<span className="text-red-600"> *</span>
                          </label>
                          <select
                            onChange={(e) => onChangeSelectBox(e)}
                            className="rounded-[0.357rem] text-[#6e6b7b] border border-[#6e6b7b] outline-[#f08b38] py-[8px] px-[1rem]"
                          >
                            {countries?.map((val, key) => (
                              <option
                                value={val?.name}
                                selected={
                                  form.country === val.name ? true : false
                                }
                              >
                                {val?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="flex px-4 justify-between py-4">
                      <button
                        className="btn btn-primary btn-prev waves-effect waves-float waves-light"
                        onClick={() => handleBtn("prev")}
                        type="button"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather  feather-arrow-left align-middle me-sm-25 me-0"
                        >
                          <line x1="19" y1="12" x2="5" y2="12"></line>
                          <polyline points="12 19 5 12 12 5"></polyline>
                        </svg>
                        <span className="align-middle d-sm-inline-block d-none">
                          Previous
                        </span>
                      </button>
                      <button
                        className="btn btn-primary btn-next waves-effect waves-float waves-light"
                        type="submit"
                      >
                        <span className="align-middle d-sm-inline-block d-none">
                          Next
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-arrow-right align-middle ms-sm-25 ms-0"
                        >
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                          <polyline points="12 5 19 12 12 19"></polyline>
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {show === "payment" && (
                <div
                  id="payment"
                  className=" mt-6 bg-[white] shadow-sm shadow-black"
                  role="tabpanel"
                  aria-labelledby="payment-trigger"
                >
                  {/* <div className="pt-4 pl-4">
                    <h5 className="mb-0 text-[grey]">
                      <b>Payment Options</b>
                    </h5>
                    <small>Be sure to click on correct payment option</small>
                  </div>
                  <div className=" pt-4 pl-4  ">
                    <ul className=" list-unstyled flex flex-col gap-2">
                      <li className="py-50">
                        <div className="">
                          <input
                            type="radio"
                            name="payment_methods"
                            id="paypal"
                            className=""
                          />
                          <label className=""> PayPal </label>
                        </div>
                      </li>
                      <li className="py-50">
                        <div className="form-check">
                          <input
                            type="radio"
                            name="payment_method"
                            value="braintree"
                            className=""
                          />
                          <label className=""> Braintree </label>
                        </div>
                      </li>
                      <li className="py-50">
                        <div className="form-check">
                          <input
                            type="radio"
                            name="payment_method"
                            value="offline_payment"
                            className=""
                          />
                          <label className=""> Offline Payment </label>
                        </div>
                      </li>
                      <li className="py-50">
                        <div className="form-check">
                          <input
                            type="radio"
                            name="payment_method"
                            value="khalti"
                            className=""
                          />
                          <label className="">Khalti</label>
                        </div>
                      </li>
                    </ul>
                  </div> */}

                  <div className="bg-white rounded-lg p-6 shadow-md w-80">
                    <h2 className="text-xl font-semibold mb-4">
                      Select a Payment Option
                    </h2>
                    <ul className="space-y-2">
                      {paymentOptions.map((option) => (
                        <li
                          key={option.id}
                          className={`flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-pointer ${
                            selectedPaymentOption === option.id
                              ? "bg-blue-100"
                              : ""
                          }`}
                          onClick={() => handleOptionSelect(option.id)}
                        >
                          <span
                            className={`text-lg ${
                              selectedPaymentOption === option.id
                                ? "text-blue-500"
                                : ""
                            }`}
                          >
                            <i className={`fab fa-${option.icon}`}></i>
                          </span>
                          <span
                            className={`text-base font-medium ${
                              selectedPaymentOption === option.id
                                ? "text-blue-500"
                                : ""
                            }`}
                          >
                            {option.name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex px-4 justify-between py-4">
                    <button
                      className="btn btn-primary btn-prev waves-effect waves-float waves-light"
                      onClick={() => handleBtn("prev")}
                      type="button"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-arrow-left align-middle me-sm-25 me-0"
                      >
                        <line x1="19" y1="12" x2="5" y2="12"></line>
                        <polyline points="12 19 5 12 12 5"></polyline>
                      </svg>
                      <span className="align-middle d-sm-inline-block d-none">
                        Previous
                      </span>
                    </button>
                    <input type="hidden" value="1" name="sms_unit" />
                    <button
                      className="btn btn-success btn-submit waves-effect waves-float waves-light"
                      onClick={(e) => {
                        handleForm2Submit(e);
                        setShowDetail(false);
                        setShow("cart");
                      }}
                    >
                      Checkout
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopUp;
