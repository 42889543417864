import axios from "axios";
import { baseUrl } from "../url";
import { getToken } from "../../utils/tokenUtils";

export const getStatistics = async () => {
  const token = getToken();
  const config = {
    method: "get",
    maxBodyLength: Infinity,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    url: `${baseUrl}/customers/dashboard/statistics`,
  };
  return await axios.request(config);
};
