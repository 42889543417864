import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdAddCircle } from "react-icons/io";
import { HiServer } from "react-icons/hi";
import { GrView } from "react-icons/gr";
import { getToken } from "../utils/tokenUtils";
import { baseUrl } from "../api/url";

const Developers = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white shadow my-10 px-[20px] rounded-lg w-full lg:w-[80%] xl:w-[60%]">
      <div className="py-10">
        <div className="md:flex gap-5 mb-5 items-center ">
          <span className="bg-[var(--primary-color)] max-w-[250px] text-white rounded-sm px-5 py-2 flex items-center gap-2 mb-2 md:mb-0">
            <IoMdAddCircle />
            <span>ReGenerate Token</span>
          </span>
          <span className="bg-green-500 text-white max-w-[250px] rounded-sm px-5 py-2 flex items-center gap-2 mb-2 md:mb-0">
            <HiServer />
            <span>Sending Server</span>
          </span>
          <span
            className="border-2 text-orange-400 max-w-[250px] border-orange-400 cursor-pointer rounded-sm px-5  py-2 flex items-center gap-2"
            onClick={() => navigate("/docs")}
          >
            <span className="text-white">
              <GrView />
            </span>
            <span>Read the Docs</span>
          </span>
        </div>
        <hr className="" />
        <div className="pt-4 ">
          <p className="font-light ">API Endpoint</p>
          <div className="text-blue-400 text-[12px] large_mobile:text-sm">
            <Link to={baseUrl}>{baseUrl}</Link>
          </div>
          <p className="font-light mt-2">API Token</p>
          <p className="text-blue-400   text-[12px] large_mobile:text-sm">
            {getToken()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Developers;
