import axios from "axios";

export const apiBaseUrl = "https://smsvendor.com/";
export const imageBaseUrl = "https://smsvendor.com/avatar";
let token = localStorage.getItem("token");

export const api = axios.create({
  baseURL: "https://smsvendor.com/",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
