import React, { useEffect, useRef, useState } from "react";
import { baseUrl } from "../../api/url";
import { getToken } from "../../utils/tokenUtils";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
import { useNavigate } from "react-router-dom";
import { profile } from "console";
import ImageUploadComponent from "./ImageUploadComponent";

const Account = (props: any) => {
  const [image, setImage] = useState<any>();
  const [preview, setPreview] = useState<any>();
  const [avatarImage, setAvatarImage] = useState<string>("");
  const { handleChanges, user } = props;
  const fileInputRef: any = useRef<HTMLInputElement>();
  const token = getToken();

  // useEffect(() => {
  //   if (user.photo) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setPreview(reader?.result as string);
  //     };
  //     const fileBlob = new Blob([user.photo], { type: user.photo.type });
  //     reader.readAsDataURL(fileBlob);
  //   } else {
  //     setPreview(undefined);
  //   }
  // }, [user.photo]);
  // useEffect(() => {
  //   getAvatar();
  // }, []);

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader?.result as string);
      };
      reader?.readAsDataURL(image);
    } else {
      setPreview(null);
    }
  }, [image]);
  console.log("user", user);
  const HandleImageChange = (e: any) => {
    e.preventDefault();
    console.log(user);
    // Updating Profile
    const fileInput: any = document.getElementById("file-input");

    const file = image;
    if (file === undefined) {
      showFailedToastAlert("Please Select File");
      return;
    }
    const formData = new FormData();
    const reader: any = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const blob = new Blob([reader?.result], { type: file.type });
      formData.append("image", blob, file.name);
      axios
        .post(
          `${baseUrl}/users/avatar`,
          { image: file },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
          }
        )
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

  const handleProfileUpdateClick = async (e: any) => {
    e.preventDefault();
    const data = JSON.stringify({
      ...user,
      _method: "PATCH",
      _token: "Lx6p4zeWtWwrK9VQAO2ZiliXsKNviJZIZDgh1DuT",
    });
    try {
      const response = await axios.post(
        `${baseUrl}/users/account/update`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      showSuccessfulToastAlert(response?.data.message);
    } catch (error) {
      showFailedToastAlert(error);
    }
  };

  return (
    <form onSubmit={handleProfileUpdateClick}>
      <div className="pl-[6px] pb-[22px]">
        <div>
          {/* <div className="flex items-center gap-[30px]">
            <div>
              {preview ? (
                <img
                  src={preview}
                  alt="profile_image"
                  className=" rounded-full h-28 w-28"
                  // style={{ objectFit: "cover" }}
                />
              ) : (
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    fileInputRef.current.click();
                  }}
                  className="bg-gradient-to-t from-orange-600 to-slate-[var(--primary-color)] rounded-full h-28 w-28"
                >
                  {/* Add Image */}
          {/* </button>
              )}
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                ref={fileInputRef}
                onChange={(event: any) => {
                  const file = event?.target?.files[0];
                  if (file && file?.type?.substr(0, 5) === "image") {
                    setImage(file);
                  } else {
                    setImage(null);
                  }
                }}
              />
            </div>

            <div>
              <div className="flex items-center gap-[9px]">
                <div
                  className="   bg-[#003D7E] rounded-[3px] text-[14px] leading-[14.06px] font-normal text-white px-[13px] py-[7px]  hover:cursor-pointer"
                  onClick={HandleImageChange}
                >
                  <label
                    htmlFor="imgd"
                    className=" flex justify-center items-center gap-2 hover:cursor-pointer"
                  >
                    <span>
                      <svg
                        width="9"
                        height="10"
                        viewBox="0 0 9 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.875 6.94678V8.63428H1.125V6.94678H0V8.63428C0 9.25303 0.50625 9.75928 1.125 9.75928H7.875C8.49375 9.75928 9 9.25303 9 8.63428V6.94678H7.875ZM1.6875 3.57178L2.48062 4.3649L3.9375 2.91365V7.50928H5.0625V2.91365L6.51938 4.3649L7.3125 3.57178L4.5 0.759277L1.6875 3.57178Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <div onClick={HandleImageChange}> upload</div>
                  </label>  */}

          {/* <input
                    id="imgd"
                    type="file"
                    className="hidden"
                    // onChange={handleImage}
                  /> */}
          {/* </div>
                <div className="flex items-center bg-[#F1602B1A] gap-[8px] py-[7.26px]  pl-[13px] pr-[12px] rounded-[3px] hover:cursor-pointer">
                  <svg
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 9.75928C1.225 9.75928 0.989667 9.66144 0.794 9.46578C0.598 9.26978 0.5 9.03428 0.5 8.75928V2.25928C0.358333 2.25928 0.2395 2.21144 0.1435 2.11578C0.0478334 2.01978 0 1.90094 0 1.75928C0 1.61761 0.0478334 1.49878 0.1435 1.40278C0.2395 1.30711 0.358333 1.25928 0.5 1.25928H2.5C2.5 1.11761 2.548 0.998777 2.644 0.902777C2.73967 0.807111 2.85833 0.759277 3 0.759277H5C5.14167 0.759277 5.2605 0.807111 5.3565 0.902777C5.45217 0.998777 5.5 1.11761 5.5 1.25928H7.5C7.64167 1.25928 7.76033 1.30711 7.856 1.40278C7.952 1.49878 8 1.61761 8 1.75928C8 1.90094 7.952 2.01978 7.856 2.11578C7.76033 2.21144 7.64167 2.25928 7.5 2.25928V8.75928C7.5 9.03428 7.40217 9.26978 7.2065 9.46578C7.0105 9.66144 6.775 9.75928 6.5 9.75928H1.5ZM1.5 2.25928V8.75928H6.5V2.25928H1.5ZM2.5 7.25928C2.5 7.40094 2.548 7.51961 2.644 7.61528C2.73967 7.71128 2.85833 7.75928 3 7.75928C3.14167 7.75928 3.2605 7.71128 3.3565 7.61528C3.45217 7.51961 3.5 7.40094 3.5 7.25928V3.75928C3.5 3.61761 3.45217 3.49878 3.3565 3.40278C3.2605 3.30711 3.14167 3.25928 3 3.25928C2.85833 3.25928 2.73967 3.30711 2.644 3.40278C2.548 3.49878 2.5 3.61761 2.5 3.75928V7.25928ZM4.5 7.25928C4.5 7.40094 4.548 7.51961 4.644 7.61528C4.73967 7.71128 4.85833 7.75928 5 7.75928C5.14167 7.75928 5.2605 7.71128 5.3565 7.61528C5.45217 7.51961 5.5 7.40094 5.5 7.25928V3.75928C5.5 3.61761 5.45217 3.49878 5.3565 3.40278C5.2605 3.30711 5.14167 3.25928 5 3.25928C4.85833 3.25928 4.73967 3.30711 4.644 3.40278C4.548 3.49878 4.5 3.61761 4.5 3.75928V7.25928Z"
                      fill="#F1602B"
                    />
                  </svg>
                  <p className="text-[#F1602B] text-[12px] leading-[14.06px] font-normal ">
                    Delete
                  </p>
                </div>
              </div>
              <div className="bg-[#F9F9F9] rounded-[5px] py-[4px] pl-[7px] pr-[31px] mt-[10px]">
                <p className="text-[#5F6368] text-[12px] leading-[16.8px] font-normal">
                  Recommended Size:
                </p>
                <p className="text-[#5F6368] text-[12px] leading-[16.8px] font-normal">
                  {" "}
                  Width: 300px, Height 300px
                </p>
              </div>
            </div>
          </div> */}
          <ImageUploadComponent />
          {/* this section is for Below Form ie. Except Image upload */}

          <div className=" mt-[57px] pr-[62px]">
            <div className="grid grid-cols-2 gap-x-[33px] gap-y-[30px]">
              <div>
                <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                  Email
                </p>
                <input
                  type="email"
                  name="email"
                  defaultValue={user?.email}
                  className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                  onChange={handleChanges}
                />
              </div>
              <div>
                <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                  Timezone
                </p>
                <input
                  type="text"
                  name="timezone"
                  defaultValue={user?.timezone}
                  // value={user?.timezone}
                  className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                  onChange={handleChanges}
                />
              </div>
              <div className="grid grid-cols-2 gap-[30px]">
                <div>
                  <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                    First name
                  </p>
                  <input
                    type="text"
                    name="first_name"
                    defaultValue={user?.first_name}
                    // value={user?.first_name}
                    className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                    onChange={handleChanges}
                  />
                </div>
                <div>
                  <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                    Last name
                  </p>
                  <input
                    type="text"
                    name="last_name"
                    value={user?.last_name}
                    className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                    onChange={handleChanges}
                    // value={user?.last_name}
                  />
                </div>
              </div>
              <div>
                <p className="text-[#5F6368] text-[13px] leading-[18.2px] font-normal ">
                  language
                </p>
                <select
                  onChange={handleChanges}
                  name="language"
                  id=""
                  onSelect={handleChanges}
                  defaultValue="en"
                  className="border hover:border-[#D9D9D9] bg-[#EEEEEE] pl-[14px] outline-none h-[46px] w-full mt-[10px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
                >
                  <option selected value="en">
                    English
                  </option>
                  <option value="de">German</option>
                  <option value="fr">French</option>
                  <option value="pt">Portuguese</option>
                  <option value="ar">Arabic</option>
                  <option value="es">Spanish</option>
                  <option value="it">Italian</option>
                  <option value="ko">Korean</option>
                  <option value="sl">Slovenian</option>
                  <option value="zh">Chinese</option>
                </select>
              </div>
            </div>
            <div className="flex items-center justify-end pt-[41px]">
              <button
                className="bg-[#0092D7] py-[12px] px-[26px] w-[140px] rounded-[5px] text-[13px] leading-[15.73px] font-normal text-white  inline-block   cursor-pointer"
                type="submit"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Account;
