import axios from "axios";
import { baseUrl } from "../../api/url";
import { getToken } from "../../utils/tokenUtils";
import { showFailedToastAlert } from "../../toastAlert.js/toastalert";

export const getData = (
 
  setAllData: (data) => void,

) => {
  const token = getToken();
  let data = JSON.stringify({
    length: 100000,
    start: 0,
    orderBy: "id",
    orderDir: "DESC",
    search: "",
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/customers/eom_records/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      const data = response?.data;
      console.log("searchData", data);
      if (data?.status === "success") {
        setAllData(data);
      } else {
        showFailedToastAlert(data?.message);
      }
    })
    .catch((error) => {
      console.log(error);
      showFailedToastAlert(error?.message);
    });
};
