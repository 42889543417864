import { getToken } from "../../../utils/tokenUtils";
const Statistics = (props: any) => {
  const { statisticsData } = props;

  return (
    <div className="bg-white p-5 rounded-xl  shadow">
      <p className="text-[18px] white leading-[25.6px] text-[#5F6368] pb-[10px] font-extrabold text-[var(--primary-color)] ">
        Statistics
      </p>
      <div className="grid 2xl:grid-cols-4  gap-y-4 large_mobile:gap-y-8 gap-x-12  large_mobile:grid-cols-2 py-2 ">
        <div className="flex items-center  cursor-pointer relative">
          <div className="w-[106px]">
            <p className="text-[12px] xl:text-[14px] leading-[22.4px] font-normal text-[#5F6368] ">
              Contact Groups
            </p>
          </div>
          <div className="w-[36px] h-[36px] bg-[#E6F7FF] rounded-full flex items-center justify-center ml-10 large_mobile:ml-0">
            <p className="text-[14px] leading-[33.6px] text-[#0092D7]  font-normal">
              {statisticsData?.contactGroups ?? "0"}
            </p>
          </div>
        </div>

        <div className="flex items-center  cursor-pointer relative">
          <div className="w-[106px]">
            <p className="text-[12px] xl:text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
              Contacts
            </p>
          </div>
          <div className="w-[36px] h-[36px] bg-[#E5F8ED] rounded-full flex items-center justify-center ml-10 large_mobile:ml-0">
            <p className="text-[14px] leading-[33.6px] text-[#24E073]  font-normal">
              {statisticsData?.contact ?? "0"}
            </p>
          </div>
        </div>
        <div className="flex items-center  cursor-pointer relative">
          <div className="w-[106px]">
            <p className="text-[12px] xl:text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
              Blacklist
            </p>
          </div>
          <div className="w-[36px] h-[36px] bg-[#FCEAEA] rounded-full flex items-center justify-center ml-10 large_mobile:ml-0">
            <p className="text-[14px] leading-[33.6px] text-[#E12D2D]  font-normal">
              {statisticsData?.blacklists ?? "0"}
            </p>
          </div>
          </div>
        <div className="flex items-center  cursor-pointer">
          <div className="w-[106px]">
            <p className="text-[12px] xl:text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
              SMS Template
            </p>
          </div>
          <div className="w-[36px] h-[36px] bg-[#FFF2E6] rounded-full flex items-center justify-center ml-10 large_mobile:ml-0">
            <p className="text-[14px] leading-[33.6px] text-[#F79236]  font-normal">
              {statisticsData?.templates ?? "0"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
