import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getToken } from "../../utils/tokenUtils";
import { showFailedToastAlert } from "../../toastAlert.js/toastalert";
import { baseUrl } from "../../api/url";

const Notification = (props: any) => {
  const { search, data, columns } = props;

  const [notification, setNotification] = useState<any>([]);
  console.log(notification);
  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "message",
        orderDir: "asc",
        search: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/users/account/notifications`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response);
          const data = response?.data;
          console.log(data);
          if (data?.status === "success") {
            console.log(data);
            setNotification(data?.data);
          } else {
            showFailedToastAlert(`Getting Notification error ${data?.message}`);
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);
  return (
    <div>
      <div className="flex  justify-between pl-[37px] mt-[0px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border bg-white w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            type="text"
            placeholder="Search"
            className="pt-[14px] bg-white pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>
      {notification ? (
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          className="px-[10px]"
        />
      ) : (
        <>
          <hr />
          <div className="text-[#5F6368] p-5 text-[13px] font-normal text-center">
            No Results Available
          </div>
        </>
      )}
    </div>
  );
};

export default Notification;
