import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phoneNumbers: [],
};

const sendSlice = createSlice({
  name: "sendSlice",
  initialState,
  reducers: {
    setPhoneNumbers: (state, action) => {
      state.phoneNumbers = action.payload;
    },
  },
});

export const { setPhoneNumbers } = sendSlice.actions;

export default sendSlice.reducer;
