import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  pageOnScreen: {
    dashboard: true,
    reports: false,
    allMessages: false,
    inbox: false,
    sent: false,
    campaigns: false,
    contactGroups: false,
    senderId: false,
    numbers: false,
    smsTemplates: false,
    blacklist: false,
    smsCampaignBuilder: false,
    smsQuickSend: false,
    smsSendFile: false,
    voiceCampaignBuilder: false,
    voiceQuickSend: false,
    voiceSendFile: false,
    mmsCampaignBuilder: false,
    mmsQuickSend: false,
    mmsSendFile: false,
    whatsAppCampaignBuilder: false,
    whatsAppQuickSend: false,
    whatsAppSendFile: false,
    chatbox: false,
    developers: false,
    devDocs: false,
  },
};

const viewPageSlice = createSlice({
  name: "viewPageSlice",
  initialState,
  reducers: {
    setViewPage: (state, action) => {
      state.pageOnScreen = action.payload;
    },
  },
});

export const { setViewPage } = viewPageSlice.actions;

export default viewPageSlice.reducer;
