import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdNotificationsActive } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { showFailedToastAlert } from "../../../toastAlert.js/toastalert";
import { getToken } from "../../../utils/tokenUtils";
import { baseUrl } from "../../../api/url";

const Notification = () => {
  const [notifications, setNotifications] = useState<any>();
  const token = getToken();
  useEffect(() => {
    try {
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "message",
        orderDir: "asc",
        search: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/users/account/notifications`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          const data = response?.data;
          if (data?.status === "success") {
            setNotifications(data?.data);
          } else {
            showFailedToastAlert(`getting notification error ${data?.message}`);
          }
        })
        .catch((error) => {
          // console.log(error);
          showFailedToastAlert(
            `getting notification error ${error?.error?.message}`
          );
        });
    } catch (error: any) {
      showFailedToastAlert(
        `getting notification error ${error?.error?.message}`
      );
    }
  }, []);
  const navigate = useNavigate();
  // console.log(notifications);
  return (
    <div>
      <div className="dropdown dropdown-end">
        <label tabIndex={0} className="cursor-pointer">
          <MdNotificationsActive />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 shadow bg-base-100 w-52 text-[12px]"
        >
          <div className="flex items-center justify-between px-2 border-b-2 pb-2">
            <span className="text-[14px]">Notification</span>
            <span className="bg-gray-200 p-2 rounded-full text-orange-600">{`${notifications?.recordsTotal} New`}</span>
          </div>
          <div
            className="bg-orange-600 text-white p-2 text-center rounded-lg mt-2 cursor-pointer"
            onClick={() => navigate("/profile", { state: "3" })}
          >
            Read All Notifications
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Notification;
