import axios from "axios";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getToken } from "../../utils/tokenUtils";
import { apiBaseUrl } from "../../globals/baseUrl";
import { baseUrl } from "../../api/url";

export default function Invoices() {
  const token = getToken();

  const columns = [
    {
      name: "SN",
      selector: (row) => row.title,
    },
    {
      name: "Date",
      selector: (row) => row.year,
    },
    {
      name: "Type",
      selector: (row) => "red",
    },
    {
      name: "Details",
      selector: (row) => "details",
    },
    {
      name: "Amount",
      selector: (row) => "details",
    },
    {
      name: "Status",
      selector: (row) => "details",
    },
    {
      name: "Actions",
      selector: (row) => "details",
    },
  ];

  const data = [
    {
      id: 1,
      title: "Seetlejuice",
      year: "1988",
    },
    {
      id: 2,
      title: "Ghostbusters",
      year: "1984",
    },
  ];

  // state variable for invoices
  const [invoice, setInvoice] = useState<any>();

  // data fetched from api
  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        start: 0,
        orderBy: "id",
        orderDir: "DESC",
        search: "",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/customers/invoices/search`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios.request(config).then((response) => {
        // const data = response?.data;
        console.log(response?.data?.data?.recordsTotal, "inonnnnn");
        setInvoice(response?.data?.data?.recordsTotal);
      });
    } catch (error: any) {
      throw new Error(error.response.data.message);
    }
  }, []);

  console.log(invoice);

  return (
    <div>
      <div className="text-2xl">
        <h3 className="py-3">Current Plan</h3>
        <hr />
      </div>
      <DataTable columns={columns} data={invoice} />
    </div>
  );
}
