import React, { useState } from "react";
import * as XLSX from "xlsx";
import PreviewMessage from "./PreviewMessage";

interface ExcelPreviewProps {
  file: File | null;
  isHeader: boolean;
}
type ExcelDataRow = string[];

const ExcelPreview: React.FC<ExcelPreviewProps> = ({ file, isHeader }) => {
  const [data, setData] = useState<ExcelDataRow[]>([]);
  const [showTable, setShowTable] = useState(false);
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleFileRead = (
    file: File,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryData = e.target!.result;
      const workbook = XLSX.read(binaryData, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet, {
        header: 1,
      }) as ExcelDataRow[];
      previewMessage(excelData, isHeader);

      setData(excelData);
      setShowTable(true);
    };
    reader.readAsBinaryString(file);
  };

  const previewMessage = (excelData: ExcelDataRow[], isHeader: boolean) => {
    if (!isHeader) {
      let message = "";
      for (const datas of excelData) {
        if (datas.length !== 0) {
          for (let i = 0; i < datas.length - 1; i++) {
            message = message + datas[i] + "/n";
          }
          setMessage(message);
          setPhoneNumber(datas[datas.length - 1]);
          break;
        }
      }
    } else {
      let message = "";
      const columns: string[] = [];
      for (const datas of excelData) {
        if (datas.length !== 0) {
          for (let i = 0; i < datas.length - 1; i++) {
            columns.push(datas[i]);
          }
          for (let i = 0; i < columns.length; i++) {
            message = message + columns[i] + ":" + excelData[1][i] + "/n";
          }
          setMessage(message);
          setPhoneNumber(excelData[1][excelData[1].length - 1]);
          break;
        }
      }
    }
  };

  const toggleTable = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setShowTable(!showTable);
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    const phoneNumberRegex = /^[9]\d{9}$/;
    return phoneNumberRegex.test(phoneNumber);
  };
  if (!file) return null;

  return (
    <div className="bg-white rounded  p-4">
      <h2 className="text-xl font-semibold mb-2">Excel Preview</h2>
      <div>
        <h3 className="mb-2">File: {file.name}</h3>
        <button
          onClick={(e) => handleFileRead(file, e)}
          className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600"
        >
          Preview
        </button>
        {showTable && ( // Show the table if showTable is true
          <>
            <div className="w-1/2 p-4">
              <table className="w-full mt-4 border">
                <tbody>
                  {data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => {
                        return (
                          <td
                            key={cellIndex}
                            className={`border p-2 ${
                              cellIndex === row.length - 1 && rowIndex !== 0
                                ? !isValidPhoneNumber(cell.toString())
                                  ? "text-red-600"
                                  : ""
                                : ""
                            }`}
                          >
                            {cell}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-1/2 p-4">
              <PreviewMessage phoneNumber={phoneNumber} message={message} />
            </div>
          </>
        )}

        <button
          onClick={(event) => {
            toggleTable(event);
          }}
          className="mx-2 my-2 bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600"
        >
          {showTable ? "Hide" : "Show"}
        </button>
      </div>
    </div>
  );
};

export default ExcelPreview;
