import React, { useEffect, useState } from "react";
import profile1 from "../assets/profile1.png";
import profile2 from "../assets/chatprofile2.png";
import profile3 from "../assets/chatprofile3.png";
import profile4 from "../assets/chatprofile4.png";
import profile5 from "../assets/chatprofile5.png";
import chat from "../assets/chatpng.png";
import axios from "axios";
import { getToken } from "../utils/tokenUtils";
import { baseUrl } from "../api/url";

const Chatbox = () => {
  const [chatData, setChatData] = useState([]);

  const token = getToken();

  useEffect(() => {
    // gettingChatBoxData
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/chat-box`,
      headers: {
        Authorization: `Bearer Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        const data = response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="grid  grid-cols-2 mt-[20px] bg-[#F9F9F9] gap-[80px]">
      <div className=" w-full">
        <div className="bg-white flex items-center w-full py-[18px] px-[15px]">
          <div className="bg-[#F9F9F9] flex items-center w-full pr-[14.69px]">
            <input
              type="text"
              className="outline-none bg-transparent w-full pt-[16px] pb-[15px] pl-[21px] placeholder:text-[#5F6368] text-[16px] leading-[18.75px]"
              placeholder="Search"
            />
            <svg
              className="cursor-pointer"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                fill="#B4B4B4"
              />
            </svg>
          </div>
        </div>

        {/* chat */}
        <div className="bg-white mt-[20px]">
          <div className="pt-[12px] pl-[15px] pb-[17px] border-b-[2px] border-[#F9F9F9]">
            <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold">
              Chats
            </h2>
          </div>
          <div className="pb-[86px]">
            {chatData?.length ? (
              chatData.map((items: any, index: any) => (
                <div className="flex items-center gap-[15px] pl-[19px] mt-[17px] pb-[18px] border-b-[1px] border-[#F9F9F9] cursor-pointer">
                  <img src={profile1} alt="" />
                  <div>
                    <div className="flex justify-between pr-[15px]">
                      <h3 className="text-[#5F6368] text-[15px] leading-[17.58px] font-normal">
                        John Doe
                      </h3>{" "}
                      <p className="text-[#5F6368] text-[12px] leading-[14.06px] font-normal">
                        15 jan
                      </p>
                    </div>
                    <p className="text-[#5F6368] text-[12px] leading-[14.06px] font-normal mt-[9px] pr-[7px]">
                      lorem ipsum dolor sit ametlorem ipsum dolor sit ametlorem
                      ipsum dolor sit amet m ipsum dolor sit ametlorem ipsum
                      dolor sit ametlorem ipsum dolor sit a
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <h3 className="text-[#5F6368] text-[15px] font-normal text-center mt-[20px]">
                Your Chats Appear Here
              </h3>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center flex-col">
        <img src={chat} alt="" className="w-[40%]  object-cover" />
        <div className="w-[169px] h-[38px]  flex items-center justify-center mx-auto text-white rounded-[5px] cursor-pointer bg-[#0092D7]  ">
          <p className="text-[14px] leading-[16.41px] font-normal pt-[10px] pl-[20px] pb-[9px] pr-[19px]">
            New Conversation
          </p>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;
