import React, { useState } from "react";
import { BsGraphUp } from "react-icons/bs";
import { BiCaretRight, BiCaretDown, BiGroup,BiMessageAltCheck } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { VscGraph } from "react-icons/vsc";
import { MdOutgoingMail } from "react-icons/md";
import { HiInboxIn } from "react-icons/hi";
import { GoFileDirectory } from "react-icons/go";

const Reports = (props: any) => {
  const [dropDown, setDropDown] = useState(false);
  const dispatch = useDispatch();
  const active = useSelector((state: any) => state?.sideNavSlice?.activeNav);

  const { handlePageComponent } = props;

  return (
    <div className="flex items-center flex-col gap-[8px]  rounded-[6px]    w-[223px] cursor-pointer ">
      <div
        className={
          active === "reports"
            ? "nav_active flex items-center gap-[16px] pl-[18px]py-[12px] nav_link  w-full  rounded-[6px] group"
            : "flex items-center gap-[16px] py-[12px] pl-[18px] nav_link  w-full rounded-[6px] group"
        }
        onClick={() => setDropDown(!dropDown)}
      >
        <div className="flex items-center gap-[14px]">
          <span className="text-[20px]">
            <BsGraphUp />
          </span>
          <p
            className={
              active === "reports"
                ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
            }
          >
            Reports
          </p>
        </div>
        {dropDown ? <BiCaretDown /> : <BiCaretRight />}
      </div>

      {dropDown && (
        <div className="w-full bg-[#006AAD1A] transition duration-300 ease-out flex flex-col gap-[8px]">
          {/* <NavLink to="/reports/allmessage">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
              <VscGraph />

              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                All messages
              </p>
            </div>
          </NavLink>
          <NavLink to="/reports/received">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
              <HiInboxIn />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Recieved Messages
              </p>
            </div>
          </NavLink> */}
          <NavLink to="/reports/sent">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link   rounded-[6px]">
              <BiMessageAltCheck />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Sent Messages
              </p>
            </div>
          </NavLink>
          <NavLink to="/reports/campaign">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
              {/* <img src={contact} alt="" className='group-hover:invert' /> */}
              <BiGroup />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Campaigns
              </p>
            </div>
          </NavLink>
          {/* EOM Reports */}
          <NavLink to="sms/eom-records">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
              <GoFileDirectory />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Eom Reports
              </p>
            </div>
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default Reports;
