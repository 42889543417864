import { Select } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

const AccountInformation = ({
  setRegister,
  setAccount,
  setPersonal,
  setRegisterForm,
}) => {
  const handleSelectorChange = (value: any) => {
    console.log(value);
  };

  // const timezones = [{ value: "Kathmandu" }];
  const languages = [{ value: "English" }];
  return (
    <div>
      <p className=" mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full  font-normal">
        Account Information
      </p>
      <p className=" mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal">
        Fill the below form to create a new account
      </p>
      <form action="" className="w-full">
        {/* <input type="hidden" name="_token" value={csrfToken} /> */}
        <div className="w-full mt-[31px]">
          <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
            Email
          </p>
          <input
            type="email"
            placeholder="Email address"
            name="email"
            className="w-full outline-none border bg-white border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
            onChange={(event) => {
              setRegisterForm((prev) => ({
                ...prev,
                email: event.target.value,
              }));
            }}
          />
        </div>
        <div className="flex gap-5">
          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Password
              </p>
            </div>
            <input
              type="password"
              placeholder="password"
              name="password"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setRegisterForm((prev) => ({
                  ...prev,
                  password: event.target.value,
                }));
              }}
            />
          </div>
          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Confirm Password
              </p>
            </div>
            <input
              type="password"
              placeholder="Confirm Password"
              name="password"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setRegisterForm((prev) => ({
                  ...prev,
                  password_confirmation: event.target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="flex gap-5">
          {/* <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Timezone
              </p>
            </div>
            <Select
              defaultValue={timezones[0].value || "N / A"}
              style={{ width: "100%" }}
              onChange={handleSelectorChange}
              className="mt-[8px]"
              options={timezones}
            />
          </div> */}
          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Language
              </p>
            </div>
            <Select
              defaultValue={languages[0].value || "N / A"}
              style={{ width: "100%" }}
              onChange={handleSelectorChange}
              className="mt-[8px]"
              options={languages}
            />
          </div>
        </div>
        <div className="flex items-center gap-[8px] mt-[24px]">
          <input
            type="checkbox"
            id=""
            className="w-[16px] h-[16px] cursor-pointer border border-[#808080] outline-none"
            //   onChange={(e) =>
            //     setLogin({ ...login, remember: e?.target?.checked })
            //   }
          />
          <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
            Remember Me
          </p>
        </div>
        <div className="flex gap-5 justify-between">
          <NavLink to="/">
            <div className="w-[200px]">
              <button
                type="submit"
                className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
                onClick={() => setRegister(false)}
              >
                back
              </button>
            </div>
          </NavLink>
          <div className="w-[200px]">
            <button
              type="submit"
              className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
              onClick={() => {
                setAccount(false);
                setPersonal(true);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AccountInformation;
