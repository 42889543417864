import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { baseUrl } from "../api/url";
import { getToken } from "../utils/tokenUtils";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";

const file = () =>
  toast.success(" File sent successfully !", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
const Sendflie = () => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const token = getToken();
    const selectedFile = event.target.files?.[0];
    // Do something with the selected file (e.g., upload it to a server)
    if (!selectedFile) return;
    try {
      const formData = new FormData();
      formData.append("marks_file", selectedFile);

      const response = await axios.post(
        `${baseUrl}/customers/sms/send-marks-campaign`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("File upload response:", response.data);
      if (response.data.status == "success")
        showSuccessfulToastAlert(response.data.message);
      // Do something with the response if needed
    } catch (error: any) {
      console.error("Error uploading file:", error);
      showFailedToastAlert(error.message);
      // Handle the error if necessary
    }
  };
  const openFileUpload = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="bg-white mt-[20px] pb-[26px] p-5">
      <ToastContainer />

      <div className="py-[23px] pl-[20px] border-b-[1px] border-[#D9D9D9] flex items-center justify-between pr-[18px]">
        <h2 className="text-[#515151] text-[16px] leading-[18.75px] font-medium">
          Send File
        </h2>
        <a
          href={`http://localhost:8000/download/sms-sample`}
          className="underline text-[#0092D7] decoration-[#0092D7] text-[13px] leading-[15.23px]"
        >
          Download Sample File
        </a>
      </div>
      <form
        action=""
        className="grid md:grid-cols-2 grid-cols-1 gap-[72.5px] pl-[21.5px] px-[12px] md:px-0"
      >
        <div>
          <div className="border border-dashed h-[214px] border-[#0092D7] mt-[24px] mr-[58px] relative cursor-pointer">
            <div className="flex items-center justify-center flex-col absolute top-[37px] left-[50%] transform translate-x-[-50%]">
              <p className="text-[#0092D7] text-[14px] leading-[16.41px] font-normal">
                Click here to upload
              </p>

              <svg
                width="64"
                height="52"
                viewBox="0 0 64 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={openFileUpload}
              >
                <path
                  d="M8.871 23.1978C6.85087 25.1622 5.85544 27.5311 5.85544 30.3333C5.85544 33.1356 6.85087 35.5044 8.871 37.5556C10.8618 39.4622 13.2626 40.4444 16.1025 40.4444H35.3962C35.2498 41.3978 35.1327 42.3511 35.1327 43.3333C35.1327 44.3156 35.2498 45.2689 35.3962 46.2222H16.1025C11.7109 46.2222 7.87557 44.7778 4.71363 41.6867C1.58097 38.6533 0 34.9267 0 30.5644C0 26.8089 1.14181 23.4578 3.42543 20.5111C5.70906 17.5644 8.78316 15.6867 12.4428 14.8778C13.6725 10.4578 16.1025 6.87556 19.7621 4.13111C23.4218 1.38667 27.5791 0 32.2049 0C37.914 0 42.7447 1.96444 46.7264 5.89333C50.7081 9.82222 52.699 14.5889 52.699 20.2222C56.0659 20.5978 58.8472 22.0422 61.0723 24.5556C62.5654 26.2022 63.5316 28.0511 64 30.1022C60.8525 27.4434 56.8423 25.9877 52.699 26H46.8435V20.2222C46.8435 16.2356 45.3797 12.8267 42.5691 9.99556C39.6999 7.22222 36.2452 5.77778 32.2049 5.77778C28.1647 5.77778 24.71 7.22222 21.8408 9.99556C19.0302 12.8267 17.5663 16.2356 17.5663 20.2222H16.1025C13.2626 20.2222 10.8618 21.2044 8.871 23.1978ZM43.9158 40.4444H49.7713V52H55.6267V40.4444H61.4822L52.699 31.7778L43.9158 40.4444Z"
                  fill="#0092D7"
                />
              </svg>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <p className="text-[#5F6368] text-[12px] leading-[19.2px] font-normal max-w-[413px] absolute left-[19px] bottom-[24px]">
              The file must contains phone column, include a country code at the
              beginning for a successful import. Supported file: csv, xls, xlsx
            </p>
          </div>
        </div>
      </form>
      <div
        className="w-[120px] h-[38px] flex items-center justify-center mx-auto text-white rounded-[2px] cursor-pointer bg-[#0092D7] mt-[67.07px] "
        onClick={file}
      >
        <p className="text-[14px] leading-[16.41px] font-normal">Send</p>
      </div>
    </div>
  );
};

export default Sendflie;
