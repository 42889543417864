import React, { useEffect, useState } from "react";
import { baseUrl } from "../../api/url";
import { useToken } from "antd/es/theme/internal";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
import { getToken } from "../../utils/tokenUtils";

interface ImageUploadResponse {
  imageUrl: string;
}

const ImageUploadComponent: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);

  useEffect(() => {
    getAvatar();
  }, []);

  const token = getToken();

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewImageUrl(URL.createObjectURL(file));
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!selectedImage) return;

    const formData = new FormData();
    const reader: any = new FileReader();
    reader.readAsArrayBuffer(selectedImage);
    reader.onload = () => {
      const blob = new Blob([reader?.result], { type: selectedImage.type });
      formData.append("image", blob, selectedImage.name);
      axios
        .post(
          `${baseUrl}/users/avatar`,
          { image: selectedImage },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
          }
        )
        .then((response) => {
          console.log(JSON.stringify(response.data));
          showSuccessfulToastAlert(response.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    };
  };

  const getAvatar = async () => {
    try {
      const response = await axios.get(`${baseUrl}/users/avatar`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("avatar response", response);
      setPreviewImageUrl(response?.data.data);
    } catch (error) {
      console.log("Error in getting avatar", error);
      showFailedToastAlert(error);
    }
  };

  return (
    <div className="flex  items-center gap-4 p-4">
      <label className="w-32 h-32 rounded-full border-2 border-gray-300 flex items-center justify-center overflow-hidden">
        {previewImageUrl ? (
          <img
            src={previewImageUrl}
            alt="Preview"
            className="w-full h-full object-cover"
          />
        ) : (
          <span className="text-gray-400">No image</span>
        )}
        <input
          type="file"
          accept="image/*"
          className="sr-only"
          onChange={handleImageChange}
        />
      </label>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded"
        onClick={handleUpload}
        disabled={!selectedImage}
      >
        Upload
      </button>
      <div className="bg-[#F9F9F9] rounded-[5px] py-[4px] pl-[7px] pr-[31px] mt-[10px]">
        <p className="text-[#5F6368] text-[12px] leading-[16.8px] font-normal">
          Recommended Size:
        </p>
        <p className="text-[#5F6368] text-[12px] leading-[16.8px] font-normal">
          {" "}
          Width: 300px, Height 300px
        </p>
      </div>
    </div>
  );
};

export default ImageUploadComponent;
