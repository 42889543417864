import React, { useEffect, useState } from "react";

import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/searchicon.png";
import DataTable from "react-data-table-component";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { Switch } from "antd";
import { getToken } from "../utils/tokenUtils";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import { baseUrl } from "../api/url";
import { AiFillEdit } from "react-icons/ai";
import Swal from "sweetalert2";

const Smstemplate = () => {
  // --------- App State -----------------------
  const [smsTemplate, setSmsTemplate] = useState<any>();
  const [searchQuery, setSearchQuery] = useState("");
  // ----------- App State End -------------------
  const token = getToken();
  const navigate = useNavigate();

  // --------- getting sms template -------------
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/templates`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        console.log("template",data);

        if (data?.status === "success") {
          setSmsTemplate(data);
          console.log(data);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.response?.data?.message);
      });
  }, []);
  // ---------- getting sms template end ------------

  // ---------- search query change -----------------
  useEffect(() => {
    let data = JSON.stringify({
      length: 10,
      start: 0,
      orderBy: "id",
      orderDir: "desc",
      search: searchQuery,
    });

    let searchConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/templates/search`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(searchConfig)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const data = response?.data;
        if (data?.status === "success") {
          // setSmsTemplate(data?.data?.data);
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchQuery]);
  // ---------------search query end -------------------

  // --------------- data colomn -------------------------
  const columns: any = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "Status",
      selector: (row) => (
        <>
          <Switch
            onChange={() => handleTemplateStatus(row?.uid)}
            checked={row.status ? true : false}
            className={row.status ? "bg-green-500" : "bg-red-500"}
          />
        </>
      ),
    },

    {
      name: "Action",
      selector: (row) => (
        <div className=" flex items-center justify-center ">
          <div className="flex gap-3 items-center justify-center">
            <NavLink to={`/template/update/${row?.uid}`}>
              <AiFillEdit className="text-2xl " />
            </NavLink>
            <div onClick={() => handleTemplateDelete(row?.uid)}>
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.625 16.5C2.14375 16.5 1.73192 16.3261 1.3895 15.9782C1.0465 15.6298 0.875 15.2111 0.875 14.7222V3.16667H0V1.38889H4.375V0.5H9.625V1.38889H14V3.16667H13.125V14.7222C13.125 15.2111 12.9538 15.6298 12.6114 15.9782C12.2684 16.3261 11.8562 16.5 11.375 16.5H2.625ZM11.375 3.16667H2.625V14.7222H11.375V3.16667ZM4.375 12.9444H6.125V4.94444H4.375V12.9444ZM7.875 12.9444H9.625V4.94444H7.875V12.9444Z"
                  fill="#EF4444"
                />
              </svg>
            </div>
          </div>
        </div>
      ),
    },
  ];
  // --------------- data column end ---------------------

  // --------------- event handles ----------------------
  const handleSeachQuery = (event: any) => {
    setSearchQuery(event?.target?.value);
  };

  const handleTemplateDelete = (uid: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let deleteConfig = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${baseUrl}/customers/templates/${uid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        axios
          .request(deleteConfig)
          .then((response) => {
            const data = response?.data;
            if (data?.status === "success") {
              showSuccessfulToastAlert(data?.message);
              window.location.reload();
            } else {
              showFailedToastAlert(data?.message);
            }
          })
          .catch((error) => {
            console.log(error?.response?.data?.message);
          });
      }
    });
  };

  const handleTemplateStatus = (uid: any) => {
    let statusConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/templates/${uid}/active`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(statusConfig)
      .then((response) => {
        console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          showSuccessfulToastAlert(data?.message);
          setTimeout(() => {
            navigate("/sms-template");
          }, 100);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.message);
      });
  };
  // --------------- event handles end ------------------

  return (
    <div className="bg-white  shadow  rounded-lg my-10 pl-[20px] ">
      <div className=" py-[24px] items-center flex justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[20px]">
          SMS Template
        </h2>
      </div>
      <div className=" pl-[20px] md:pl-[36px] my-[24px] grid gap-y-4  md:flex ">
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center pr-[12px] min-w-[257px] justify-between">
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSeachQuery}
            className="pt-[14px] pl-[14px] pb-[13px] outline-none bg-white placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />

          <img src={search} alt="" className="cursor-pointer" />
        </div>
        <div className="flex items-center justify-center bg-[#F1602B1A] gap-[6px] rounded-[3px] max-w-[120px] py-[14px] px-4 cursor-pointer md:mx-6">
          <p className="text-[12px] leading-[14.06px] text-[#F1602B]">Action</p>
          <img src={bulkaction} alt="" />
        </div>
        <NavLink to="add-new">
          <div className="flex items-center justify-center bg-[#003D7E] text-white max-w-[120px] gap-[6px] p-[10px] rounded-[3px] cursor-pointer py-[14px]">
            <p className="text-[12px] leading-[14.06px]">Add New</p>
            <img src={addnew} alt="" />
          </div>
        </NavLink>
      </div>
      <DataTable
        columns={columns}
        data={smsTemplate?.data}
        pagination
        selectableRows
        className="px-[10px]"
      />
    </div>
  );
};

export default Smstemplate;
