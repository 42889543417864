import axios from "axios";
import { baseUrl } from "../url";

interface Payload {
  phone: string;
  otp: string;
}

export const verifyOtp = async (payload: Payload) => {
  const { phone, otp } = payload;
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/auth/verify_otp`,
    headers: {
      "Content-Type": "Application/json",
    },
    data: { phone_number: phone, otp },
    
  };
  return await axios.request(config);
};
