import { Box, Modal } from "@mui/material";
import { TiDeleteOutline } from "react-icons/ti";
import { MagnifyingGlass } from "react-loader-spinner";

const ViewCampaignModal = ({ data, modalOpen, modalHandle }) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid var(--primary-color)",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={modalOpen}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {data ? (
          <>
            <div className="flex justify-between items-center text-4xl ">
              <span></span>
              <span className="text-orange-700" onClick={modalHandle}>
                <TiDeleteOutline />
              </span>
            </div>
            <div className="flex flex-col w-full px-5 gap-2">
              <div className="w-1/2 ">
                <div className="flex gap-3 justify-between">
                  <span className="font-light">Sender ID</span>
                  <span>{data?.cache?.sender_id}</span>
                </div>
              </div>
              <div className="flex flex-col mb-5 mt-3">
                <span className="text-[var(--primary-color)]">Message</span>
                <style>
                  {`
                    .message-content {
                      max-height: 200px; /* Adjust the maximum height as needed */
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  `}
                </style>
                <span className="message-content">{data?.message}</span>
              </div>
              <div className="flex gap-3 justify-between  border-b-2 pb-1">
                <span className="font-light">Contacts</span>
                {/* <span>{message?.sms_type}</span> */}
              </div>
              {data?.cache?.contacts &&
                data?.cache?.contacts.length > 0 &&
                data?.cache?.contacts.map((contact: any) => {
                  return contact == null ? (
                    <></>
                  ) : (
                    <div className="flex gap-3 justify-between">
                      <span>{contact}</span>
                    </div>
                  );
                })}
              <div className="flex gap-3 justify-between   border-b-2 pb-1 mt-3">
                <span className="font-light">Type</span>

                <span>{data?.sms_type}</span>
              </div>
              <div className="flex gap-3 justify-between   border-b-2 pb-1">
                <span className="font-light">Cost</span>
                <span>{data?.cache?.cost ?? "0"}</span>
              </div>
              <div className="flex gap-3 justify-between   border-b-2 pb-1">
                <span className="font-light">Run At</span>
                <span>{data?.run_at ?? "0"}</span>
              </div>
              <div className="flex gap-3 justify-between   border-b-2 pb-1">
                <span className="font-light">Delivery At</span>
                <span>{data?.delivery_at ?? "0"}</span>
              </div>
              {/* <span>{getDate(message?.created_at)}</span>
              <span>{message?.send_by}</span>
              <span>{message?.sms_type}</span>
              <span>{message?.cost}</span>
              <span>{message?.status}</span> */}
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center">
            <MagnifyingGlass
              visible={true}
              height="80"
              width="80"
              ariaLabel="MagnifyingGlass-loading"
              wrapperStyle={{}}
              wrapperClass="MagnifyingGlass-wrapper"
              glassColor="#ffffff"
              color="#000000"
            />
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default ViewCampaignModal;
