import { Select } from "antd";
import React from "react";
import { NavLink } from "react-router-dom";

const PersonalInformation = ({
  setAccount,
  setBilling,
  setPersonal,
  setRegisterForm,
}) => {
  return (
    <div>
      <p className=" mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full  font-normal">
        Personal Information
      </p>
      <p className=" mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal">
        Fill the below form to create a new account
      </p>
      <form action="">
        {/* name */}
        <div className="flex gap-5">
          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                First Name
              </p>
            </div>
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setRegisterForm((prev) => ({
                  ...prev,
                  first_name: event.target.value,
                }));
              }}
            />
          </div>

          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Last Name
              </p>
            </div>
            <input
              type="text"
              placeholder="Last Name"
              name="lastname"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setRegisterForm((prev) => ({
                  ...prev,
                  last_name: event.target.value,
                }));
              }}
            />
          </div>
        </div>

        {/* phone */}
        <div className="flex gap-5">
          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Phone
              </p>
            </div>
            <input
              type="number"
              placeholder="Phone"
              name="phone"
              minLength={10}
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setRegisterForm((prev) => ({
                  ...prev,
                  phone: event.target.value,
                }));
              }}
            />
          </div>

          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Post Code
              </p>
            </div>
            <input
              type="number"
              placeholder="postCode"
              name="postCode"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setRegisterForm((prev) => ({
                  ...prev,
                  post_code: event.target.value,
                }));
              }}
            />
          </div>
        </div>
        {/* address  */}
        <div className="w-full">
          <div className="flex items-center justify-between mt-[24px] mb-[5px]">
            <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
              Address
            </p>
          </div>
          <input
            type="text"
            placeholder="Address"
            name="address"
            className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
            onChange={(event) => {
              setRegisterForm((prev) => ({
                ...prev,
                address: event.target.value,
              }));
            }}
          />
        </div>
        {/* company  */}
        <div className="w-full">
          <div className="flex items-center justify-between mt-[24px] mb-[5px]">
            <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
              Company
            </p>
          </div>
          <input
            type="text"
            placeholder="Company"
            name="company"
            className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
            onChange={(event) => {
              setRegisterForm((prev) => ({
                ...prev,
                company: event.target.value,
              }));
            }}
          />
        </div>
        {/* city */}
        <div className="flex gap-5">
          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                City
              </p>
            </div>
            <input
              type="text"
              placeholder="City"
              name="city"
              className="w-full outline-none bg-white border border-[#D9D9D9] rounded-[1px] pt-[11px] pl-[14px] pb-[12px] mt-[4px] placeholder:text-[13px] placeholder:text-[#000] placeholder:opacity-[0.5] placeholder:font-normal placeholder:leading-[15.23px] text-[13px]"
              onChange={(event) => {
                setRegisterForm((prev) => ({
                  ...prev,
                  city: event.target.value,
                }));
              }}
            />
          </div>

          <div className="w-1/2">
            <div className="flex items-center justify-between mt-[24px] mb-[5px]">
              <p className="text-[13px] leading-[15.23px] font-normal text-[#545454]">
                Country
              </p>
            </div>
            <Select
              defaultValue={[{ value: "Nepal" }]}
              style={{ width: "100%" }}
              // onChange={handleSelectorChange}
              className="mt-[8px]"
              options={[{ value: "Nepal" }]}
            />
          </div>
        </div>
      </form>
      {/* Buttons */}
      <div className="flex gap-5 justify-between">
        <NavLink to="/">
          <div className="w-[200px]">
            <button
              type="submit"
              className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
              onClick={() => {
                setPersonal(false);
                setAccount(true);
              }}
            >
              back
            </button>
          </div>
        </NavLink>
        <div className="w-[200px]">
          <button
            type="submit"
            className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
            onClick={() => {
              setAccount(false);
              setPersonal(false);
              setBilling(true);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
