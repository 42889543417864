import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { apiBaseUrl } from "../../../globals/baseUrl";
import withAuthHeader from "../../../globals/Auth";
import { api } from "../../../globals/baseUrl";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../../toastAlert.js/toastalert";
import { baseUrl } from "../../../api/url";
import { getToken } from "../../../utils/tokenUtils";

const AddnewTemplate = () => {
  // ----------app state ----------
  const [name, setName] = useState("");
  const [message, setMessage] = useState<any>();

  const [tags, setTags] = useState<any>([]);
  const [templateTag, setTemplateTag] = useState("");
  // ------------app state end----------------
  const navigate = useNavigate();
  const token = getToken();

  // ------------ event handles -----------
  const handleMessageChange = (event: any) => {
    setMessage(event?.target?.value);
  };
  const handleNameChange = (event: any) => {
    setName(event?.target?.value);
  };
const handleTagChange = (e:any)=>{
  setTemplateTag(e.target?.value);
   setTags([...tags,e.target.value]);
   
}

useEffect(()=>{
  const formattedMessage = tags.map(value => `{${value.replace(/_/g, '')}}`)
  .join('');
  setMessage(formattedMessage);

  console.log(formattedMessage);
},[tags]);
  // --------------event handles end---------------

  // ---------------create template ---------
  const createTemplate = (event: any) => {
    event?.preventDefault();
    let data = JSON.stringify({
      name,
      message,
      user_type: "customer",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/templates`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;

        if (data?.status === "success") {
          showSuccessfulToastAlert(data?.message);
          navigate("/sms-template");
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.response?.data?.message);
      });
  };
  // ---------------create template end---------

  return (
    <div className="bg-white shadow my-10 round-lg px-[20px] w-full lg:w-[60%]">
      <ToastContainer />
      <div className="py-[24px]  items-center flex justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[20px]">
          Add New Template
        </h2>
      </div>
      <form onSubmit={createTemplate} className="pl-[20px] w-full">
        <div className="mt-[24px]">
          <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
            Name *
          </h2>
          <input
            name="name"
            type="text"
            required
            placeholder="Required"
            onChange={handleNameChange}
            value={name}
            className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
          />
        </div>
        <div className="mt-[24px]">
          <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
            Available Tag *
          </h2>
          <select
              name="plan"
              className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
            onChange={handleTagChange}
            >
              <option></option>
              <option
                value="phone"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Phone
              </option>
              <option
                value="first_name"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                First Name
              </option>
              <option
                value="last_name"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Last Name
              </option>
              <option
                value="email"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Email
              </option>
              <option
                value="username"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Username
              </option>
              <option
                value="company"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Company
              </option>
              <option
                value="address"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Address
              </option>
              <option
                value="birth_date"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Birth Date
              </option>
              <option
                value="anniversary_date"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Anniversary Date
              </option>
            </select>
        </div>

        <div className="mt-[32px]">
          <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
            Message *
          </h2>
          <textarea
            id="w3review"
            value={message}
            onChange={handleMessageChange}
            name="message"
            className="border w-full h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
            placeholder="message"
            required
          ></textarea>
        </div>

        <button type="submit">
          <div className="w-[108px] h-[34px] flex items-center justify-center text-white rounded-sm my-[32px] cursor-pointer bg-[#0092D7] mr-4">
            <p className="text-[14px] leading-[16.41px] font-normal">Save</p>
          </div>
        </button>
        <button type="submit">
          <div className="w-[108px] h-[34px] flex items-center justify-center text-white rounded-sm my-[32px] cursor-pointer bg-[#0092D7]">
            <p className="text-[14px] leading-[16.41px] font-normal">Reset </p>
          </div>
        </button>
      </form>
    </div>
  );
};

export default AddnewTemplate;
