import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../utils/tokenUtils";
// import { setPhoneNumbers } from "../redux/sendingSlice";
import axios from "axios";
import { baseUrl } from "../../api/url";

const CampaignBuilder = () => {
  const [userCredentials, setUserCredential] = useState({
    name: "",
    countryCode: "+977",
    sendingServer: "",
    message: "",
    contactGroups: "",
    manualInput: "",
    diffrentiator: "",
    senderId: "",
    phoneNumber: "",
    template: "",
    tag: "",
    recipients: "",
    mmsFile: "",
  });
  const [allSenderIds, setAllSenderIds] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const dispatch = useDispatch();

  // Getting Sender Ids
  const allSenders = useSelector(
    (state: any) => state?.reducers?.dashboardSlice?.senderIds
  );

  useEffect(() => {
    let temp: any = [];
    allSenders.map((items: any, index: any) => {
      temp.push({ value: items?.sender_id });
    });
    setAllSenderIds(temp);
  }, [allSenders]);

  // Getting Numbers
  const allNumbers: any = useSelector(
    (state: any) => state?.reducers?.dashboardSlice?.numbers
  );

  useEffect(() => {
    let temp: any = [];
    allNumbers.map((items: any, index: any) => {
      temp.push({ value: items?.number });
    });
    setNumbers(temp);
  }, [allNumbers]);

  // Getting Sms Templates
  const allTemplates = useSelector(
    (state: any) => state?.reducers?.dashboardSlice?.smsTemplate
  );
  const token = getToken();

  useEffect(() => {
    let temp: any = [];
    allTemplates.map((items: any, index: any) => {
      temp.push({ value: items?.name });
    });
    setTemplates(temp);
  }, [allTemplates]);

  // Current plan
  const plan = useSelector(
    (state: any) => state?.reducers?.dashboardSlice?.currentPlan?.data
  );

  const handleChange = (event: any) => {
    console.log(userCredentials?.countryCode);
    const { value, name } = event.target;
    setUserCredential({ ...userCredentials, [name]: value });
  };
  // Handling Form Submit
  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(userCredentials);

    let data = JSON.stringify({
      name: userCredentials?.name,
      sending_server: userCredentials?.sendingServer,
      recipients: userCredentials?.recipients,
      delimiter: userCredentials?.diffrentiator,
      mms_file: userCredentials?.mmsFile,
      // "schedule_date": "required_if:schedule,true|date|nullable",
      // "schedule_time": "required_if:schedule,true|date_format:H:i",
      // "timezone": "required_if:schedule,true|timezone",
      // "frequency_cycle": "required_if:schedule,true",
      // "frequency_amount": "required_if:frequency_cycle,custom|nullable|numeric",
      // "frequency_unit": "required_if:frequency_cycle,custom|nullable|string",
      // "recurring_date": "sometimes|date|nullable",
      // "recurring_time": "sometimes|date_format:H:i"
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://smsvendor.com/api/v1/customers/mms/campaign-builder",
      headers: {
        Authorization: "Bearer 4|titqclJtET4YtrKXDdTgAfTW3V4KfWs5MW24Irs7",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Handling Select Change
  const handleSelectIdChange = (selectedOption: any) => {
    setUserCredential({ ...userCredentials, senderId: selectedOption });
  };
  // handle phone number change
  const handleSelectPhoneChange = (selectedOption: any) => {
    setUserCredential({ ...userCredentials, phoneNumber: selectedOption });
  };
  // handle sms template change
  const handleSelectTemplateChange = (selectedOption: any) => {
    setUserCredential({ ...userCredentials, template: selectedOption });
  };
  // handle tag change
  const handleSelectTagChange = (selectedOption: any) => {
    setUserCredential({ ...userCredentials, tag: selectedOption });
  };
  // handle seperator change
  const handleSelectSepChange = (selectedOption: any) => {
    console.log(selectedOption);
    setUserCredential({ ...userCredentials, diffrentiator: selectedOption });
  };
  // const handleSelectSepChange = (selectedOption: any) => {
  //   setUserCredential({ ...userCredentials, senderId: selectedOption });
  // };
  // const handleSelectSepChange = (selectedOption: any) => {
  //   setUserCredential({ ...userCredentials, senderId: selectedOption });
  // };

  return (
    <div className="bg-white mt-[20px] pb-[68px] flex flex-col">
      <ToastContainer />
      <div className="border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#515151] text-[16px] leading-[18.75px] font-medium py-[23px] pl-[20px]">
          MMS Campaign Builder
        </h2>
      </div>
      <form
        action=""
        className="grid grid-cols-2 pl-[23.25px] gap-[49.5px] pr-[73px]"
      >
        <div>
          <div className="mt-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Name *
            </h2>
            <input
              name="name"
              type="text"
              placeholder=""
              className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              onChange={handleChange}
            />
          </div>
          <div className="mt-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Sending Server *
            </h2>
            <input
              name="sendingServer"
              type="text"
              placeholder=""
              className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              onChange={handleChange}
            />
          </div>
          <div className="mt-[21px] border-b-[1px] border-[#D9D9D9] pb-[18px]">
            <p className="text-[#545454] text-[14px] leading-[16.41px] font-normal">
              ORIGINATOR
            </p>
            <div className="grid grid-cols-2 mt-[14px] gap-[24px]">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <input type="radio" />{" "}
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Sender ID
                  </p>
                </div>
                {/* <input type="text" className='border w-full h-[38px] outline-none pl-[15px] mt-[8px]' /> */}
                <Select
                  // defaultValue=""
                  style={{ width: "100%" }}
                  onChange={handleSelectIdChange}
                  className="mt-[8px]"
                  options={allSenderIds}
                />
              </div>
              <div>
                <div className="flex items-center gap-[8px]">
                  {" "}
                  <input type="radio" />{" "}
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Phone Number
                  </p>
                </div>
                {/* <input type="text" className='border w-full h-[38px] outline-none pl-[15px] mt-[8px]' /> */}
                <Select
                  defaultValue=""
                  style={{ width: "100%" }}
                  onChange={handleSelectPhoneChange}
                  className="mt-[8px]"
                  options={numbers}
                />
              </div>
            </div>
          </div>
          <div className="mt-[21px]  pb-[18px]">
            <div className="grid grid-cols-2 mt-[14px] gap-[24px]">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    SMS Template
                  </p>
                </div>
                {/* <input type="text" className='border w-full h-[38px] outline-none pl-[15px] mt-[8px]' /> */}
                <Select
                  defaultValue="Select Data"
                  style={{ width: "100%" }}
                  onChange={handleSelectTemplateChange}
                  className="mt-[8px]"
                  options={templates}
                />
              </div>
              <div>
                <div className="flex items-center gap-[8px]">
                  {" "}
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Available Tag
                  </p>
                </div>
                {/* <input type="text" className='border w-full h-[38px] outline-none pl-[15px] mt-[8px]' /> */}
                <Select
                  defaultValue="Phone"
                  style={{ width: "100%" }}
                  onChange={handleSelectTagChange}
                  className="mt-[8px]"
                  options={allTags}
                />
              </div>
            </div>
          </div>
          <div className="mt-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              MMS *
            </h2>
            <input
              name="mmsFile"
              type="file"
              placeholder=""
              className="mt-2"
              //   className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              onChange={handleChange}
            />
          </div>
          <div className="mt-[18px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Message *
            </h2>
            <textarea
              onChange={handleChange}
              name="message"
              id="w3review"
              // name="w3review"
              className="border w-full h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
              placeholder="message"
            ></textarea>
          </div>
        </div>

        <div>
          <div className="mt-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Country Code
            </h2>
            {/* <input type="text" placeholder='' className='border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal' /> */}
            <Select
              defaultValue={"+977"}
              style={{ width: "100%" }}
              // onChange={handleSelectSepChange}
              className="mt-[8px]"
              options={
                [
                  // {
                  //   value: "+977",
                  //   label: "Nepal",
                  // },
                  // {
                  //   value: "+91",
                  //   label: "India",
                  // },
                ]
              }
            />
          </div>
          <div className="mt-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Contact Groups
            </h2>
            <input
              type="text"
              name="contactGroups"
              placeholder=""
              className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-full mt-[4px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              onChange={handleChange}
            />
          </div>
          <div className="mt-[18px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Manual Input
            </h2>
            <textarea
              onChange={handleChange}
              id="w3review"
              name="manualInput"
              className="border w-full h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
              placeholder=""
            ></textarea>
          </div>
          <div className="mt-[23px]  grid grid-cols-5 h-[60px]  w-full">
            <div
              onClick={() => handleSelectSepChange(",")}
              className="flex items-center justify-center border gap-[4px] border-[#0092D7] border-r-0 hover:bg-[#0092D71A] group"
            >
              <p className="group-hover:text-[#0092D7]">,</p>
              <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
                (Comma)
              </p>
            </div>
            <div
              onClick={() => handleSelectSepChange(";")}
              className="flex items-center justify-center border gap-[4px] border-[#0092D7] border-r-0 hover:bg-[#0092D71A] group"
            >
              <p className="group-hover:text-[#0092D7]">;</p>
              <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
                (Semi colon)
              </p>
            </div>
            <div
              onClick={() => handleSelectSepChange("|")}
              className="flex items-center justify-center border gap-[4px] border-[#0092D7] border-r-0 hover:bg-[#0092D71A ] group"
            >
              <p className="group-hover:text-[#0092D7]">|</p>
              <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
                (Bar)
              </p>
            </div>
            <div
              onClick={() => handleSelectSepChange("\t")}
              className="flex items-center justify-center border gap-[4px] border-[#0092D7] border-r-0 hover:bg-[#0092D71A] group"
            >
              <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
                Tab
              </p>
            </div>
            <div
              onClick={() => handleSelectSepChange("\n")}
              className="flex items-center justify-center border gap-[4px] border-[#0092D7] hover:bg-[#0092D71A] group"
            >
              <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal group-hover:text-[#0092D7]">
                New line
              </p>
            </div>
          </div>
          <div className="bg-[#0092D71A] mt-[26px] py-[4px] px-[7px] cursor-pointer">
            <p className="text-[#003D7E] text-[13px] leading-[20.8px font-normal]">
              Note: You can upload a maximum of 1000 rows by copy-pasting. If
              you have more numbers you'd like to send in one go, please create
              a contact group and insert your contacts. Every number needs to
              include a country code for a successful import.
            </p>
          </div>
        </div>
      </form>
      <div
        className="w-[120px] h-[38px] flex items-center justify-center self-end mr-25px text-white rounded-[2px] cursor-pointer bg-[#0092D7] mt-[86px] mr-[75px]"
        onClick={handleSubmit}
      >
        <p className="text-[14px] leading-[16.41px] font-normal">Save</p>
      </div>
    </div>
  );
};

export default CampaignBuilder;
const campaign = () =>
  toast.success("Campaign saved successfully !", {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

//for ant select
const handleChange = (value: any) => {
  // console.log(`selected ${value}`);
};
const allTags = [
  {
    value: "Phone",
    // label: "1 Year (Rs)",
  },
  {
    value: "First Name",
    // label: "1 Year (Rs)",
  },
  {
    value: "Last Name",
    // label: "1 Year (Rs)",
  },
  {
    value: "Username",
    // label: "1 Year (Rs)",
  },
  {
    value: "Company",
    // label: "1 Year (Rs)",
  },
  {
    value: "Address",
    // label: "1 Year (Rs)",
  },
  {
    value: "Birthday",
    // label: "1 Year (Rs)",
  },
  {
    value: "Aniversary day",
    // label: "1 Year (Rs)",
  },
  // {
  //   value: "First Name",
  //   // label: "1 Year (Rs)",
  // },
];
