import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contactGroups: {},
  contacts: {},
  blackList: {},
  smsTemplate: {},
  senderIds: {},
  numbers: {},
  switchView: {},
  currentPlan: {},
};

export const dashBoardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setReduxContactGroups: (state, action) => {
      state.contactGroups = action?.payload;
    },
    setReduxContacts: (state, action) => {
      state.contacts = action?.payload;
    },
    setReduxBlackList: (state, action) => {
      state.blackList = action?.payload;
    },
    setReduxSmsTemplate: (state, action) => {
      state.smsTemplate = action?.payload;
    },
    setReduxSenderId: (state, action) => {
      state.senderIds = action?.payload;
    },
    setReduxNumbers: (state, action) => {
      state.numbers = action?.payload;
    },
    setReduxSwitchView: (state, action) => {
      state.switchView = action?.payload;
    },
    setReduxCurrentPlan: (state, action) => {
      state.currentPlan = action?.payload;
    },
  },
});

export const {
  setReduxContactGroups,
  setReduxBlackList,
  setReduxSmsTemplate,
  setReduxContacts,
  setReduxSenderId,
  setReduxNumbers,
  setReduxSwitchView,
  setReduxCurrentPlan,
} = dashBoardSlice.actions;

export default dashBoardSlice.reducer;
