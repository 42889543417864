import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../../utils/tokenUtils";
import axios from "axios";
import { RxCross1 } from "react-icons/rx";
import { sendExcelFile } from "../../api/excel-file/SendExcelFile";
import { deleteContactGroup } from "../../api/delete-contact/DeleteContactGroup";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
import { Switch } from "antd";
import { baseUrl } from "../../api/url";
import Swal from "sweetalert2";
import { IoIosSend } from "react-icons/io";
import withAuthHeader from "../../globals/Auth";
const Contacts = ({
  importdata,
  exportdata,
  bulkaction,
  addnew,
  search,
  allContacts,
}) => {
  // ........................data for data table..................................
  const columns: any = [
    {
      name: "Name",
      selector: (datas) => datas.name,
    },
    {
      name: "No. of Contact",
      selector: (datas) => datas.contacts,
    },
    {
      name: "Created at",
      selector: (datas) => datas.created_at,
      // cell: row => <> <label className="switch">
      //     <input type="checkbox" checked />
      //     <span className="slider round"></span>
      // </label></>
    },
    {
      name: "Status",
      selector: (row) => <Switch checked />,
    },
    {
      name: "Action",
      selector: (row: any) => {
        // console.log(row);

        return (
          <div className=" grid  my-4 grid-cols-3 xl:flex  items-center gap-3  xl:gap-3 xl:my-0">
            <div onClick={() => handleCreateCopy(row?.uid, row?.name)} className="cursor-pointer">
              {/* MAKE NEW COPY */}
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17 0.542553V13.5638C17 13.7077 16.9428 13.8457 16.8411 13.9475C16.7393 14.0492 16.6013 14.1064 16.4574 14.1064C16.3136 14.1064 16.1756 14.0492 16.0738 13.9475C15.9721 13.8457 15.9149 13.7077 15.9149 13.5638V1.08511H3.43617C3.29228 1.08511 3.15428 1.02794 3.05253 0.926196C2.95078 0.824448 2.89362 0.686447 2.89362 0.542553C2.89362 0.398659 2.95078 0.260659 3.05253 0.15891C3.15428 0.0571616 3.29228 0 3.43617 0H16.4574C16.6013 0 16.7393 0.0571616 16.8411 0.15891C16.9428 0.260659 17 0.398659 17 0.542553ZM14.1064 3.43617V16.4574C14.1064 16.6013 14.0492 16.7393 13.9475 16.8411C13.8457 16.9428 13.7077 17 13.5638 17H0.542553C0.398659 17 0.260659 16.9428 0.15891 16.8411C0.0571616 16.7393 0 16.6013 0 16.4574V3.43617C0 3.29228 0.0571616 3.15428 0.15891 3.05253C0.260659 2.95078 0.398659 2.89362 0.542553 2.89362H13.5638C13.7077 2.89362 13.8457 2.95078 13.9475 3.05253C14.0492 3.15428 14.1064 3.29228 14.1064 3.43617ZM13.0213 3.97872H1.08511V15.9149H13.0213V3.97872Z"
                  fill="#F79136"
                />
              </svg>
            </div>
            <div className="cursor-pointer"
              onClick={() => {
                navigate(`/contact/${row.uid}/create`);
              }}
            >
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 14.5V8.5H0V6.5H6V0.5H8V6.5H14V8.5H8V14.5H6Z"
                  fill="#003D7E"
                />
              </svg>
            </div>
            <div
            className="cursor-pointer"
              onClick={() => {
                navigate(`/contact/${row.uid}/view`);
              }}
            >
              <svg
                width="14"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23 4C23 2.34315 21.6569 1 20 1H4C2.34315 1 1 2.34315 1 4V8C1 9.65685 2.34315 11 4 11H20C21.6569 11 23 9.65685 23 8V4ZM21 4C21 3.44772 20.5523 3 20 3H4C3.44772 3 3 3.44772 3 4V8C3 8.55228 3.44772 9 4 9H20C20.5523 9 21 8.55228 21 8V4Z"
                    fill="#0F0F0F"
                  ></path>{" "}
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23 16C23 14.3431 21.6569 13 20 13H4C2.34315 13 1 14.3431 1 16V20C1 21.6569 2.34315 23 4 23H20C21.6569 23 23 21.6569 23 20V16ZM21 16C21 15.4477 20.5523 15 20 15H4C3.44772 15 3 15.4477 3 16V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V16Z"
                    fill="#0F0F0F"
                  ></path>{" "}
                </g>
              </svg>
            </div>
            <div
            className="cursor-pointer"
              onClick={() => {
                handlePopup(row?.id);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                id="upload"
              >
                <path d="M8.71,7.71,11,5.41V15a1,1,0,0,0,2,0V5.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21l-4,4A1,1,0,1,0,8.71,7.71ZM21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V13a1,1,0,0,0-2,0v6a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12Z"></path>
              </svg>
            </div>
            <div
            className="cursor-pointer"
              onClick={() => {
                toggleDeleteModal(row?.uid);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#FF0000"
                viewBox="0 0 16 16"
              >
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />{" "}
                <path
                  fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </div>
          </div>
        );
      },
    },
  ];

  const token = getToken();
  const navigate = useNavigate();

  // ....................upload excel file funcitonality.............................
  const [show, setShow] = useState(false);
  const [isNumberOnly, setIsNumberOnly] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [groupId, setGroupId] = useState(null);

  const handleSubmitFile = async (e) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      formData.append("import_file", selectedFile);
      try {
        const response = await sendExcelFile({
          formData,
          isNumberOnly,
          groupId,
        });

        if (response.data.status === "success") {
          showSuccessfulToastAlert(response.data.message);
          setShow(false);
        } else {
          showFailedToastAlert(response.data.message);
        }
      } catch (err: any) {
        showFailedToastAlert(err.response.data.message);
      }
    } else {
      showFailedToastAlert("Please select file");
    }
  };
  const handlePopup = (id: any) => {
    setShow(!show);
    setGroupId(id);
  };
  const handleBackgroundClick = (event: React.MouseEvent) => {
    // Check if the click occurred on the black background
    if (event.target === event.currentTarget) {
      // Call closePopup when clicking outside of the image
      setShow(false);
      setShow2(false);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleFileTypeChange = (event) => {
    const selectedValue = event.target.value === "first";
    setIsNumberOnly(selectedValue);
  };

  // ..................fetch and display data from api for contact groups...........
  const [contactsGroups, setContactGroups] = useState<any>();
  useEffect(() => {
    // Getting ContactGroups
    let data = JSON.stringify({
      length: 1000000,
      start: 0,
      orderBy: "name",
      orderDir: "desc",
      search: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/contacts/search`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          setContactGroups(data);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message);
        showFailedToastAlert(`Error getting Contact Groups ${error?.message}`);
      });
  }, [ contactsGroups?.length]);


  //...............delete funcitonality/.....................
  const [show2, setShow2] = useState(false);
  const [rowIdToDelete, setRowIdToDelete] = useState(null);
  const toggleDeleteModal = (id: any) => {
    setShow2(!show2);
    setRowIdToDelete(id);
  };
  const handleDeletion = async (e) => {
    e.preventDefault();
    const id = rowIdToDelete;
    try {
      const response = await deleteContactGroup({ id });
      if (response.data.status === "success") {
        setShow2(false);
          showSuccessfulToastAlert(response.data.message);
             
      } else {
        showFailedToastAlert(response.data.message);
      }
    } catch (error: any) {
      showFailedToastAlert(`Error Deleting ${error?.message}`);
    }
  };

  //................functionality for creating copy of contact group.....................
  const handleCreateCopy = async (id: any, name: any) => {
    console.log(name);

    const { value: text } = await Swal.fire({
      input: "text",
      inputLabel: "New Contact Group",
      inputPlaceholder: `Copy Of ${name}`,
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
    });

    if (text) {
      Swal.fire(text);
      try {
        let data = JSON.stringify({
          group_name: text,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${baseUrl}/customers/contacts/${id}/copy`,
          headers: {
            Authorization: `Bearer Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };

        axios
          .request(config)
          .then(async (response) => {
            // console.log(JSON.stringify(response.data));
            const data = response?.data;
            if (data?.status === "success") {
              window.location.reload();
              showSuccessfulToastAlert(data?.message);
            } else {
              showFailedToastAlert(data?.message);
            }
          })
          .catch((error) => {
            console.log(error?.message);
            showFailedToastAlert(`Error Creating Copy ${error?.message}`);
          });
      } catch (error: any) {
        showFailedToastAlert(`Error Creating Copy ${error?.message}`);
      }
    } else {
      showFailedToastAlert("Please provide Group Name");
    }
  };

  return (
    <div className="pr-[18px]">
      <div className="flex flex-wrap gap-[12px]">
        <Link to="create">
          <div className="flex items-center bg-[#003D7E] text-white gap-[6px] p-[10px] my-2 rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px]">Add New</p>
            <img src={addnew} alt="" />
          </div>
        </Link>
      </div>
      {/* <div className="flex flex-wrap justify-between">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368]  text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div> */}

      {/* modal for delete	 */}
      {show2 && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div
            className="fixed top-0 left-0 w-full h-full bg-black opacity-50"
            onClick={handleBackgroundClick}
          ></div>
          <div className="relative">
            <button
              className="absolute top-6 right-2 text-gray-600 text-xl bg-transparent"
              onClick={() => setShow2(false)}
            >
              <RxCross1 />
            </button>
            <div className="bg-white mt-[20px]  rounded-[5px] pb-[38px]  p-12">
              <p className="text-xl">
                Are you sure you want to delete the contact group?
              </p>
              <div className="mt-8">
                <button
                  className="bg-red-400 text-white font-bold max-w-[70%] sm:max-w-[38%]  px-4 py-2 rounded  "
                  onClick={() => setShow2(false)}
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeletion}
                  className="bg-green-400 text-white font-bold max-w-[70%] sm:max-w-[38%] px-4 py-2 rounded  ml-4"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* modal for excel form */}

      {show && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div
            className="fixed top-0 left-0 w-full h-full bg-black opacity-50"
            onClick={handleBackgroundClick}
          ></div>
          <div className="relative">
            <button
              className="absolute top-6 right-2 text-gray-600 text-xl bg-transparent"
              onClick={() => setShow(false)}
            >
              <RxCross1 />
            </button>
            <div className="bg-white mt-[20px]  rounded-[5px] pb-[38px]  p-12">
              <div className="bg-white  rounded max-w-sm   mb-4">
                <a
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-7 rounded text"
                  href="/test.xlsx"
                  download
                >
                  Sample File for number only
                </a>
              </div>
              <div className="bg-white  rounded max-w-sm   mb-4">
                <a
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded text"
                  href="/test2.xlsx"
                  download
                >
                  Sample File for number and text
                </a>
              </div>
              <form
                // action=""
                id="sms_quick_sender"
                className="flex flex-col "
              >
                {/* Radio button */}
                <div className=" grid sm:flex  gap-4  mt-4 w-full justify-between">
                  <div>
                    <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal mb-3">
                      File Definition*
                    </h2>
                    <label className="mr-3">
                      <input
                        value="first"
                        type="radio"
                        name="eomType"
                        checked={isNumberOnly}
                        onChange={handleFileTypeChange}
                      />
                      &nbsp; Number
                    </label>

                    <label>
                      <input
                        value="second"
                        type="radio"
                        name="eomType"
                        checked={!isNumberOnly}
                        onChange={handleFileTypeChange}
                      />
                      &nbsp; Number and text
                    </label>
                  </div>

                  <div className=" sm:ml-8">
                    <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal mb-3">
                      Upload (.xlsx) *
                    </h2>
                    <input
                      className="bg-white"
                      type="file"
                      accept=".xlsx"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <button
                  className="bg-green-500 text-white font-bold max-w-[70%] sm:max-w-[38%] py-2 rounded mt-8 "
                  onClick={handleSubmitFile}
                >
                  Upload
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      <DataTable
        columns={columns}
        data={contactsGroups?.data?.data}
        pagination
        selectableRows
        className="px-[10px]"

      />
    </div>
  );
};

export default Contacts;
