import axios from "axios";
import { baseUrl } from "../url";

interface Payload {
  phone: string;
}

export const sendOtp = async (payload: Payload) => {
  const { phone } = payload;
  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/auth/send_otp`,
    headers: {
      "Content-Type": "application/json",
    },
    data: { phone_number: phone },
  };
  return await axios.request(config);
};
