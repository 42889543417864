import React from "react";

const Pagetitle = ({ title }) => {
  return (
    <div className="text-2xl">
      <h3 className="py-3">{title}</h3>
      <hr />
    </div>
  );
};

export default Pagetitle;
