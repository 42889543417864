import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dropDown: {
    reportDropDown: {
      allMessages: false,
      inbox: false,
      sent: false,
      campaign: false
    },
    sendingDropDown: false,
    smsDropDown: false,
    voiceDropDown: false,
    mmsDropdown: false,
    whatsapp: false,
  },

  activeNav: "dashboard",
  activeSubNav: ""
};

const sideNavSlice = createSlice({
  name: "sideNavSlice",
  initialState,
  reducers: {
    setDropDown: (state, action) => {
      state.dropDown = action.payload;
    }, setActiveNav: (state, action) => {
      state.activeNav = action.payload;
    },
  },
});

export const { setDropDown, setActiveNav } = sideNavSlice.actions;

export default sideNavSlice.reducer;
