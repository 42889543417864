import { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import ReactPaginate from "react-paginate";

const HandlingPagination = ({ data, getDataForTable }) => {
  // useState for handle currentpage that is displayed on the screen.
  const [currentPage, setCurrentPage] = useState<any>(0);
  const itemsPerPage = 10;
  console.log("Pagination data", data);
  // total data that need to be shwon on the screen
  const dataForTable = data?.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  // function that handles the page pagination.
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
    getDataForTable(dataForTable);
  };

  // this code is for first time populating of the table
  useEffect(() => {
    getDataForTable(dataForTable);
  }, [dataForTable?.length]);

  return (
    <div className="  p-8 flex flex-col ">
      <div className="text-[12px] sm:text-[15px] text-[#b9b9c3] mb-4 md:mb-0 ">
        Showing page
        <span className="mx-2">{currentPage + 1}</span>
        of {Math.ceil(data?.length / itemsPerPage)} pages out of{" "}
        {data?.recordsTotal} entries
      </div>

      <div className=" text-[12px] max-w-[300px] text-[var(--accent-gray)] mt-4">
        <ReactPaginate
          breakLabel=""
          previousLabel={
            <MdKeyboardArrowLeft
              className="text-[var(--accent-gray)]   hover:text-[var(--accent-orange)]  "
              size={30}
            />
          }
          nextLabel={
            <MdKeyboardArrowRight
              className="text-[var(--accent-gray)]  hover:text-[var(--accent-orange)]"
              size={30}
            />
          }
          pageCount={
            Math.ceil(data?.length / itemsPerPage)
              ? Math.ceil(data?.length / itemsPerPage)
              : 1
          }
          marginPagesDisplayed={0}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          previousClassName={"pagination__button"}
          nextClassName={"pagination__button"}
          containerClassName={"pagination"}
          activeClassName={"activepagination"}
        />
      </div>
    </div>
  );
};

export default HandlingPagination;
