import React from "react";
import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { AiFillDatabase } from "react-icons/ai";
import { BsFillCartFill } from "react-icons/bs";
import { MdShoppingCart } from "react-icons/md";
import Logs from "./Logs/Logs";
import Transactions from "./Logs/Transactions";

const LogsTransactions: React.FC = () => {
  return (
    <div className="">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span className="flex items-center gap-2 text-[14px] px-5">
              <AiFillDatabase />
              <span>LOGS</span>
            </span>
          }
          key="1"
        >
          <Logs />
        </TabPane>
        <TabPane
          tab={
            <span className="flex items-center gap-2 text-[14px] px-5">
              <MdShoppingCart />
              <span>TRANSACTIONS</span>
            </span>
          }
          key="2"
        >
          <Transactions />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default LogsTransactions;
