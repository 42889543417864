import { Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React from "react";
import { BsCreditCard } from "react-icons/bs";
import { TbFileInvoice, TbSettings2 } from "react-icons/tb";
import Invoices from "../components/BillingComponents/Invoices";
import Subscription from "../components/BillingComponents/subscription";
import Preferences from "../components/BillingComponents/Preferences";

const Billing = () => {
  return (
    <div className="bg-white p-10 m-10 shadow-sm rounded-xl shadow-black">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span className="flex items-center gap-2 text-[14px] px-5">
              <TbFileInvoice />
              INVOICES
            </span>
          }
          key="1"
        >
          {/* invoices  */}
          <Invoices />
        </TabPane>
        <TabPane
          tab={
            <span className="flex items-center gap-2 text-[14px] px-5">
              <BsCreditCard />
              SUBSCRIPTIONS
            </span>
          }
          key="2"
        >
          <Subscription />
        </TabPane>
        <TabPane
          tab={
            <span className="flex items-center gap-2 text-[14px] px-5">
              <TbSettings2 />
              PREFERENCES
            </span>
          }
          key="3"
        >
          <Preferences />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Billing;
