import React, { FC, useState } from "react";
import { Form, Input, Select, DatePicker, TimePicker } from "antd";
import moment from "moment";
import {AiOutlineCalendar} from "react-icons/ai";
export interface ScheduleCampaignData {
  date: string;
  time: string;
  frequency: string;
  endDate?: string;
  endTime?: string;
  frequencyAmount?: string; // For custom
  frequencyUnit?: string; // For custom
}

interface ScheduleCampaignFormProps {
  isVisible: boolean;
  onCampaignDataChange: (data: ScheduleCampaignData) => void;
}

const ScheduleCampaign: FC<ScheduleCampaignFormProps> = ({
  isVisible,
  onCampaignDataChange,
}) => {
  const [campaignData, setCampaignData] = useState<ScheduleCampaignData>({
    date: "",
    time: "",
    frequency: "",
  });
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    console.log(name, value);
    setCampaignData({
      ...campaignData,
      [name]: value,
    });
  };

  const handleFrequencyChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    setCampaignData({
      ...campaignData,
      frequency: value,
      endDate: "",
      endTime: "",
      frequencyAmount: "",
      frequencyUnit: "",
    });
  };
  const handleFrequencyUnitChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;
    setCampaignData({
      ...campaignData,
      frequencyUnit: value,
      endDate: "",
      endTime: "",
      frequencyAmount: "",
    });
  };

  if (!isVisible) {
    return null;
  }
  onCampaignDataChange(campaignData);
  return (
    <form className="mt-4 px-4 py-2 bg-white-100 border rounded shadow-md block md:absolute bg-white md:-translate-y-[324px] md:translate-x-[30px]"  >
      <div className="mb-4">
        <label className="block text-gray-700 font-semi-bold mb-2">
          Campaign Date:
          <i><AiOutlineCalendar/></i>
          <input
            className="appearance-none  text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            type="date"
            name="date"
            value={campaignData.date}
            onChange={handleInputChange}/>
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semi-bold mb-2">
          Campaign Time:
          <input
            className="appearance-none text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            type="time"
            name="time"
            value={campaignData.time}
            onChange={handleInputChange}
          />
        
        </label>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-semi-bold mb-2">
          Campaign Frequency:
          <select
            className="appearance-none text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            name="frequency"
            value={campaignData.frequency}
            onChange={handleFrequencyChange}
          >
            <option value="onetime">One Time</option>
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="custom">Custom</option>
          </select>
        </label>
      </div>
      {/* Render additional form fields based on frequency selection */}
      {campaignData.frequency === "daily" ||
      campaignData.frequency === "monthly" ||
      campaignData.frequency === "yearly" ? (
        <>
          <div>
            <label className="block text-gray-700 font-semi-bold mb-2">
              End Date:
              <input
                className="appearance-none text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                type="date"
                name="endDate"
                value={campaignData.date || ""}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label className="block text-gray-700 font-semi-bold mb-2">
              End Time:
              <input
                className="appearance-none text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
                type="time"
                name="endTime"
                value={campaignData.endTime || ""}
                onChange={handleInputChange}
              />
            </label>
          </div>
        </>
      ) : campaignData.frequency === "custom" ? (
        <div>
          <label className="block text-gray-700 font-semi-bold mb-2">
            Frequency Amount:
            <input
              className="appearance-none text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              name="frequencyAmount"
              value={campaignData.frequencyAmount || ""}
              onChange={handleInputChange}
            />
          </label>
          <label className="block text-gray-700 font-semi-bold mb-2">
            Frequency Unit:
            <select
              className="appearance-none text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              name="frequencyUnit"
              value={campaignData.frequencyUnit}
              onChange={handleFrequencyUnitChange}
            >
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="year">Year</option>
            </select>
          </label>
          <label className="block text-gray-700 font-semi-bold mb-2">
            End Date:
            <input
              className="appearance-none text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="date"
              name="endDate"
              value={campaignData.endDate || ""}
              onChange={handleInputChange}
            />
          </label>
          <label className="block text-gray-700 font-semi-bold mb-2">
            End Time:
            <input
              className=" text-gray-500 bg-white border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              type="time"
              name="endTime"
              value={campaignData.endTime}
              onChange={handleInputChange}
            />
          </label>
        </div>
      ) : null}
    </form>
  );
};

export default ScheduleCampaign;
