import axios from "axios";
import { baseUrl } from "../url";
import { getToken } from "../../utils/tokenUtils";

export const deleteContact = async (payload) => {
  const token = getToken();
  let config = {
    method: "DELETE",
    url: `${baseUrl}/customers/contacts/${payload.id}/deleteOneContact`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return await axios.request(config);
};
