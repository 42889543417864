import {
  SET_CONTACT_GROUP,
  SET_NUMBER_LIST,
  SET_SENDERID_LIST,
} from "../constant/constant";

let initialState = {
  data: [],
};

export default function getNumbers(state = initialState, action: any) {
  if (action.type === SET_NUMBER_LIST) {
    return {
      ...state,
      data: action.payload,
    };
  }
  return state;
}
