import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { baseUrl } from "../../../api/url";
import { getToken } from "../../../utils/tokenUtils";
import axios from "axios";

const OutgoingGraph = () => {
  const [outGoing, setOutGoing] = useState<any>([]);
  console.log(outGoing);
  const token = getToken();
  //   ------------getting chart data -----------------
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/users/dashboard`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          setOutGoing(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  //   ------------getting chart data end -------------

  const chartData = {
    series: [
      {
        name: "Count",
        data: outGoing?.data?.outgoing?.values || [0],
      },
    ],
    options: {
      chart: {
        id: "line-chart",
      },
      xaxis: {
        categories: outGoing?.data?.outgoing?.keys || ["0"],
      },
    },
  };

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="line"
      max-width="600px"
      height={200}
    />
  );
};

export default OutgoingGraph;
