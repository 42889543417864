import React, { useEffect, useState } from "react";
import { Select } from "antd";
import Form from "antd/es/form/Form";
import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getToken } from "../../utils/tokenUtils";
import { baseUrl } from "../../api/url";
import { showFailedToastAlert } from "../../toastAlert.js/toastalert";
import { useNavigate } from "react-router-dom";

const SpecificNumberQuickSend = () => {
  const [allSenderIds, setAllSenderIds] = useState(false);
  const [numbers, setNumbers] = useState<any>();
  const token = getToken();

  const [sms, setSms] = useState({
    sendingservers: "EN",
    recipient: "",
    originator: "",
    sender_id: "",
    phone_number: "",
    message: "",
    country_code: "+977",
  });

  // Getting Sender Ids
  const allSenders = useSelector(
    (state: any) => state?.reducers?.dashboardSlice?.senderIds
  );

  useEffect(() => {
    let temp: any = [];
    allSenders.map((items: any, index: any) => {
      temp.push({ value: items?.sender_id });
    });
    setAllSenderIds(temp);
  }, [allSenders]);

  // Phone Numbers
  const numbersWithDetails = useSelector(
    (state: any) => state?.reducers?.dashboardSlice?.numbers
  );

  useEffect(() => {
    let temp: any = [];
    numbersWithDetails.map((items: any, index: any) => {
      temp.push({ value: items?.number });
    });
    setNumbers(temp);
  }, [numbersWithDetails]);

  const sendSms = (e: any) => {
    e.preventDefault();
    // console.log(sms);

    // QUICK SEND CALL

    let data = JSON.stringify({
      recipient: sms?.recipient,
      sending_server: sms?.sendingservers,
      country_code: sms?.country_code,
      message: sms?.message,
      sms_type: "plain",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/sms/quick-send`,
      headers: {
        Authorization: `Bearer Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response?.data?.status !== "success") {
          showFailedToastAlert(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
        showFailedToastAlert(error?.message);
      });
  };

  const handleChange = (e: any) => {
    // console.log(e?.target?.value);
    const { value, name } = e.target;
    setSms({ ...sms, [name]: value });
  };

  const handleCountryCodeChange = (code: any) => {
    // console.log(code);
    setSms({ ...sms, country_code: code });
  };
  const handleServerChange = (server: any) => {
    // console.log(server);
    setSms({ ...sms, sendingservers: server });
  };

  return (
    <div className="h-[100vh]">
      <div className="bg-white mt-[20px] max-w-[600px] rounded-[5px] pb-[38px]">
        <div className="border-b-[1px] border-[#D9D9D9]">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
            Quick SMS Sender
          </h2>
        </div>
        <form action="" className="pl-[31px] flex flex-col " onSubmit={sendSms}>
          <div className="mt-[24px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Sending Server *
            </h2>
            <Select
              options={allSenders}
              onChange={handleServerChange}
              style={{ width: "100%" }}
              defaultValue="EN"
            />
          </div>
          <div className="mt-[24px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Recipient
            </h2>
            <div className="flex items-center">
              <div className="w-[100px]">
                <Select
                  defaultValue={"+977"}
                  style={{ width: "100%" }}
                  onChange={handleCountryCodeChange}
                  className="mt-[8px] w-[100px]"
                  options={[{ value: "+977" }, { value: "+91" }]}
                />
              </div>

              <input
                type="tel"
                onChange={handleChange}
                name="recipient"
                minLength={10}
                className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] flex-grow mt-[8px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              />
            </div>
          </div>
          <div className="mt-[21px]  pb-[18px] pr-[24px]">
            <p className="text-[#545454] text-[14px] leading-[16.41px] font-normal">
              ORIGINATOR
            </p>
            <div className="grid grid-cols-2 mt-[14px] gap-[24px]">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <input
                    type="radio"
                    name="originator"
                    value="sender_id"
                    onChange={handleChange}
                  />
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Sender ID
                  </p>
                </div>

                <Select options={[{ value: "" }]} style={{ width: "100%" }} />
              </div>
              <div>
                <div className="flex items-center gap-[8px]">
                  {" "}
                  <input
                    type="radio"
                    name="originator"
                    value="contact_number"
                    onChange={handleChange}
                  />{" "}
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Phone Number
                  </p>
                </div>
                {/* <input type="text" className='border w-full h-[38px] outline-none pl-[15px] mt-[8px]' /> */}
                <Select options={[{ value: "" }]} style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          <div className="mt-[20px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Message *
            </h2>
            <textarea
              onChange={handleChange}
              name="message"
              id="w3review"
              className="border w-full h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
              placeholder="message"
            ></textarea>
          </div>
          <div className="w-[120px] h-[38px] flex items-center justify-center text-white rounded-[2px] mt-[13px] cursor-pointer bg-[#0092D7] ml-[335px] self-end mr-[24px]">
            <button type="submit">
              <p className="text-[14px] leading-[16.41px] font-normal">Send</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SpecificNumberQuickSend;
