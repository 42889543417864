import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../utils/tokenUtils";
import { baseUrl } from "../api/url";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import { getTemplate } from "../api/template/getData";

const UpdateTemplate = () => {
  // ----------app state ----------'
  const [template, setTemplate] = useState<any>();
  const [name, setName] = useState("");
  const [message, setMessage] = useState<any>();
  const [tags,setTags] = useState<any>([]);
  // ------------app state end----------------
  const navigate = useNavigate();
  const token = getToken();
  const params = useParams();

  // ------------geting template to update ------
  useEffect(() => {
    if (params.id !== undefined) {
      fetchTemplate(params.id);
    }
  }, []);
  // ------------getting template end ----------

  // ------------ event handles -----------
  const handleMessageChange = (event: any) => {
    setMessage(event.target.value);
  };
  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleTagChange = (event:any) =>{
    setTags([...tags,event.target.value]);
  }

  useEffect(()=>{
    const formattedMessage = tags.map(value => `{${value.replace(/_/g, '')}}`)
  .join('');
  setMessage(formattedMessage);

  },[tags]);

  // --------------event handles end---------------

  // ---------------update template ------------
  const createTemplate = (event: any) => {
    event?.preventDefault();
    let data = JSON.stringify({
      name: name,
      message: message,
      user_type: "customer",
    });
    console.log("uid", template);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/templates/${template.template.uid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;

        if (data?.status === "success") {
          showSuccessfulToastAlert(data?.message);
          navigate("/sms-template");
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.response?.data?.message);
      });
  };

  const fetchTemplate = async (id) => {
    const response = await getTemplate(id);
    if (response?.data?.status === "success") {
      console.log(response?.data.data);
      setTemplate(response?.data.data);
      setName(response?.data.data.template.name);
      setMessage(response?.data.data.template.message);
    }
  };
  // ---------------update template end ---------

  // ---------------delete a template ---------

  return (
    <div className="bg-white mt-[20px] max-w-[513px] pb-[30px] shadow-md shadow-black">
      <ToastContainer />
      <div className="border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
          Update Template
        </h2>
      </div>
      <form onSubmit={createTemplate} className="pl-[31px]">
        <div className="mt-[24px]">
          <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
            Name *
          </h2>
          <input
            name="name"
            type="text"
            required
            placeholder="Required"
            onChange={handleNameChange}
            value={name}
            className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-[452px] mt-[4px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
          />
        </div>
        <div className="mt-[24px]">
          <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
            Available Tag *
          </h2>
          <select
              name="plan"
              className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-[452px] mt-[4px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
            onChange={handleTagChange}
            >
              <option></option>
              <option
                value="phone"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Phone
              </option>
              <option
                value="first_name"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                First Name
              </option>
              <option
                value="last_name"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Last Name
              </option>
              <option
                value="email"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Email
              </option>
              <option
                value="username"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Username
              </option>
              <option
                value="company"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Company
              </option>
              <option
                value="address"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Address
              </option>
              <option
                value="birth_date"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Birth Date
              </option>
              <option
                value="anniversary_date"
                className=" w-full absolute py-4 h-[38px] text-[14px]"
              >
                Anniversary Date
              </option>
            </select>
        </div>

        <div className="mt-[33px]">
          <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
            Message *
          </h2>
          <textarea
            id="w3review"
            value={message}
            onChange={handleMessageChange}
            name="message"
            className="border w-[452px] h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
            placeholder="message"
            required
          ></textarea>
        </div>
        <button type="submit">
          <div className="w-[107px] h-[34px] flex items-center justify-center text-white rounded-[2px] mt-[40px] cursor-pointer bg-[#0092D7] ml-[345px]">
            <p className="text-[14px] leading-[16.41px] font-normal">Save</p>
          </div>
        </button>
      </form>
    </div>
  );
};

export default UpdateTemplate;
