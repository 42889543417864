import React, { useEffect, useState } from "react";
import { Select } from "antd";
const QuickSender = () => {
  return (
    <div className="h-[100vh]">
      <div className="bg-white mt-[20px] max-w-[600px] rounded-[5px] p-5 pb-[38px] shadow-lg shadow-black">
        <div className="border-b-[1px] border-[#D9D9D9]">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
            MMS Quick Sender
          </h2>
        </div>
        <form action="" className="pl-[31px] flex flex-col">
          <div className="mt-[24px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal mb-2">
              Sending Server *
            </h2>
            <Select
              options={[{ value: "No options available" }]}
              // onChange={handleServerChange}
              style={{ width: "100%" }}
            />
          </div>
          <div className="mt-[24px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Recipient *
            </h2>
            <div className="flex items-center">
              <div className="w-[100px]">
                <Select
                  defaultValue={"+977"}
                  style={{ width: "100%" }}
                  // onChange={handleCountryCodeChange}
                  className="mt-[8px] w-[100px]"
                  options={[{ value: "+977" }, { value: "+91" }]}
                />
              </div>

              <input
                type="tel"
                // onChange={handleChange}
                name="recipient"
                minLength={10}
                className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] flex-grow mt-[8px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              />
            </div>
          </div>
          <div className="mt-[21px]  pb-[18px] pr-[24px]">
            <div className="mt-[14px] gap-[24px]">
              <div className="">
                <div className="flex items-center gap-[8px]  mb-2">
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Sender ID
                  </p>
                </div>
                <Select
                  options={[{ value: "No options Available" }]}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className="mt-[24px]  mr-6">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              MMS *
            </h2>
            <input
              name="mmsFile"
              type="file"
              placeholder=""
              // onChange={handleChange}
              className="border-gray-300 border-[1px] mt-2 w-full p-1"
            />
          </div>
          <div className="mt-[20px] pr-[24px]  mb-2">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Message
            </h2>
            <textarea
              // onChange={handleChange}
              name="message"
              id="w3review"
              className="border w-full h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
              placeholder="message"
            ></textarea>
          </div>
          <div className="w-[120px] h-[38px] flex items-center justify-center text-white rounded-[2px] mt-[13px] cursor-pointer bg-[#0092D7] ml-[335px] self-end mr-[24px]">
            <button type="submit">
              <p className="text-[14px] leading-[16.41px] font-normal">Send</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default QuickSender;
