import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  switchView: {},
};

const switchViewSlice = createSlice({
  initialState,
  name: "switchView",
  reducers: {
    setSwitchView: (state, action) => {
      state.switchView = action.payload;
    },
  },
});

export const { setSwitchView } = switchViewSlice.actions;

export default switchViewSlice.reducer;
