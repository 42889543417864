import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import RegisterNav from "./registerNav";
import AccountInformation from "./RegisterComponents/AccountInformation";
import PersonalInformation from "./RegisterComponents/PersonalInformation";
import BillingInformation from "./RegisterComponents/BillingInformation";
import Documents from "./RegisterComponents/Documents";

const Register = (props: any) => {
  const [showAccount, setAccount] = useState(true);
  const [showPersonal, setPersonal] = useState(false);
  const [showBilling, setBilling] = useState(false);
  const { setRegister } = props;

  const [registerForm, setRegisterForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    timezone: "Asia/Kathmandu",
    address: "",
    city: "",
    country: "Nepal",
    plans: "",
    locale: "en",
    payment_methods: "offline_payment",
  });

  return (
    <div className="  flex bg-white items-center justify-center flex-col  px-[44px] lg:px-[70px] xl:px-[94px] 2xl:px-[164px]  pb-[40px] md:pb-0 ">
      <div className="w-full">
        {/* <h2 className="text-[#0063A5] text-[22px] leading-[28.13px] font-normal text-center">
          Register
        </h2> */}
        <div className="flex flex-wrap  gap-x-5 gap-[0px] mb-4">
          <RegisterNav
            data={{ title: "Account", desc: "EnterCredentials", active: true }}
            iconStyle={showAccount}
          />
          <RegisterNav
            data={{
              title: "Personal",
              desc: "Personal Information",
              active: false,
            }}
            iconStyle={showPersonal}
          />
          <RegisterNav
            data={{ title: "Billing", desc: "Payment Details", active: false }}
            iconStyle={showBilling}
          />
        </div>
        <hr />

        {/* ACCOUNT INFORMATION */}
        {showAccount && (
          <AccountInformation
            setRegister={setRegister}
            setAccount={setAccount}
            setPersonal={setPersonal}
            setRegisterForm={setRegisterForm}
          />
        )}
        {/* PERSONAL INFORMATION */}
        {showPersonal && (
          <PersonalInformation
            setAccount={setAccount}
            setPersonal={setPersonal}
            setBilling={setBilling}
            setRegisterForm={setRegisterForm}
          />
        )}
        {/* BILLING INFORMATION */}
        {showBilling && (
          <BillingInformation
            setAccount={setAccount}
            setPersonal={setPersonal}
            setBilling={setBilling}
            setRegisterForm={setRegisterForm}
            registerForm={registerForm}
          />
        )}
      </div>
    </div>
  );
};

export default Register;
