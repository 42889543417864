import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../utils/tokenUtils";
import { baseUrl } from "../api/url";
import axios from "axios";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import { getBlacklist } from "../api/blacklist/getBlacklist";

const UpdateBlacklist = () => {
  // ----------app state ----------'
  const [blacklist, setBlacklist] = useState<any>();
  const [number, setNumber] = useState("");
  const [reason, setReason] = useState("");
  // ------------app state end----------------
  const navigate = useNavigate();
  const token = getToken();
  const params = useParams();

  // ------------geting blacklist to update ------
  useEffect(() => {
    if (params.id !== undefined) {
      fetchBlacklist(params.id);
    }
  }, []);
  // ------------getting blacklist end ----------

  // ------------ event handles -----------
  const handleReasonChange = (event: any) => {
    setReason(event.target.value);
  };
  const handleNumberChange = (event: any) => {
    setNumber(event.target.value);
  };

  // --------------event handles end---------------

  // ---------------update blacklist ------------
  const createBlacklist = (event: any) => {
    event?.preventDefault();
    let data = JSON.stringify({
      number: number,
      reason: reason,
      user_type: "customer",
    });
    console.log("uid", blacklist);
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/blacklists/${blacklist.blacklist.uid}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;

        if (data?.status === "success") {
          showSuccessfulToastAlert(data?.message);
          navigate("/blacklist");
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.response?.data?.message);
      });
  };

  const fetchBlacklist = async (id) => {
    const response = await getBlacklist(id);
    console.log(response);
    if (response?.data?.status === "success") {
      console.log(response?.data.data);
      setBlacklist(response?.data.data);
      setNumber(response?.data.data.blacklist.number);
      setReason(response?.data.data.blacklist.reason);
    }
  };
  // ---------------update blacklist end ---------

  // ---------------delete a blacklist ---------

  return (
    <div className="bg-white mt-[20px] max-w-[513px] pb-[30px] shadow-md shadow-black">
      <ToastContainer />
      <div className="border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
          Update Blacklist
        </h2>
      </div>
      <form onSubmit={createBlacklist} className="pl-[31px]">
        <div className="mt-[24px]">
          <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
            Number *
          </h2>
          <input
            name="name"
            type="text"
            required
            placeholder="Required"
            onChange={handleNumberChange}
            value={number}
            className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] w-[452px] mt-[4px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
          />
        </div>

        <div className="mt-[33px]">
          <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
            Reason *
          </h2>
          <textarea
            id="w3review"
            value={reason}
            onChange={handleReasonChange}
            name="message"
            className="border w-[452px] h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
            placeholder="message"
            required
          ></textarea>
        </div>
        <button type="submit">
          <div className="w-[107px] h-[34px] flex items-center justify-center text-white rounded-[2px] mt-[40px] cursor-pointer bg-[#0092D7] ml-[345px]">
            <p className="text-[14px] leading-[16.41px] font-normal">Save</p>
          </div>
        </button>
      </form>
    </div>
  );
};

export default UpdateBlacklist;
