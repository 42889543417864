import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setReduxCurrentPlan } from "../redux/dashboardSlice";
import { getDate } from "../utils/getData";
import { getToken } from "../utils/tokenUtils";
import { baseUrl } from "../api/url";

const CurrentPlan = (props: any) => {
  const { subscribe, info } = props;

  const [activePlan, setActivePlan] = useState({
    plan: {
      name: "",
      price: "",
    },
    subscription: {
      current_period_ends_at: "",
      end_period_last_days: "",
    },
  });

  const token = getToken();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("api");
    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/subscriptions`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        const data = response?.data;
        dispatch(setReduxCurrentPlan(data));
        setActivePlan(data?.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  // console.log(activePlan, "activeplan");

  return (
    <div className="bg-white p-5 basis-[50%] relative rounded-xl shadow">
      <span className="text-[var(--primary-color)] text-[18px] font-extrabold pb-[10px] ">
        Current Plan
      </span>
    
        <div className="text-[#C7C7C7] text-[16px]  font-normal mt-2 ">
          You are currently subscribed to the
          <span className="mx-2 text-orange-500">{activePlan?.plan?.name}</span>
          plan
          <span className="mx-2 text-orange-500">
            Rs {activePlan?.plan?.price}
          </span>{" "}
          , your subscription will expire in
          <span className="mx-2 text-orange-500">
            {activePlan?.subscription?.end_period_last_days}
          </span>
          days
          <span className="mx-2 text-orange-500">
            {getDate(activePlan?.subscription?.current_period_ends_at)}
          </span>
       
        {/* <button className="bg-[#F1602B] cursor-pointer flex items-center gap-[10px] py-[15px] px-[45px] rounded-[5px] absolute left-[50%] transform translate-x-[-50%] bottom-[43px]">
          <img src={info} alt="" />
          <p className="text-[14px] leading-[16.41px] font-normal text-white whitespace-nowrap ">
            More Info
          </p>
        </button> */}
      </div>
    </div>
  );
};

export default CurrentPlan;
