import { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import lang from "../assets/language.png";
import notification from "../assets/notification.png";
import profile from "../assets/profile.png";
import arrow from "../assets/arrowdown.png";
import online from "../assets/online.png";
import profile2 from "../assets/profile2.png";
import biling from "../assets/billing.png";
import logout from "../assets/logout.png";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { setReduxUser } from "../redux/userSlice";
import { imageBaseUrl } from "../globals/baseUrl";
import UpperNav from "../components/UpperNavigation";
import SideNav from "../components/SideNavigation";
import { baseUrl } from "../api/url";
import Login from "./Login";
import { getToken } from "../utils/tokenUtils";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/dashboard" || location.pathname === "/") {
      navigate("/dashboard");
    } else {
      // navigate(`${location.pathname}`)
    }
  }, []);

  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
  });
  const [show, setShow] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState(false);
  const updateSidebar = () => {
    if (window.innerWidth > 1024) {
      setActiveSidebar(false);
    }
  };
  useEffect(() => {
    // Initial update
    updateSidebar();
    window.addEventListener("resize", updateSidebar);
    return () => {
      window.removeEventListener("resize", updateSidebar);
    };
  }, []);
  const dispatch = useDispatch();

  // Dropdown state
  const dropDown = useSelector((state: any) => state?.sideNavSlice?.dropDown);

  // console.log(dropDown);

  useEffect(() => {
    try {
      var config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/me`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios(config)
        .then(async function (response) {
          // // console.log(response.data);
          const data = response?.data?.data;
          setUser(data);
          dispatch(setReduxUser(data));
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error: any) {
      console.log("Axios Error!");
    }
  }, []);

  const showProfile = () => {
    setShow(!show);
  };

  const arrRef = useRef<HTMLImageElement>(null!);

  // Avatar Image
  const uid = useSelector((state: any) => state?.userSlice?.user?.uid);
  // console.log(uid, "uid");
  const profileImage = `${imageBaseUrl}?${uid}`;
  const token = getToken();
  if (!token) {
    return <Login />;
  }

  return (
    <div className="bg-[#f8f8f8] min-h-screen">
      {/* topbar */}
      <UpperNav
        logo={logo}
        lang={lang}
        notification={notification}
        showProfile={showProfile}
        profileImage={profileImage}
        online={online}
        user={user}
        arrow={arrow}
        show={show}
        profile={profile}
        profile2={profile2}
        biling={biling}
        logout={logout}
        setShow={setShow}
        setActiveSidebar={setActiveSidebar}
      />
      <SideNav
        dropDown={dropDown}
        activeSidebar={activeSidebar}
        setActiveSidebar={setActiveSidebar}
        logo={logo}
      />
    </div>
  );
};

export default Dashboard;
