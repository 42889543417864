import { useEffect, useState } from "react";
import { Select } from "antd";
import axios from "axios";

import { baseUrl } from "../../api/url";
import { getToken } from "../../utils/tokenUtils";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
const VoiceQuickSender = () => {
  // ----------APPLICATION STATES  -----------------
  const [sendingServer, setSendingServer] = useState([
    { value: "No options available" },
  ]);
  const [senderId, setSenderId] = useState([{ value: "No options available" }]);
  const [languages, setLanguages] = useState<any>([
    { value: "please wait..." },
  ]);
  //---------- information to send ---------------
  const [reciepient, setReciepient] = useState("");
  const [server, setServer] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [message, setMessage] = useState("");
  const [gender, setGender] = useState("");
  const [id, setId] = useState("");
  const [languageUsed, setLanguageUsed] = useState("");
  // ----------APPLICATION STATES END -----------------------
  // -------------- getting token -----------------------
  const token = getToken();

  useEffect(() => {
    // ------------ getting form data ---------------------------------------------
    let data = JSON.stringify({
      recipient: "user",
    });

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/customers/voice/quick-send`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        if (response?.data === "success") {
          const data = response?.data;
          setSendingServer(
            data?.sending_server.length
              ? // ------modify array here if data present / currently null --------
                data?.sending_server
              : sendingServer
          );
          setSenderId(
            data?.senderId.length
              ? // ------modify array here if data present / currently null --------
                data?.sender_ids
              : senderId
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // ---------------- getting form data End -------------------------------------------

    // -------------- getting languages -------------------------
    let configg = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/users/account`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(configg)
      .then((response) => {
        // console.log(response.data);
        const data = response.data;
        if (data?.status === "success") {
          let temp1: any = [];
          let temp2 = data?.data?.languages;
          temp2.map((items: any, index: any) => {
            temp1.push({
              value: `${items?.name}, ${(items?.iso_code).toUpperCase()}`,
            });
          });
          setLanguages(temp1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // -------------- languages -------------------------
  }, []);

  // ----------------- submitting form ------------------------
  const sendSms = (e: any) => {
    e.preventDefault();
    let data = JSON.stringify({
      recipient: reciepient,
      sending_server: server,
      country_code: "+977",
      sms_type: "voice",
      message: message,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/customers/voice/quick-send`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        if (data?.status === "success") {
          showSuccessfulToastAlert(data?.message);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log(error.message);
        showFailedToastAlert(error.message);
      });
  };
  // ----------------- submitting form end ------------------------

  // ----------------- user events handling ----------------------
  const handleServerChange = (value) => {
    setServer(value);
  };

  const handleIdChange = (value) => {
    setId(value);
  };

  const handleLanguageChange = (value) => {
    setLanguageUsed(value);
  };

  const handleGenderChange = (value) => {
    setGender(value);
  };
  const handleReciepientChange = (event: any) => {
    setReciepient(event?.target?.value);
  };

  const handleMessageChange = (event: any) => {
    setReciepient(event?.target?.value);
  };

  const handleCountryCodeChange = (event: any) => {
    setCountryCode(event?.target?.value);
  };

  return (
    <div className="h-[100vh]">
      <div className="bg-white mt-[20px] max-w-[600px] rounded-[5px] pb-[38px] shadow-lg shadow-black p-5">
        <div className="border-b-[1px] border-[#D9D9D9]">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
            Voice Quick Send
          </h2>
        </div>
        <form action="" className="pl-[31px] flex flex-col " onSubmit={sendSms}>
          <div className="mt-[24px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Sending Server *
            </h2>
            <Select
              options={sendingServer || [{ value: "No options available" }]}
              onChange={handleServerChange}
              style={{ width: "100%" }}
              aria-required
            />
          </div>
          {/* ------------Reciepient--------------- */}
          <div className="mt-[24px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Recipient <sup>*</sup>
            </h2>
            <div className="flex items-center">
              <div className="w-[100px]">
                <Select
                  defaultValue={"+977"}
                  style={{ width: "100%" }}
                  onChange={handleCountryCodeChange}
                  className="mt-[8px] w-[100px]"
                  options={
                    [{ value: "+977" }, { value: "+91" }] || [
                      { value: "No options available" },
                    ]
                  }
                />
              </div>

              <input
                type="tel"
                onChange={handleReciepientChange}
                name="recipient"
                minLength={10}
                className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] flex-grow mt-[8px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              />
            </div>
          </div>
          {/* -----------------Reciepient ---------------- */}
          {/* -----------------sender ID ----------------- */}
          <div className="mt-[21px]  pb-[18px] pr-[24px]">
            <div className="mt-[14px] gap-[24px]">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Sender ID
                  </p>
                </div>

                <Select
                  options={senderId || [{ value: "No options available" }]}
                  onChange={setId}
                  // defaultValue=""
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          {/* -----------message ------------------ */}
          <div className="mt-[20px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Message *
            </h2>
            <textarea
              onChange={handleMessageChange}
              name="message"
              id="w3review"
              className="border w-full h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
              placeholder="message"
            ></textarea>
          </div>
          {/* ----------- message end -----------------------*/}
          {/* -----------languages and gender ------------- */}
          <div className="flex mr-5 gap-5">
            <div className="mt-[14px] gap-[24px] w-1/2">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Languages <sup>*</sup>
                  </p>
                </div>

                <Select
                  options={
                    languages.length
                      ? languages
                      : [{ value: "No options available" }]
                  }
                  onChange={handleLanguageChange}
                  // defaultValue=""
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="mt-[14px] gap-[24px] w-1/2">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Gender <sup>*</sup>
                  </p>
                </div>

                <Select
                  options={
                    [{ value: "male" }, { value: "Female" }] || [
                      { value: "No options available" },
                    ]
                  }
                  onChange={handleGenderChange}
                  // defaultValue=""
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>
          {/* -----------languages and gender End ------------- */}
          <div className="w-[120px] h-[38px] flex items-center justify-center text-white rounded-[2px] mt-[13px] cursor-pointer bg-[#0092D7] ml-[335px] self-end mr-[24px]">
            <button type="submit">
              <p className="text-[14px] leading-[16.41px] font-normal">Send</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VoiceQuickSender;
