import { useEffect, useState } from "react";
import OTPInput from "../OtpInput";

import "./otpInputPage.css";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../../toastAlert.js/toastalert";
import { verifyOtp } from "../../../api/otp/VerifyOtp";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../../assets/logo.png";
import InfoMessage from "../../reusable/InfoMessage";

const OtpInputPage = () => {
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    // Check if location.state exists and contains the 'phone' property
    if (!location.state || !location.state.phone) {
      navigate("/login");
      return; // Return null to prevent further rendering
    }
  }, []);

  const handleSubmit = async () => {
    if (otp.length < 6) {
      showFailedToastAlert("Please Enter OTP");
      return;
    }
    try {
      const response = await verifyOtp({ phone: location.state.phone, otp });
      if (response.data.status === "success") {
        showSuccessfulToastAlert(response.data.message);
        navigate("/login");
      } else {
        showFailedToastAlert(response.data.message);
      }
    } catch (err: any) {
      showFailedToastAlert(err.response.data.message);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen">
        <img src={logo} alt="Company Logo" className=" mb-14" />
        <div className=" mb-14">
          <InfoMessage message={`OTP has been sent to your mobile number`} />
        </div>
        <div className="text-lg font-bold text-black">Enter OTP</div>
        <OTPInput
          autoFocus
          length={6}
          className="otpContainer"
          inputClassName="otpInput"
          onChangeOTP={(otp) => setOtp(otp)}
        />
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default OtpInputPage;
