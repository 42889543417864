import React, { useEffect, useRef, useState } from "react";
import { Select } from "antd";
import Form from "antd/es/form/Form";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import withAuthHeader from "../../globals/Auth";
import { apiBaseUrl } from "../../globals/baseUrl";
import { baseUrl } from "../../api/url";
import { getToken } from "../../utils/tokenUtils";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
import { getSenderIds } from "../../utils/getFormData";
import { error } from "console";
const WhatsAppQuickSender = () => {
  const [formdata, setFormData] = useState<any>();
  const [sendingServers, setSendingServers] = useState([
    { value: "loading..." },
  ]);
  const [senderId, setSenderId] = useState([{ value: "loading..." }]);
  const [selectedServer, setSelectedServer] = useState("");
  const [recipient, setRecipient] = useState("");
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState([{ value: "+977" }]);
  const token = getToken();

  // -----------------form initializtion --------------
  useEffect(() => {
    // ---------------sending server ------------------
    let data = "";

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/customers/sending-servers/select`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          let temp: any = [];
          data?.data?.map((items: any, index: any) => {
            temp.push({ value: items?.name });
          });
          setSendingServers(temp);
        } else {
          showFailedToastAlert(data.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error.message);
      });
    //--------------sending server end -------------

    // -------------senderId------------------------

    let configure = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/customers/senderid`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // data : data
    };

    axios
      .request(configure)
      .then((response) => {
        const data = response?.data;
        // console.log(data);
        if (data?.status === "success") {
          let temp: any = [];
          data?.data?.map((items: any, index: any) => {
            temp.push({ value: items?.sender_id });
          });
          setSenderId(temp);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // -------------senderId end -------------------
    // -------------form data ---------------------
    // let dataa = JSON.stringify({
    //   recipient: "user",
    // });

    // let configg = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: `${token}api/v1/customers/whatsapp/quick-send`,
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   data: dataa,
    // };

    // axios
    //   .request(configg)
    //   .then((response) => {
    //     console.log(response.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    //-------------form data end-----------------------
  }, []);
  // -----------------form initializtion end-----------

  // -----------------data population  ----------------
  // useEffect(() => {
  // sendingServers.current = formdata?.data?.sending_server;
  // recipient.current = formdata?.data?.reciepient;
  // senderId.current = formdata?.data?.sender_ids;
  // countryCode.current = formdata?.data?.
  // }, [formdata]);
  // -----------------data population end -------------

  // -----------------form submission -----------------
  const sendSms = (event: any) => {
    event.preventDefault();
    // console.log(
    //   selectedServer,
    //   "server",
    //   recipient,
    //   "reciepient",
    //   senderId,
    //   "senderId",
    //   message,
    //   "message"
    // );
    // -----------------sending message------------------
    let data = JSON.stringify({
      recipient: recipient,
      sending_server: selectedServer,
      country_code: 140,
      message: message,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/customers/whatsapp/quick-send`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        const data = response?.data;
        if (data?.status === "success") {
          showSuccessfulToastAlert("Message Sent");
        } else {
          showFailedToastAlert("Failed to send message");
        }
      })
      .catch((error: any) => {
        showFailedToastAlert("Failed to send message");
        console.log(error?.message);
      });
    // -----------------sending message end -------------
  };
  // -----------------form submission end -------------

  const handleServerSelect = (value: any) => {
    setSelectedServer(value);
  };
  const handleRecipientChange = (event: any) => {
    setRecipient(event?.target?.value);
  };

  const handleSenderIdChange = (value: any) => {
    setRecipient(value);
  };

  const handleMessageChange = (event: any) => {
    setMessage(event?.target?.value);
  };

  return (
    <div className="h-[100vh]">
      <div className="bg-white mt-[20px] max-w-[600px] rounded-[5px] pb-[38px] p-5">
        <div className="border-b-[1px] border-[#D9D9D9]">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pl-[20px] pt-[23px] pb-[24px]">
            WhatsApp Quick Send
          </h2>
        </div>
        <form action="" className="pl-[31px] flex flex-col " onSubmit={sendSms}>
          <div className="mt-[24px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Sending Server *
            </h2>
            <Select
              options={sendingServers}
              onChange={handleServerSelect}
              style={{ width: "100%" }}
            />
          </div>
          <div className="mt-[24px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Recipient
            </h2>
            <div className="flex items-center">
              <div className="w-[100px]">
                <Select
                  style={{ width: "100%" }}
                  // onChange={handleCountryCodeChange}
                  className="mt-[8px] w-[100px]"
                  options={countryCode}
                  defaultValue={[{ value: "+977" }]}
                />
              </div>

              <input
                type="tel"
                onChange={handleRecipientChange}
                name="recipient"
                minLength={10}
                className="border border-[#D9D9D9] pl-[14px] outline-none h-[38px] flex-grow mt-[8px] placeholder:text-[#000] placeholder:text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              />
            </div>
          </div>
          <div className="mt-[21px]  pb-[18px] pr-[24px]">
            <p className="text-[#545454] text-[14px] leading-[16.41px] font-normal">
              ORIGINATOR
            </p>
            <div className="mt-[14px] gap-[24px]">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <input
                    type="radio"
                    name="originator"
                    value="sender_id"
                    // onChange={handleChange}
                  />{" "}
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Sender ID
                  </p>
                </div>
                <Select
                  options={senderId}
                  style={{ width: "100%" }}
                  onChange={handleSenderIdChange}
                />
              </div>
              {/* <div>nd
                <div className="flex items-center gap-[8px]">
                  {" "}
                  <input
                    type="radio"
                    name="originator"
                    value="contact_number"
                    // onChange={handleChange}
                  />{" "}
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Phone Number
                  </p>
                </div>
                <Select options={[{ value: "" }]} />
              </div> */}
            </div>
          </div>
          <div className="mt-[20px] pr-[24px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Message *
            </h2>
            <textarea
              onChange={handleMessageChange}
              name="message"
              id="w3review"
              className="border w-full h-[136px] mt-[4px] rounded-[1px] pt-[12px] pl-[14px] outline-none"
              placeholder="message"
            ></textarea>
          </div>
          <div className="w-[120px] h-[38px] flex items-center justify-center text-white rounded-[2px] mt-[13px] cursor-pointer bg-[#0092D7] ml-[335px] self-end mr-[24px]">
            <button type="submit">
              <p className="text-[14px] leading-[16.41px] font-normal">Send</p>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WhatsAppQuickSender;
