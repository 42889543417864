// import { createStore, applyMiddleware } from "redux";
// import createSagaMiddleware from "redux-saga";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import hardSet from "redux-persist/lib/stateReconciler/hardSet";
// import rootSaga from "../rootSaga";
// import { reducers } from "../rootReducer";

// const persistConfig = {
//   key: "easy-sms",
//   storage,
//   stateReconciler: hardSet,
// };

// const bindMiddleware = (middleware: any) => {
//   if (process.env.NODE_ENV !== "production") {
//     const { composeWithDevTools } = require("redux-devtools-extension");
//     return composeWithDevTools(applyMiddleware(...middleware));
//   }
//   return applyMiddleware(...middleware);
// };

// const persistedReducer = persistReducer(persistConfig, reducers);
// const sagaMiddleware = createSagaMiddleware();

// const store = createStore(persistedReducer, bindMiddleware([sagaMiddleware]));
// export const store = createStore()

import { configureStore } from "@reduxjs/toolkit";
import { reducers } from "../rootReducer";
export const store = configureStore({
  reducer: {
    reducers,
  },
});
