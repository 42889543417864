export function getDate(dateStr) {
  const date = new Date(dateStr);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().substr(-2);
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  const formattedDate = `${getOrdinalSuffix(
    day
  )} ${month} ${year}, ${hours}:${padZero(minutes)} ${ampm}`;

  return formattedDate;
}

function getOrdinalSuffix(n) {
  const suffixes = ["th", "st", "nd", "rd"];
  const lastDigit = n % 10;
  const suffix = suffixes[lastDigit] || suffixes[0];
  return `${n}${suffix}`;
}

function padZero(n) {
  return n.toString().padStart(2, "0");
}
