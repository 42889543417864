import React from "react";
import { baseUrl } from "../api/url";

const EndPointComponent = () => {
  return (
    <div className="border-l-8  border-2  border-orange-600 p-2 relative">
      <pre className=" language-markup">
        <code className=" language-markup">
          <a className="token url-link" href={`${baseUrl}/api/v1/contacts`}>
            https://smsvendor.com/api/v1/contacts
          </a>
          <p className="absolute top-0 right-0 z-50 bg-gray-200 p-2">Mark Up</p>
        </code>
      </pre>
    </div>
  );
};

export default EndPointComponent;
