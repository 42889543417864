import React, { useEffect, useState } from "react";

import importdata from "../../assets/importdata.png";
import bulkaction from "../../assets/bulkaction.png";
import search from "../../assets/searchicon.png";
import DataTable from "react-data-table-component";
import axios from "axios";
import parser from "html-react-parser";
import { getToken } from "../../utils/tokenUtils";
import { showFailedToastAlert } from "../../toastAlert.js/toastalert";
import { baseUrl } from "../../api/url";
import ViewCampaignModal from "../ViewCampaignModal";

const Campaigns = () => {
  const [campaigns, setCampaigns] = useState<any>();
  const [modal, setModal] = useState<any>(false);
  const token = getToken();
  const modalHandle = (row: any) => {
    setModal({ open: !modal?.open, data: row });
  };

  useEffect(() => {
    // Getting Campaigns
    let data = JSON.stringify({
      length: 1000000,
      start: 0,
      orderBy: "id",
      orderDir: "desc",
      search: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      // url: "https://smsvendor.com/api/v1/customers/reports/search/campaigns",
      url: `${baseUrl}/customers/reports/search/campaigns`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const data = response?.data;
        console.log(data, "response");
        if (data?.status === "success") {
          setCampaigns(data);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns: any = [
    {
      name: "Name",
      selector: (row: any) => (
        <div>
          <span>{row?.campaign_name}</span>
          <span></span>
        </div>
      ),
    },

    {
      name: "Contacts",
      selector: (row: any) => row?.contacts,
      // cell: row => <> <label className="switch">
      //     <input type="checkbox" checked />
      //     <span className="slider round"></span>
      // </label></>
    },
    {
      name: "SMS Type",
      selector: (row: any) => parser(row?.sms_type),
    },
    {
      name: "Campaign Type",
      selector: (row: any) => parser(row?.schedule_type),
    },
    {
      name: "Message",
      selector: (row: any) => row?.message,
    },
    {
      name: "Status",
      selector: (row: any) => row?.status,
    },
    {
      name: "Action",
      selector: (row: any) => (
        <div className=" flex items-center gap-3 justify-center ">
          <div
            onClick={() => {
              modalHandle(row);
            }}
          >
            <svg
              fill="#F79236"
              width="19px"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 5.5A2.59 2.59 0 0 0 5.33 8 2.59 2.59 0 0 0 8 10.5 2.59 2.59 0 0 0 10.67 8 2.59 2.59 0 0 0 8 5.5zm0 3.75A1.35 1.35 0 0 1 6.58 8 1.35 1.35 0 0 1 8 6.75 1.35 1.35 0 0 1 9.42 8 1.35 1.35 0 0 1 8 9.25z" />
              <path d="M8 2.5A8.11 8.11 0 0 0 0 8a8.11 8.11 0 0 0 8 5.5A8.11 8.11 0 0 0 16 8a8.11 8.11 0 0 0-8-5.5zm5.4 7.5A6.91 6.91 0 0 1 8 12.25 6.91 6.91 0 0 1 2.6 10a7.2 7.2 0 0 1-1.27-2A7.2 7.2 0 0 1 2.6 6 6.91 6.91 0 0 1 8 3.75 6.91 6.91 0 0 1 13.4 6a7.2 7.2 0 0 1 1.27 2 7.2 7.2 0 0 1-1.27 2z" />
            </svg>
          </div>
        </div>
      ),
    },
  ];
  return (
    <>
      {" "}
      <ViewCampaignModal
        modalHandle={modalHandle}
        modalOpen={modal?.open}
        data={modal?.data}
      />
      <div className="bg-white px-[20px] rounded-lg my-10 shadow">
        <div className=" py-[24px] items-center flex justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[20px]">
            Campaigns
          </h2>

          {/* <div className="flex gap-[15px]">
          <div className="flex items-center bg-[#2AA3001A] gap-[6px]  p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[#2AA300] text-[12px] leading-[14.06px]">
              Export Data
            </p>{" "}
            <img src={importdata} alt="" />
          </div>
          <div className="flex items-center bg-[#F1602B1A] gap-[6px] p-[10px] rounded-[3px] cursor-pointer">
            <p className="text-[12px] leading-[14.06px] text-[#F1602B]">
              Bulk Action
            </p>
            <img src={bulkaction} alt="" />
          </div>
        </div> */}
        </div>
        <div className="pl-[20px] md:pl-[36px] my-[24px]">
          {/* <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] bg-white text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div> */}
          <div className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
            <input
              type="text"
              placeholder="Search"
              className="pt-[14px] pl-[14px] pb-[13px] bg-white outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
            />
            <img src={search} alt="" className="cursor-pointer" />
          </div>
        </div>
        <DataTable
          columns={columns}
          data={campaigns?.data?.data}
          selectableRows
          className="px-[10px]"
          pagination
        />
        {/* <MessagesTable /> */}
      </div>
    </>
  );
};

export default Campaigns;
