import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import subscribe from "../../../assets/subscribe.png";
import info from "../../../assets/info.png";
import Line from "../../chart/line";
import CurrentPlan from "../../CurrentPlan";
import ContactGroups from "../../DashBoardContactGroups";
import SmsPieChart from "../SmsReportPieChart";
import ContactsCircularChart from "../Contacts/ContactsCircularChart";
import OutgoingGraph from "../../Graph/Outgoing/OutgoingGraph";
import { getStatistics } from "../../../api/dashboard/getStatistics";
import Statistics from "../Statistics";

const MainDashBoardComponent = () => {
  const [statisticsData, setStatisticsData] = useState({});

  const percentage = 0;
  const percentages = 0;

  useEffect(() => {
    fetchStatistics();
  }, []);

  const fetchStatistics = async () => {
    const response = await getStatistics();
    const data = response?.data.data;
    setStatisticsData(data);
  };
  return (
    <div>
      <div className="w-full my-3 px-[20px] md:px-0 ">
        <h2 className="text-[20px] leading-[32px ] mb-2 font-semibold text-orange-600">
          Welcome to Easy Service App
        </h2>
        <div className="flex gap-5 max-sm:flex-col  ">
          <Statistics statisticsData={statisticsData} />
          <CurrentPlan subscribe={subscribe} info={info} />
        </div>

        <div className="flex flex-col gap-[10px]">
          <div className="flex items-center max-sm:flex-col max-sm:pt-5"></div>
          <div className="flex gap-5 max-sm:flex-col  ">
            <div className="bg-white basis-[50%] w-[50%] max-sm:w-full max-h-[320px] relative rounded-xl shadow">
              <h2 className="text-[18px] leading-[25.6px] text-[#5F6368] pb-[10px] font-extrabold text-[var(--primary-color)] pt-5 pl-5">
                SMS Report
              </h2>
              <div className="flex justify-center py-4">
                <SmsPieChart />
              </div>
            </div>
            <div className="bg-white basis-[50%]  w-[50%] max-sm:w-full max-h-[350px] relative  rounded-xl shadow ">
              <p className=" text-[18px] leading-[25.6px] text-[#5F6368] pb-[10px] font-extrabold text-[var(--primary-color)] pt-5 px-5 ">
                Outgoing SMS History of this Month
              </p>
              <div className=" flex justify-center my-0 mobile:my-4 p-4 lg:p-0 ">
                <OutgoingGraph />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashBoardComponent;
