import React, { useEffect, useState } from "react";
import Pagetitle from "../../reusable/pagetitle";
import DataTable from "react-data-table-component";
import { getToken } from "../../../utils/tokenUtils";
import axios from "axios";
import { baseUrl } from "../../../api/url";

const PlanDetails = () => {
  const token = getToken(); // To get token

  // state variable for planDetails
  const [planDetails, setPlanDetails] = useState<any>();

  // state variable for plan "options"
  const [planOptions, setPlanOptions] = useState<any>();

  const OptArray: any = [];

  // data fetched from api

  useEffect(() => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/customers/subscriptions`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      axios.request(config).then((res) => {
        console.log(res?.data?.data);
        setPlanDetails(res?.data?.data?.plan);
        setPlanOptions(JSON.parse(res?.data?.data?.plan?.options)); // converted the JSON configuration into a JavaScript object
        // OptArray.push(planOptions)
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  console.log(planOptions, "opt");
  OptArray.push(planOptions);
  console.log(OptArray, "arr");

  const columns = [
    {
      name: <div className="text-orange-600">Plan Name</div>,
      selector: (row) => row.name,
    },
    {
      name: (
        <div className="text-[var(--primary-color)]">Ecommerce Test API</div>
      ),
      selector: (row) => row.value,
    },
  ];

  // Data for the table
  const data = [
    {
      id: 1,
      name: "Plan Name",
      value: "IC-pro",
    },
    {
      id: 2,
      name: "price",
      value: planOptions?.per_unit_price,
    },
    {
      id: 3,
      name: "Renew",
      value: planDetails?.frequency_amount,
    },
    {
      id: 4,
      name: "Sms Unit",
      value: planOptions?.sms_max,
    },
    {
      id: 4,
      name: "Create own sending server",
      value: planOptions?.sms_max,
    },
  ];

  return (
    <div className="pt-10">
      <Pagetitle title="Plan Details" />
      <DataTable columns={columns} data={data} />
    </div>
  );
};

export default PlanDetails;
