import axios from "axios";
import { baseUrl } from "../url";
import { getToken } from "../../utils/tokenUtils";

export const getTransaction = async (payload) => {
  const token = getToken();
  const config = {
    method: "POST",
    maxBodyLength: Infinity,
    url: `${baseUrl}/customers/transactions/search`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "Application/json",
    },
    data: payload,
  };
  return await axios.request(config);
};
