const InfoMessage = ({ message }) => {
  return (
    <div className="bg-blue-200 border-t-4 border-blue-500 rounded-b p-4 shadow-md">
      <div className="flex">
        <div className="flex-shrink-0">
          {/* Info icon or any other icon */}
          <svg
            className="h-6 w-6 text-blue-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* Add your icon path here */}
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-sm leading-5 font-medium text-blue-900">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoMessage;
