import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { showFailedToastAlert } from "../../../toastAlert.js/toastalert";
import axios from "axios";
import { getToken } from "../../../utils/tokenUtils";
import { baseUrl } from "../../../api/url";
import { NavLink, useNavigate } from "react-router-dom";

const Balance = () => {
  const [balance, setBalance] = useState<string>("");


  const token = getToken();
  useEffect(() => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${baseUrl}/balance`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            setBalance(data?.data?.remaining_balance);
          } else {
            showFailedToastAlert(data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) {
      showFailedToastAlert(error?.message);
    }
  }, []);
  // console.log(balance);
  const navigate = useNavigate();
  return (
    <div className="text-[12px] gap-2 sm:flex border-x-2 px-2 large_mobile:px-5">
      <div className="flex flex-col items-center">
        <span className="font-light">Balance</span>
        <span className="text-green-500">{balance}</span>
      </div>

      <span
        className="border-2 rounded cursor-pointer hover:bg-green-500 hover:text-white  flex items-center px-2 border-green-500 whitespace-nowrap"
        onClick={() => navigate("/account/top-up")}
      >
        Top up
      </span>
    </div>
  );
};

export default Balance;
