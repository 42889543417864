import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getPlans } from "../../api/Plans/FetchPlans";
import { register } from "../../api/User/Register";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
import { sendOtp } from "../../api/otp/SendOtp";
import { AxiosError, AxiosResponse } from "axios";

const BillingInformation = ({
  setAccount,
  setBilling,
  setPersonal,
  setRegisterForm,
  registerForm,
}) => {
  const [plans, setPlans] = useState([]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(null);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const paymentOptions = [
    { id: "offline_payment", name: "Offline Payment", icon: "credit-card" },
    { id: "khalti", name: "Khalti", icon: "Khalti" },
    // Add more payment options as needed
  ];

  const handleOptionSelect = (optionId) => {
    setSelectedPaymentOption(optionId);
    setRegisterForm((prev) => ({
      ...prev,
      payment_methods: optionId,
    }));
  };

  const handlePlanClick = (planId) => {
    setSelectedPlanId(planId);
    setRegisterForm((prev) => ({
      ...prev,
      plans: planId,
    }));
  };

  useEffect(() => {
    fetchPlan();
  }, []);

  const fetchPlan = async () => {
    const planData = await getPlans();
    if (planData.status === 200) {
      setPlans(planData.data.data);
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = await register(registerForm);
      if (data.data.status === "error") {
        setLoading(false);
        showFailedToastAlert(data.data.message);
      } else {
        setLoading(false);
        await sendOtp({ phone: registerForm.phone });
        showSuccessfulToastAlert("User Registered Successfully");
        navigate("/otp-input", { state: { phone: registerForm.phone } });
      }
    } catch (err: any) {
      showFailedToastAlert(err.response.data.message);
      setLoading(false);
    }
  };
  return (
    <div>
      <div>
        {plans && plans.length > 0 && (
          <>
            <p className=" mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full  font-normal">
              Select Plans
            </p>
            <p className=" mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal">
              Select plan as per your retirement
            </p>
            {/* plans  */}
            <div className="flex gap-3">
              {plans.map((plan: { id; name; price; billing_cycle }) => {
                return (
                  <div
                    key={plan.id}
                    className={`${
                      selectedPlanId === plan.id
                        ? "bg-blue-100" // Add a different style for selected plan
                        : "bg-gray-100"
                    } inline-block my-5 p-5 border-2 rounded-lg border-orange-500`}
                    onClick={() => handlePlanClick(plan.id)}
                  >
                    <p>{plan.name}</p>
                    <span className="text-[var(--primary-color)]">
                      <p>
                        Rs.{plan.price}
                        <sub>/ {plan.billing_cycle}</sub>
                      </p>
                    </span>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>

      <p className=" mt-[41px] text-[22px] leading-[16.41px] text-[#545454] w-full  font-normal">
        Payment Options
      </p>
      <p className=" mt-[20px] text-[14px] leading-[16.41px] text-[#545454] w-full  font-normal">
        Be sure to click on correct payment options
      </p>
      {/* payment options */}
      <div className="bg-white rounded-lg p-6 shadow-md w-80">
        <ul className="space-y-2">
          {paymentOptions.map((option) => (
            <li
              key={option.id}
              className={`flex items-center space-x-3 p-2 rounded-md hover:bg-gray-100 cursor-pointer ${
                selectedPaymentOption === option.id ? "bg-blue-100" : ""
              }`}
              onClick={() => handleOptionSelect(option.id)}
            >
              <span
                className={`text-lg ${
                  selectedPaymentOption === option.id ? "text-blue-500" : ""
                }`}
              >
                <i className={`fab fa-${option.icon}`}></i>
              </span>
              <span
                className={`text-base font-medium ${
                  selectedPaymentOption === option.id ? "text-blue-500" : ""
                }`}
              >
                {option.name}
              </span>
            </li>
          ))}
        </ul>
      </div>
      {/* Buttons */}
      <div className="flex gap-5 justify-between">
        <NavLink to="/">
          <div className="w-[200px]">
            <button
              type="submit"
              className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
              onClick={() => {
                setPersonal(true);
                setAccount(false);
                setBilling(false);
              }}
            >
              back
            </button>
          </div>
        </NavLink>
        <div className="w-[200px]">
          <button
            type="submit"
            className="btn w-full text-[14px] leading-[16.41px] font-normal text-[#F9F9F9] py-[14px] px-[14px] mt-[24px]"
            onClick={() => {
              handleSubmit();
            }}
            disabled={loading}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BillingInformation;
