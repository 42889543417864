import React, { useState } from "react";
import {
  HiOutlineChevronRight,
  HiOutlineIdentification,
  HiTemplate,
} from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { IoIosSend } from "react-icons/io";
import { dropUpDownArrow } from "../../dropUpDownarrow";
import { useDispatch, useSelector } from "react-redux";
import {BsSend} from "react-icons/bs"

import { CgNotes } from "react-icons/cg";
import { setDropDown } from "../../../redux/sideNavSlice/SideNavSlice";

const Sending = (props: any) => {
  const [dropDown, setDropDown] = useState(false);
  const { sendingg, color2, handlePageComponent } = props;

  const active = useSelector((state: any) => state?.sideNavSlice?.activeNav);

  return (
    <div className="flex items-center flex-col gap-[8px] rounded-[6px] w-[223px] cursor-pointer ">
      <div
        className={
          active === "sending"
            ? "  flex items-center nav_active gap-[16px] py-[12px] pl-[18px] nav_link w-full  rounded-[6px] group"
            : "flex items-center gap-[16px] py-[12px] pl-[18px] nav_link w-full  rounded-[6px] group"
        }
        onClick={() => {
          setDropDown(!dropDown);
        }}
      >
        <div className="flex items-center gap-[16px]">
          {/* <img src={sending} alt="" className='group-hover:invert' /> */}
          <BsSend />
          <p
            className={
              active === "sending"
                ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
            }
          >
            Sending
          </p>
        </div>
        {dropUpDownArrow(dropDown)}
      </div>

      {dropDown && (
        <div className="w-full bg-[#006AAD1A] transition duration-300 ease-out flex flex-col gap-[8px]">
          <NavLink to="senderid">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
              <HiOutlineIdentification />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Sender ID
              </p>
            </div>
          </NavLink>
          {/* <NavLink to="/numbers">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
              <CgNotes />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Numbers
              </p>
            </div>
          </NavLink> */}
          {/* <NavLink to="/sms_template">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
              <HiTemplate />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                SMS Templates
              </p>
            </div>
          </NavLink> */}
        </div>
      )}
    </div>
  );
};

export default Sending;
