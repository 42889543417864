import React, { useState } from "react";

interface FileInputProps {
  setFile: any;
  type: string;
}

const FileInput: React.FC<FileInputProps> = ({ setFile, type }) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setFile(file);
    }
  };

  return (
    <input
      type={type}
      onChange={handleFileChange}
      className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
    />
  );
};

export default FileInput;
