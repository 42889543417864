export const GET_SMS_TEMPLATE = "GET_SMS_TEMPLATE";
export const SET_SMS_TEMPLATE = "SET_SMS_TEMPLATE";
export const GET_BLOCK_LIST = "GET_BLOCK_LIST";
export const SET_BLOCK_LIST = "SET_BLOCK_LIST";
export const GET_CONTACT_GROUP = "GET_CONTACT_GROUP";
export const SET_CONTACT_GROUP = "SET_CONTACT_GROUP";
export const GET_SENDERID_LIST = "GET_SENDERID_LIST";
export const SET_SENDERID_LIST = "SET_SENDERID_LIST";
export const GET_NUMBER_LIST = "GET_NUMBER_LIST";
export const SET_NUMBER_LIST = "SET_NUMBER_LIST";
