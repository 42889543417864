import { Button } from "@mui/material";
import { IoMdSave } from "react-icons/io";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import FileInput from "../reusable/FileInput";
import { storeDocuments } from "../../api/UserDocuments/Store";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
import { getDocuments } from "../../api/UserDocuments/Get";
import InfoMessage from "../reusable/InfoMessage";

const UserDocument = () => {
  // input states

  const [businessId, setBusinessId] = useState<string>("");
  const [citizenshipId, setCitizenshipId] = useState<string>("");
  const [businessImage, setBusinessImage] = useState<File>();
  const [citizenshipImage, setCitizenshipImage] = useState<File>();

  const [businessImageUrl, setBusinessImageUrl] = useState("");
  const [citizenshipImageUrl, setCitizenshipImageUrl] = useState("");
  const [userDocument, setUserDocument] = useState<boolean>(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await getDocuments();
      console.log("response", response);
      if (response.data.status !== "error") {
        setBusinessId(response.data.data.business_id);
        setCitizenshipId(response.data.data.citizenship_id);
        setBusinessImageUrl(response.data.data.business_image);
        setCitizenshipImageUrl(response.data.data.citizenship_image);
      }
    } catch (err) {
      setUserDocument(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const documentData = await storeDocuments({
      businessId,
      citizenshipId,
      citizenshipImage,
      businessImage,
    });
    if (documentData.status === 200) {
      showSuccessfulToastAlert(documentData.data.message);
    } else {
      showFailedToastAlert(documentData.data.message);
    }
  };

  return (
    <div>
      {!userDocument && (
        <InfoMessage
          message={
            "You have not submitted your business documents. Please submit them as soon as possible."
          }
        />
      )}
      <form
        action=""
        className="flex p-5 gap-10"
        id="Information form"
        onSubmit={handleSubmit}
      >
        <div className="w-1/2 flex-col flex gap-5">
          <h3>User Document Information</h3>

          <div className="flex flex-col gap-3 mt-1">
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Business ID (PAN/VAT)
              </p>
              <input
                name="business_id"
                value={businessId}
                type="text"
                required
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
                onChange={(e) => {
                  setBusinessId(e.target.value);
                }}
              />
            </div>
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Citizenship ID
              </p>
              <input
                name="citizenship_id"
                value={citizenshipId}
                onChange={(e) => {
                  setCitizenshipId(e.target.value);
                }}
                type="text"
                className="border bg-white mt-[4px] w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 flex flex-col gap-5 mt-10">
          <div className="flex flex-col gap-3">
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Business Document Image
              </p>
              <FileInput type="file" setFile={setBusinessImage} />
              {businessImageUrl && (
                <div className="my-2">
                  <h3>Current Image</h3>
                  <img src={businessImageUrl} alt="business_image"></img>
                </div>
              )}
            </div>
          </div>
          <hr></hr>
          <div className="flex flex-col gap-3">
            <div>
              <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
                Citizenship Image
              </p>
              <FileInput type="file" setFile={setCitizenshipImage} />
              {citizenshipImageUrl && (
                <div className="my-2">
                  <h3>Current Image</h3>
                  <img src={citizenshipImageUrl} alt="citizenship_image"></img>
                </div>
              )}
            </div>
          </div>
          <button
            className="self-end hover:cursor-pointer"
            id="Information form"
            type="submit"
          >
            <Stack direction="row" spacing={2}>
              <Button variant="contained" endIcon={<IoMdSave />}>
                Save
              </Button>
            </Stack>
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserDocument;
