import { useEffect, useState } from "react";

import search from "../../assets/searchicon.png";
import DataTable from "react-data-table-component";

import { SmileOutlined } from "@ant-design/icons";
import { MenuProps, Select } from "antd";
import axios from "axios";
import { toast } from "react-toastify";

import * as Filesaver from "file-saver";
import * as XLSX from "xlsx";

import { baseUrl } from "../../api/url";
import { getToken } from "../../utils/tokenUtils";
import { useNavigate } from "react-router-dom";
import { showFailedToastAlert } from "../../toastAlert.js/toastalert";
import parser from "html-react-parser";
import ViewMessageModalComponent from "../viewMessageModalComponent";
import FormSkeleton from "../FormSkeleton";
import HandlingPagination from "../handlingPaginagination";
import { bulkAction } from "../BulkActionMessage";
import { api } from "../../globals/baseUrl";

const Allmessage = () => {
  const [modal, setModal] = useState({
    open: false,
    id: "",
  });
  const [openedMessageId, setmessageId] = useState<any>();
  const [next, setNext] = useState<any>(0);
  const [Allmessage, setAllMessage] = useState<any>();
  const [detail, setDetailMessage] = useState<any>();
  const [id, setId] = useState<any>();
  const [bulkedMessages, setBulkedMessages] = useState<any>();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [noOfRows, setNoOfRows] = useState(10);

  console.log(Allmessage);

  // console.log(Allmessage, "allmessage");
  const deleteMessage = async (id: string) => {
    // console.log(id);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/reports/${id}/destroy`,
      headers: {
        Authorization: `Bearer Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          toast.success(data?.message);
          navigate("/customer/dashboard/allmessage");
        }
      })
      .catch((error) => {
        console.log(error);
        showFailedToastAlert(error?.message);
      });
    // if (res.data.status === "success") {
    //   toast.success(res.data.message);
    // }
  };
  const token = getToken();

  const getMessages = () => {
    let data = JSON.stringify({
      length: 1536,
      start: next,
      orderBy: "id",
      orderDir: "DESC",
      search: "",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/reports/search`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data, "allmessages");
        const data = response?.data;
        if (data?.status === "success") {
          setAllMessage(data?.data);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
        showFailedToastAlert(error?.message);
      });
  };
  useEffect(() => {
    //GETTING ALL MESSAGES
    getMessages();
  }, []);
  // console.log(Allmessage);
  const exportMessage = () => {
    const messagesToExport = Allmessage?.data;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = "messsage";
    const ws = XLSX.utils.json_to_sheet(messagesToExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    Filesaver.saveAs(data, fileName + fileExtension);
  };
  const detailMessage = async (id: String) => {
    // setModal1Open(true);
    const res = await api.post(`/customers/reports/${id}/view`);
    // console.log(res.data.data, "res");
    setDetailMessage(res.data.data);
  };

  const modalHandle = (id: any) => {
    // console.log(id);
    setModal({ open: !modal?.open, id: id });
  };

  // const handleRowClicked = () => {};
  const columns: any = [
    {
      name: "Date",
      selector: (row) => (
        // <Moment format="  Do MMMM, YYYY  ">{row.created_at}</Moment>
        <div>{row?.created_at}</div>
      ),
    },
    {
      name: "Direction",
      selector: (row) => (
        <div
          className={` capitalize font-normal text-[12px]   bg-[#07499A]   text-white  px-3 py-1 rounded-[5px] w-full `}
        >
          {parser(`<div>
          ${row.send_by}
          </div>
          `)}
        </div>
      ),
    },
    {
      name: "Type",
      selector: (row) => (
        <div
          className={` capitalize font-normal text-[12px]   bg-[#F47C30]   text-white  px-3 py-1 rounded-[5px] w-full `}
        >
          {parser(`<div>
          ${row.sms_type}
          </div>
          `)}
        </div>
      ),
    },
    {
      name: "From",
      selector: (row) => row.from,
    },
    {
      name: "To",
      selector: (row) => row.to,
    },
    {
      name: "Cost",
      selector: (row) => row.cost,
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className={` ${
            row.status === "Delivered" ? "bg-green-500 " : "bg-red-500"
          } capitalize font-normal text-[12px]  text-white px-3 py-1 rounded-[5px] w-full `}
        >
          {row.status}
        </p>
      ),
    },
    {
      name: "Action",
      selector: (row: any) => {
        // console.log(row);
        return (
          <div className=" flex items-center gap-3 justify-center ">
            <div
              onClick={() => {
                modalHandle(row?.uid);
              }}
            >
              <svg
                fill="#F79236"
                width="19px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5.5A2.59 2.59 0 0 0 5.33 8 2.59 2.59 0 0 0 8 10.5 2.59 2.59 0 0 0 10.67 8 2.59 2.59 0 0 0 8 5.5zm0 3.75A1.35 1.35 0 0 1 6.58 8 1.35 1.35 0 0 1 8 6.75 1.35 1.35 0 0 1 9.42 8 1.35 1.35 0 0 1 8 9.25z" />
                <path d="M8 2.5A8.11 8.11 0 0 0 0 8a8.11 8.11 0 0 0 8 5.5A8.11 8.11 0 0 0 16 8a8.11 8.11 0 0 0-8-5.5zm5.4 7.5A6.91 6.91 0 0 1 8 12.25 6.91 6.91 0 0 1 2.6 10a7.2 7.2 0 0 1-1.27-2A7.2 7.2 0 0 1 2.6 6 6.91 6.91 0 0 1 8 3.75 6.91 6.91 0 0 1 13.4 6a7.2 7.2 0 0 1 1.27 2 7.2 7.2 0 0 1-1.27 2z" />
              </svg>
            </div>
            <div
              onClick={() => deleteMessage(row?.uid)}
              className="cursor-pointer"
            >
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.625 16.5C2.14375 16.5 1.73192 16.3261 1.3895 15.9782C1.0465 15.6298 0.875 15.2111 0.875 14.7222V3.16667H0V1.38889H4.375V0.5H9.625V1.38889H14V3.16667H13.125V14.7222C13.125 15.2111 12.9538 15.6298 12.6114 15.9782C12.2684 16.3261 11.8562 16.5 11.375 16.5H2.625ZM11.375 3.16667H2.625V14.7222H11.375V3.16667ZM4.375 12.9444H6.125V4.94444H4.375V12.9444ZM7.875 12.9444H9.625V4.94444H7.875V12.9444Z"
                  fill="#EF4444"
                />
              </svg>
            </div>
          </div>
        );
      },
    },
  ];
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => bulkAction(id)}
        >
          Delete All
        </a>
      ),
      icon: <SmileOutlined />,
    },
  ];

  const handleChange = ({ selectedRows }) => {
    const ids = selectedRows.map((element) => {
      return element.uid;
    });
    console.log(ids);
    // setId(ids);
  };

  const searchMessage = () => {
    console.log(searchQuery);
    let data = JSON.stringify({
      length: 10,
      start: 0,
      orderBy: "id",
      orderDir: "ASC",
      search: searchQuery,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${token}/customers/reports/search`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        const data = response?.data;
        if (data?.status === "success") {
          setAllMessage(data);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
        showFailedToastAlert(error?.message);
      });
  };

  const handleSearchChange = (e: any) => {
    e.preventDefault();
    setSearchQuery(e?.target?.value);
  };

  // data that have been returned from pagination.
  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  return (
    <div className="shadow-sm rounded-xl shadow-black h-[80vh] m-10">
      <ViewMessageModalComponent
        modalHandle={modalHandle}
        modalOpen={modal?.open}
        messageId={modal?.id}
      />
      <div className="bg-white">
        <div className=" mt-[16px] pt-[23px] pb-[12px] items-center flex justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
          <div className="flex items-center">
            <h2 className="text-[var(--primary-color)] text-[18px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
              All Messages
            </h2>
            <Select
              onChange={(value: any) => setNoOfRows(value)}
              options={[
                { value: 10 },
                { value: 20 },
                { value: 50 },
                { value: 100 },
              ]}
              style={{ width: "100px", marginLeft: "15px" }}
              defaultValue={10}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
          <div
            className="border border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between"
            onClick={searchMessage}
          >
            <input
              type="text"
              placeholder="Search"
              onChange={handleSearchChange}
              className="pt-[14px] pl-[14px] pb-[13px] outline-none bg-white placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
            />
            <img src={search} alt="" className="cursor-pointer" />
          </div>
        </div>

        {!Allmessage ? (
          <FormSkeleton titles={["Name", "Current Plan", "Status", "Action"]} />
        ) : (
          <DataTable
            columns={columns}
            data={dataForTable}
            selectableRows
            fixedHeader
            highlightOnHover
            onSelectedRowsChange={handleChange}
            selectableRowsHighlight
          />
        )}

        <HandlingPagination
          data={Allmessage?.data}
          getDataForTable={getDataForTable}
        />
      </div>
    </div>
  );
};

export default Allmessage;
