import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import search from "../assets/searchicon.png";
import { Switch } from "antd";
import axios from "axios";
import { apiBaseUrl, imageBaseUrl } from "../globals/baseUrl";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Account from "./ProfilePageComponents/Account";
import Security from "./ProfilePageComponents/security";
import Notification from "./ProfilePageComponents/Notification";
import { MdInfo } from "react-icons/md";
import { FiInfo } from "react-icons/fi";
import Information from "./ProfilePageComponents/Information";
import { baseUrl } from "../api/url";
import UserDocument from "./ProfilePageComponents/UserDocument";

const columns: any = [
  {
    name: "Type",
    selector: (row) => row?.name,
  },
  {
    name: "Message",
    selector: (row) => row?.current,
  },
  {
    name: "Mark as Read",
    selector: (row) => <Switch defaultChecked />,
    // cell: row => <> <label className="switch">
    //     <input type="checkbox" checked />
    //     <span className="slider round"></span>
    // </label></>
  },
  {
    name: "Action",
    selector: (row) => "",
  },
];

// const props: UploadProps = {
//   // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
//   onChange({ file, fileList }) {
//     // console.log(file, "kk");
//     if (file.status !== "uploading") {
//       // console.log(file, fileList);
//     }
//   },
// //   defaultFileList: [
// //     {
// //       uid: "1",
// //       name: "xxx.png",
// //       status: "uploading",
// //       url: "http://www.baidu.com/xxx.png",
// //       percent: 33,
// //     },
// //     {
// //       uid: "2",
// //       name: "yyy.png",
// //       status: "done",
// //       url: "http://www.baidu.com/yyy.png",
// //     },
// //     {
// //       uid: "3",
// //       name: "zzz.png",
// //       status: "error",
// //       response: "Server Error 500", // custom error message to show
// //       url: "http://www.baidu.com/zzz.png",
// //     },
// //   ],
// };

type Props = {};

const onChange = (key: string) => {
  console.log(key);
};

let token = localStorage.getItem("token");

const languages = [
  "English",
  "German",
  "French",
  "Portugese",
  "Arabic",
  "Spanish",
  "Italian",
  "Korean",
  "Slovenian",
  "Chinese",
];

const Profile = (props: Props) => {
  //const user = useSelector((state: any) => state?.userSlice?.user);
  const [user, setUser] = useState<any>([]);
  const [data, setData] = useState([
    {
      id: Number,
      name: "",
      current: "",
    },
  ]);
  const [userCredentials, setUserCredentials] = useState({
    photo: File,
    email: user?.email,
    timezone: user?.timezone,
    first_name: user?.first_name,
    last_name: user?.last_name,
    locale: "en",
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });
  useEffect(() => {
    userCredentials.email = user?.email;
    userCredentials.timezone = user?.timezone;
    userCredentials.first_name = user?.first_name;
    userCredentials.last_name = user?.last_name;
    userCredentials.locale = user?.locale;
  }, [user]);
  console.log(userCredentials);
  // const data = [
  //   {
  //     id: 1,
  //     name: "SalesBerry App",
  //     current: "Ecommerce-API",
  //   },
  //   {
  //     id: 2,
  //     name: "Customer App",
  //     current: "IC-Pro",
  //   },
  // ];

  useEffect(() => {
    axios
      .get(`${baseUrl}/users/account`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the successful response here
        const data = response?.data;
        console.log(data);
        setUser(data.data.user);
      })
      .catch((error) => {
        // Handle the error here
        console.error(error);
      });
  }, []);

  useEffect(() => {
    // Getting All Notifications

    var data = JSON.stringify({
      length: 10,
      start: 0,
      orderBy: "message",
      orderDir: "asc",
      search: "",
    });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/users/account/notifications`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // // console.log(response.data);
        const data = response?.data;
        setData(data?.data?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleChanges = (e: any) => {
    const { value, name } = e.target;
    // console.log(value);
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  const navigate = useNavigate();

  // Handling and Changing Password
  const handleChangePassword = async (e: any) => {
    e.preventDefault();
    // console.log(userCredentials);

    var data = {
      current_password: userCredentials?.current_password,
      password: userCredentials?.new_password,
      password_confirmation: userCredentials?.confirm_new_password,
      _token: "rsLTFIs0VFJt56zlrR8fEZYrsL3QnrnBdYD8VsMd",
    };

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/users/account/change-password`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        const data = response?.data;
        // console.log(data);
        if (data?.status === "success") {
          toast.success("Password Changed!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          navigate("/login");
        } else {
          toast.error("Password Update Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <div className="hover:bg-[#0092D7] hover:text-white flex  items-center gap-[11.5px] py-[12px] px-[33.37px] rounded-[5px]">
          <svg
            width="10"
            height="12"
            viewBox="0 0 10 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.877479 10.0696L0.874964 10.0674C0.84516 10.0421 0.824351 10.0125 0.81193 9.98233C0.799735 9.9527 0.795346 9.92196 0.798206 9.89198C0.899441 8.96878 1.35831 8.10791 2.09468 7.47822C2.83232 6.84744 3.79502 6.49494 4.79734 6.49494C5.79966 6.49494 6.76237 6.84744 7.5 7.47822C8.23642 8.10795 8.6953 8.96888 8.7965 9.89215C8.7994 9.92207 8.79504 9.95278 8.78286 9.98237C8.77047 10.0125 8.74971 10.042 8.71995 10.0672L8.71994 10.0672L8.71723 10.0696C7.64799 10.9896 6.25056 11.5019 4.79753 11.5L4.79629 11.5C3.34355 11.5017 1.94645 10.9895 0.877479 10.0696ZM6.37072 4.02685C5.95764 4.41849 5.39196 4.64285 4.79689 4.64285C4.20182 4.64285 3.63614 4.41849 3.22306 4.02685C2.81089 3.63608 2.58466 3.11194 2.58466 2.57143C2.58466 2.03091 2.81089 1.50678 3.22306 1.116C3.63614 0.724366 4.20182 0.5 4.79689 0.5C5.39196 0.5 5.95764 0.724366 6.37072 1.116C6.7829 1.50678 7.00913 2.03091 7.00913 2.57143C7.00913 3.11194 6.7829 3.63608 6.37072 4.02685Z"
              stroke="#5F6368"
            />
          </svg>

          <p>Account</p>
        </div>
      ),
      children: (
        <Account user={userCredentials} handleChanges={handleChanges} />
      ),
    },
    {
      key: "2",
      label: (
        <div className="hover:bg-[#0092D7] hover:text-white flex  items-center gap-[11.5px] py-[12px] px-[33.37px] rounded-[5px]">
          {" "}
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.54976 7.72505C9.42826 7.72505 10.9498 6.20355 10.9498 4.32505C10.9498 2.44655 9.42826 0.925049 7.54976 0.925049C5.67126 0.925049 4.14976 2.44655 4.14976 4.32505C4.14976 6.20355 5.67126 7.72505 7.54976 7.72505ZM7.54976 2.62505C8.00062 2.62505 8.43303 2.80416 8.75184 3.12297C9.07065 3.44178 9.24976 3.87418 9.24976 4.32505C9.24976 4.77592 9.07065 5.20832 8.75184 5.52713C8.43303 5.84594 8.00062 6.02505 7.54976 6.02505C6.60626 6.02505 5.84976 5.26855 5.84976 4.32505C5.84976 3.38155 6.61476 2.62505 7.54976 2.62505ZM9.24976 14.525H0.749756V11.975C0.749756 9.70555 5.28026 8.57505 7.54976 8.57505C8.39976 8.57505 9.57276 8.73655 10.7033 9.05105C10.4483 9.52705 10.2953 10.054 10.2783 10.6065C9.44526 10.36 8.49326 10.19 7.54976 10.19C5.02526 10.19 2.36476 11.431 2.36476 11.975V12.91H9.24976V14.525ZM16.7298 11.975V10.7C16.7298 9.51005 15.5398 8.57505 14.3498 8.57505C13.1598 8.57505 11.9698 9.51005 11.9698 10.7V11.975C11.4598 11.975 10.9498 12.485 10.9498 12.995V15.97C10.9498 16.565 11.4598 17.075 11.9698 17.075H16.6448C17.2398 17.075 17.7498 16.565 17.7498 16.055V13.08C17.7498 12.485 17.2398 11.975 16.7298 11.975ZM15.6248 11.975H13.0748V10.7C13.0748 10.02 13.6698 9.59505 14.3498 9.59505C15.0298 9.59505 15.6248 10.02 15.6248 10.7V11.975Z"
              fill="#5F6368"
            />
          </svg>
          <p>Security</p>
        </div>
      ),
      children: (
        <Security
          handleChangePassword={handleChangePassword}
          userCredentials={userCredentials}
          handleChanges={handleChanges}
        />
      ),
    },
    {
      key: "3",
      label: (
        <div className="hover:bg-[#0092D7] hover:text-white flex  items-center gap-[11.5px] py-[12px] px-[33.37px] rounded-[5px]">
          {" "}
          <svg
            width="15"
            height="18"
            viewBox="0 0 15 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.259 11.2233L12.8014 9.63648V7.53438C12.7997 6.08168 12.3033 4.68126 11.4082 3.60391C10.513 2.52655 9.28275 1.8488 7.95521 1.70171V0.5H6.87829V1.70171C5.55075 1.8488 4.32047 2.52655 3.42533 3.60391C2.5302 4.68126 2.0338 6.08168 2.03213 7.53438V9.63648L0.574517 11.2233C0.473529 11.3332 0.416779 11.4823 0.416748 11.6378V13.3964C0.416748 13.5518 0.473479 13.7009 0.57446 13.8109C0.675441 13.9208 0.812401 13.9826 0.95521 13.9826H4.72444V14.438C4.71273 15.1817 4.9535 15.9039 5.40063 16.4663C5.84776 17.0287 6.46979 17.3917 7.14752 17.4857C7.52184 17.5261 7.8998 17.4808 8.25707 17.3526C8.61434 17.2245 8.94301 17.0163 9.22195 16.7416C9.5009 16.4668 9.72393 16.1316 9.87671 15.7574C10.0295 15.3831 10.1086 14.9783 10.1091 14.5688V13.9826H13.8783C14.0211 13.9826 14.1581 13.9208 14.259 13.8109C14.36 13.7009 14.4167 13.5518 14.4167 13.3964V11.6378C14.4167 11.4823 14.36 11.3332 14.259 11.2233ZM9.03213 14.5688C9.03213 15.0352 8.86194 15.4825 8.559 15.8123C8.25605 16.1421 7.84518 16.3273 7.41675 16.3273C6.98832 16.3273 6.57744 16.1421 6.2745 15.8123C5.97156 15.4825 5.80136 15.0352 5.80136 14.5688V13.9826H9.03213V14.5688ZM13.3398 12.8102H1.49367V11.8804L2.95129 10.2936C3.05227 10.1837 3.10903 10.0346 3.10906 9.87917V7.53438C3.10906 6.29062 3.5629 5.09781 4.37075 4.21834C5.1786 3.33887 6.27428 2.84479 7.41675 2.84479C8.55922 2.84479 9.6549 3.33887 10.4627 4.21834C11.2706 5.09781 11.7244 6.29062 11.7244 7.53438V9.87917C11.7245 10.0346 11.7812 10.1837 11.8822 10.2936L13.3398 11.8804V12.8102Z"
              fill="#5F6368"
            />
          </svg>
          <p>Notification</p>
        </div>
      ),
      children: <Notification search={search} data={data} columns={columns} />,
    },
    {
      key: "4",
      label: (
        <div className="hover:bg-[#0092D7] hover:text-white flex  items-center gap-[11.5px] py-[12px] px-[33.37px] rounded-[5px]">
          {" "}
          <FiInfo />
          <p>Information</p>
        </div>
      ),
      children: <Information user={user} />,
    },

    {
      key: "5",
      label: (
        <div className="hover:bg-[#0092D7] hover:text-white flex  items-center gap-[11.5px] py-[12px] px-[33.37px] rounded-[5px]">
          {" "}
          <FiInfo />
          <p>User Documents</p>
        </div>
      ),
      children: <UserDocument />,
    },
  ];

  const location = useLocation();

  return (
    <div className="bg-white rounded-[5px] mt-[13px] pl-[17px] shadow-sm shadow-black mb-10 min-h-[70vh]">
      <Tabs
        defaultActiveKey={location.state ? "3" : "1"}
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default Profile;
