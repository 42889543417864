import React, { useState } from "react";

interface TextPreviewProps {
  file: File | null;
  setValidPhoneNumbers: React.Dispatch<React.SetStateAction<string[]>>;
}

const TextPreview: React.FC<TextPreviewProps> = ({
  file,
  setValidPhoneNumbers,
}) => {
  const [text, setText] = useState<string>("");
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState<string[]>([]);

  const handleFileRead = (
    file: File,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    const reader = new FileReader();
    reader.onload = (e) => {
      const fileText = e.target!.result;
      if (fileText !== null && typeof fileText === "string") {
        setText(fileText);

        const phoneNumbers = fileText.split(",").map((number) => number.trim());
        const invalidNumbers = phoneNumbers.filter((number) => {
          return !isValidPhoneNumber(number);
        });
        setInvalidPhoneNumbers(invalidNumbers);

        // Filter and set valid phone numbers
        const validNumbers = phoneNumbers.filter(isValidPhoneNumber);
        setValidPhoneNumbers(validNumbers);
      }
    };
    reader.readAsText(file);
  };

  const isValidPhoneNumber = (phoneNumber: string) => {
    return phoneNumber.length === 10;
  };

  if (!file) return null;

  return (
    <div className="bg-white rounded shadow p-4">
      <div>
        <h3 className="mb-2">File: {file.name}</h3>
        <button
          onClick={(e) => handleFileRead(file, e)}
          className="bg-blue-500 text-white rounded py-2 px-4 hover:bg-blue-600"
        >
          Preview Text
        </button>
        <pre className="mt-4 whitespace-pre-wrap">{text}</pre>
        {invalidPhoneNumbers.length > 0 && (
          <div>
            <p className="text-red-600 mt-2">Invalid Phone Numbers:</p>
            <p className="text-red-600 mt-2">
              {invalidPhoneNumbers.join(", ")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextPreview;
