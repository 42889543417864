import React, { FC } from "react";
interface PreviewMessageProps {
  phoneNumber: string;
  message: string;
}

const PreviewMessage: FC<PreviewMessageProps> = ({ phoneNumber, message }) => {
  return (
    <div className="mb-4">
      <h2 className="text-lg font-medium mb-1">Preview</h2>
      <div className="border p-4 rounded-md bg-gray-50">
        <p className="text-gray-600">
          {message.split("/n").map((line, index) => (
            <React.Fragment key={index}>
              {line.split("/t").map((tabbed, tabIndex) => (
                <React.Fragment key={tabIndex}>
                  {tabIndex > 0 && "\t"}
                  {tabbed}
                </React.Fragment>
              ))}
              <br />
            </React.Fragment>
          ))}
        </p>
      </div>
    </div>
  );
};

export default PreviewMessage;
