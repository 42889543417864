import React, { useEffect, useState } from "react";

import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/searchicon.png";
import DataTable from "react-data-table-component";
import { NavLink } from "react-router-dom";
import withAuthHeader from "../globals/Auth";
import { apiBaseUrl } from "../globals/baseUrl";
import Swal from "sweetalert2";
import axios from "axios";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { getToken } from "../utils/tokenUtils";
import { baseUrl } from "../api/url";
import { useSelector } from "react-redux";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";

const columns: any = [
  {
    name: "Number",
    selector: (row: any) => row.number,
  },
  {
    name: "Remarks",
    selector: (row: any) => row.reason,
  },
  {
    name: "Action",
    selector: ({ uid, row }) => (
      <div className=" flex items-center justify-center ">
        <div className="flex gap-3 items-center justify-center">
          <NavLink to={`/blacklist/update/${uid}`}>
            <AiFillEdit className="text-2xl" />
          </NavLink>
          <svg
            width="14"
            height="17"
            viewBox="0 0 14 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              }).then(async (result) => {
                const token = getToken();
                // DELETING ROW
                try {
                  //deleting BLACKLIST
                  var config = {
                    method: "delete",
                    maxBodyLength: Infinity,
                    url: `${baseUrl}/customers/blacklists/${uid}`,
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  };
                  axios(config).catch(function (error) {
                    console.log(error);
                  });
                } catch (error) {
                  // console.log("AXIOS ERROR!", error);
                }

                if (result.isConfirmed) {
                  Swal.fire("Deleted!", "Successfully Deleted", "success").then(
                    () => {
                      window.location.reload();
                    }
                  );
                }
              })
            }
          >
            <path
              d="M2.625 16.5C2.14375 16.5 1.73192 16.3261 1.3895 15.9782C1.0465 15.6298 0.875 15.2111 0.875 14.7222V3.16667H0V1.38889H4.375V0.5H9.625V1.38889H14V3.16667H13.125V14.7222C13.125 15.2111 12.9538 15.6298 12.6114 15.9782C12.2684 16.3261 11.8562 16.5 11.375 16.5H2.625ZM11.375 3.16667H2.625V14.7222H11.375V3.16667ZM4.375 12.9444H6.125V4.94444H4.375V12.9444ZM7.875 12.9444H9.625V4.94444H7.875V12.9444Z"
              fill="#EF4444"
            />
          </svg>
        </div>
      </div>
    ),
  },
];

const Blacklist = () => {
  const [blackListNumbers, setBlackListNumbers] = useState<any>();

  const [inputSearch, setInputSearch] = useState<any>("");

  const [idToDelete, setIdToDelete] = useState("");
  const token = getToken();

  useEffect(() => {
    //Getting ALL BLACKLIST
    let data = JSON.stringify({
      length: 1000000,
      start: 0,
      orderBy: "id",
      orderDir: "DESC",
      search: "",
    });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/blacklists/search`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        const data = response?.data;
        console.log(response);
        setBlackListNumbers(data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // handling onKeyDown for searching blacklist phone numbers.
  const handleSearch = () => {
    var data = JSON.stringify({
      length: 10,
      start: 0,
      orderBy: "id",
      orderDir: "desc",
      search: inputSearch,
    });

    var config = {
      method: "post",
      maxBodyLength: Infinity,
      data: data,
      url: `${baseUrl}/customers/blacklists/search`,

      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        const data = response?.data;
        setBlackListNumbers(data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDeleteAll = (e: any) => {
    e.preventDefault();
    try {
      var data = JSON.stringify({
        query: ``,
        variables: {},
      });

      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/customers/blacklists/batch_action`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          const data = response?.data;

          if (data?.status === "Success") {
            Swal.fire("Deleted!", "Everything has been deleted.", "success");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: '<a href="">Why do I have this issue?</a>',
            });
          }
        })
        .catch(function (error) {
          // console.log(error);
          showFailedToastAlert(`Error getting Blacklist ${error?.message}`);
        });
    } catch (error: any) {
      showFailedToastAlert(`Error getting Blacklist ${error?.message}`);
      console.log("Axios Error");
    }
  };

  return (
    <div className="bg-white shadow rounded-lg my-10 px-[20px] ">
      <div className="py-[24px]  items-center flex justify-between pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-bold pt-[4px] pl-[20px]">
          Blacklist
        </h2>
      </div>

      <div className="pl-[20px] md:pl-[36px] my-[24px] grid gap-y-4  md:flex">
        <div className="border border-[#D9D9D9] rounded-[2px] flex items-center pr-[12px] w-[257px] justify-between">
          <input
            value={inputSearch}
            onChange={(e) => setInputSearch(e.target.value)}
            onKeyDown={handleSearch}
            type="text"
            placeholder="Search"
            className="pt-[14px] pl-[14px] bg-white pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          <img src={search} alt="" className="cursor-pointer" />
        </div>
        <div
          className="flex items-center justify-center bg-[#F1602B1A] gap-[6px] rounded-[3px] px-2  py-[14px] md:py-0 max-w-[120px] cursor-pointer mx-0 md:mx-6"
          onClick={handleDeleteAll}
        >
          <p className="text-[12px] leading-[14.06px] text-[#F1602B]">
            Delete All
          </p>
          {/* <img src={bulkaction} alt="" /> */}
          <AiFillDelete className="text-red-400" />
        </div>
        <NavLink to="add-new">
          <div className="flex items-center justify-center bg-[#003D7E] text-white max-w-[120px] gap-[6px] p-[10px] rounded-[3px] cursor-pointer py-[14px]">
            <p className="text-[12px] leading-[14.06px]">Add New</p>
            <img src={addnew} alt="" />
          </div>
        </NavLink>
      </div>

      <DataTable
        columns={columns}
        data={blackListNumbers?.data}
        selectableRows
        pagination
        className="px-[10px]"
        // onRowClicked={handleRowClick}
      />
    </div>
  );
};

export default Blacklist;
