import React, { useState } from "react";
import {BiMessage} from "react-icons/bi"
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { dropUpDownArrow } from "../../dropUpDownarrow";
import { PiSpeakerSimpleHigh } from "react-icons/pi";
import { RiMailSendLine } from "react-icons/ri";
const SMS = (props: any) => {
  const [dropDown, setDropDown] = useState(false);

  const active = useSelector((state: any) => state?.sideNavSlice?.activeNav);

  return (
    <div className="flex items-center flex-col gap-[8px] rounded-[6px]   w-[223px] cursor-pointer ">
      <div
        className={
          active === "sms"
            ? " nav_active flex items-center gap-[16px] pl-[18px] py-[12px]  nav_link w-full  rounded-[6px] group"
            : "flex items-center gap-[16px] py-[12px] pl-[18px] nav_link w-full  rounded-[6px] group"
        }
        onClick={() => {
          // opensms();
          setDropDown(!dropDown);
        }}
      >
        <div className="flex items-center gap-[16px]">
          <BiMessage />
          <p
            className={
              active === "sms"
                ? "text-[#fff] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
                : "text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] "
            }
          >
            SMS
          </p>
        </div>
        {dropUpDownArrow(dropDown)}
      </div>

      {dropDown && (
        <div className="w-full bg-[#006AAD1A] transition duration-300 ease-out flex flex-col gap-[8px]">
          <NavLink to="/sms/campaign-builder">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
            
                <PiSpeakerSimpleHigh />
            
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Campaign Builder
              </p>
            </div>
          </NavLink>

          <NavLink to="sms/quick-send">
            <div
              className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]"
              // onClick={() => navigate("sms/quick-send")}
            >
              <RiMailSendLine/>
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Quick send
              </p>
            </div>
          </NavLink>
          {/* <NavLink to="sms/send-file">
            <div className="flex w-full items-center gap-[16px] pl-[18px] py-[12px] group nav_link  rounded-[6px]">
              <GoFileDirectory />
              <p className="text-[#5F6368] text-[14px] leading-[16.41px] font-normal group-hover:text-[#F5F5F5] w-[130px] ">
                Send using File
              </p>
            </div>
          </NavLink> */}
        </div>
      )}
    </div>
  );
};

export default SMS;
