import { SET_CONTACT_GROUP } from "../constant/constant";

let initialState = {
  data: [],
};

export default function contactGroupReducer(state = initialState, action: any) {
  if (action.type === SET_CONTACT_GROUP) {
    return {
      ...state,
      data: action.payload,
    };
  }
  return state;
}
