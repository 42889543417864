import React from "react";
import EndPointComponent from "./EndPointComponent";
import ParamsTable from "./ParamsTable";

const ContactAPI = () => {
  return (
    <div>
      <h2 className=" text-orange-600">CONTACT API</h2>
      <p className="mt-3">
        EASYSMS Contacts API helps you manage contacts that are identified by a
        unique random ID. Using this ID, you can create, view, update, or delete
        contacts. This API works as a collection of customer-specific contacts
        that allows you to group them and assign custom values that you can
        later use when sending SMS template messages.
      </p>
      <br />
      The Contacts API uses HTTP verbs and a RESTful endpoint structure with an
      access key that is used as the API Authorization. Request and response
      payloads are formatted as JSON using UTF-8 encoding and URL encoded
      values.
      <br />
      <EndPointComponent />
      <h3>Parameters</h3>
      {/* table */}
      <ParamsTable />
    </div>
  );
};

export default ContactAPI;
