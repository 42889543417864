import { Box, Modal } from "@mui/material";
import { TiDeleteOutline } from "react-icons/ti";
import React, { useEffect, useState } from "react";
import { getDate } from "../utils/getData";
import axios from "axios";
import { baseUrl } from "../api/url";
import { getToken } from "../utils/tokenUtils";
import { MagnifyingGlass } from "react-loader-spinner";

const ViewMessageModalComponent = ({ modalOpen, modalHandle, messageId }) => {
  const [message, setMessage] = useState<any>();
  const token = getToken();
  useEffect(() => {
    if (messageId) {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}/customers/reports/${messageId}/view`,
        headers: {
          Authorization: `Bearer Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          const data = response?.data;
          // console.log(data);
          if (data?.status === "success") {
            setMessage(data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [messageId]);

  // console.log(message);
  return (
    <Modal
      open={modalOpen}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {message ? (
          <>
            <div className="flex justify-between items-center text-4xl ">
              <span></span>
              <span className="text-orange-700" onClick={modalHandle}>
                <TiDeleteOutline />
              </span>
            </div>
            <div className="flex flex-col w-full px-5 gap-2">
              <div className="w-1/2 ">
                <div className="flex gap-3 justify-between">
                  <span className="font-light">From</span>
                  <span>{message?.from}</span>
                </div>
                <div className="flex gap-3 justify-between">
                  <span className="font-light">To</span>
                  <span>{message?.to}</span>
                </div>
              </div>
              <div className="flex flex-col mb-5 mt-3">
                <span className="text-[var(--primary-color)]">Message</span>
                <style>
                  {`
                    .message-content {
                      max-height: 200px; /* Adjust the maximum height as needed */
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  `}
                </style>
                <span className="message-content">
                  {message?.message.split("/n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line.split("/t").map((tabbed, tabIndex) => (
                        <React.Fragment key={tabIndex}>
                          {tabIndex > 0 && "\t"}
                          {tabbed}
                        </React.Fragment>
                      ))}
                      <br />
                    </React.Fragment>
                  ))}
                </span>
              </div>
              <div className="flex gap-3 justify-between  border-b-2 pb-1">
                <span className="font-light">Type</span>
                <span>{message?.sms_type}</span>
              </div>
              <div className="flex gap-3 justify-between   border-b-2 pb-1">
                <span className="font-light">Status</span>

                <span
                  className={`${
                    message?.status === "Delivered"
                      ? "text-green-500 "
                      : "text-red-500"
                  }`}
                >
                  {message?.status}
                </span>
              </div>
              <div className="flex gap-3 justify-between   border-b-2 pb-1">
                <span className="font-light">Cost</span>
                <span>{message?.cost}</span>
              </div>
              {/* <span>{getDate(message?.created_at)}</span>
              <span>{message?.send_by}</span>
              <span>{message?.sms_type}</span>
              <span>{message?.cost}</span>
              <span>{message?.status}</span> */}
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center">
            <MagnifyingGlass
              visible={true}
              height="80"
              width="80"
              ariaLabel="MagnifyingGlass-loading"
              wrapperStyle={{}}
              wrapperClass="MagnifyingGlass-wrapper"
              glassColor="#ffffff"
              color="#000000"
            />
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default ViewMessageModalComponent;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid var(--primary-color)",
  boxShadow: 24,
  p: 4,
};
