import React, { useState, useEffect } from "react";
import { baseUrl } from "../../api/url";
import { getToken } from "../../utils/tokenUtils";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../../toastAlert.js/toastalert";
const UpdateContact = () => {
  const [phone, setPhone] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [id, setId] = useState<string>("");
  const navigate = useNavigate();
  const route = useParams();
  console.log(route);

  const handleUpdateContact = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const token = getToken();
    const data = JSON.stringify({
      phone: phone,
      first_name: firstName,
      last_name: lastName,
    });

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/contact_update/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        if (data?.status === "success") {
          console.log("not updated");
          showSuccessfulToastAlert(data?.message);
          navigate(-1);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log("not updated 2");
        console.log(error);
        showFailedToastAlert(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    // Getting ContactGroupsData
    let data = JSON.stringify({
      length: 10,
      start: 0,
      orderBy: "id",
      orderDir: "desc",
      search: "",
    });
    const token = getToken();

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/contacts/${route.uid}/getOneContact`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(response.data);
        const data = response?.data;
        if (data?.status === "success") {
          console.log(data);
          setPhone(data?.data?.phone);
          setFirstName(data?.data?.first_name);
          setLastName(data?.data?.last_name);
          setId(data?.data?.uid);
        } else {
          console.log(data?.message);
          //   showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        console.log(error?.message);
        // showFailedToastAlert(`Error getting Contact Groups ${error?.message}`);
      });
  }, []);

  return (
    <div className="bg-white mt-[20px] max-w-[549px] rounded-[5px] pb-[42px]">
      <div className="py-[23px] pl-[20px] border-b-[1px] border-[#D9D9D9] flex items-center justify-between pr-[18px]">
        <h2 className="text-[#515151] text-[16px] leading-[18.75px] font-bold">
          Update Contacts
        </h2>
      </div>
      <form
        onSubmit={(e) => {
          handleUpdateContact(e);
        }}
      >
        <div className="pl-[37px] pr-[38px] mt-[35px]">
          <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
            Phone*
          </p>
          <input
            type="text"
            className="border mt-[4px]  w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
        <div className="pl-[37px] pr-[38px] mt-[35px]">
          <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
            First Name
          </p>
          <input
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            value={firstName}
            className="border mt-[4px]  w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
          />
        </div>
        <div className="pl-[37px] pr-[38px] mt-[35px]">
          <p className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
            Last Name
          </p>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="border mt-[4px]  w-full placeholder:text-[#000000] placeholder:opacity-[0.5] pt-[11px] pl-[14px] pb-[12px] outline-none"
          />
        </div>

        <div className="bg-[#0092D7] w-[107px] h-[34px] rounded-[2px] text-white flex items-center justify-center mx-auto cursor-pointer mt-[34px]">
          <button type="submit">
            {" "}
            <p className="text-[14px] leading-[16.41px] font-normal">
              Save Changes
            </p>
          </button>
        </div>
      </form>
    </div>
  );
};

export default UpdateContact;
