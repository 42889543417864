import React, { useEffect, useState } from "react";
import { Select } from "antd";

import "react-toastify/dist/ReactToastify.css";
import { getToken } from "../utils/tokenUtils";
// import { setPhoneNumbers } from "../redux/sendingSlice";
import axios from "axios";
import { baseUrl } from "../api/url";
import {
  showFailedToastAlert,
  showSuccessfulToastAlert,
} from "../toastAlert.js/toastalert";
import FileUpload from "./FileUpload";
import TextPreview from "./TextPreview";
import ScheduleCampaign, { ScheduleCampaignData } from "./ScheduleCampaign";
import { checkUnicode } from "../utils/checkUnicode";
import { Oval } from "react-loader-spinner";

const CampaignBuilder = () => {
  // ----------------App State --------------------
  const [name, setName] = useState("");
  const [sendingServers, setSendingServers] = useState([]);
  const [senderId, setSenderId] = useState("");
  const [templates, setTemplates] = useState([
    { value: "Please Select Template" },
  ]);
  const [loading, setLoading] = useState(false);
  const [contactGroups, setContactGroups] = useState([
    { value: "Please Select Contact Group" },
  ]);
  const [manualInput, setManualInput] = useState("");
  const [selectedDifferentiator, setSelectedDifferentiator] = useState<
    string | null
  >(",");
  // ----------------App State End ----------------
  // ----------------Datas to submit ----------------
  const [submitableServer, setSubmitableServer] = useState<any>("");
  const [serverID, setServerId] = useState<any>("");
  const [contactGroupIDs, setContactGroupIDs] = useState<string[]>([]);

  const [submitableTemlate, setSubmitableTemplate] = useState<any>("");
  const [submitableContactGroups, setSubmitableContactGroups] = useState(null);
  // ----------------Datas to submit ------------
  // ----------------small form datas -------------
  const [smallSendingServerData, setSmallSendingServerData] = useState<any>([]);
  const [smallTemplateData, setSmallTemplateData] = useState<any>([]);
  const [smallGroupsData, setSmallGroupsData] = useState([]);
  // ----------------small form dats end ----------
  const token = getToken();
  const [language, setLanguage] = useState("en");
  const [message1, setMessage1] = useState("");
  const [senderIdData, setSenderIdData] = useState([
    { value: "Please Select Sender Id" },
  ]);

  const [templateData, setTemplateData] = useState([
    { value: "Please Select template" },
  ]);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [validPhoneNumbers, setValidPhoneNumbers] = useState<string[]>([]);
  const [isTick, setIsTick] = useState(false);
  const [campaignData, setCampaignData] = useState<ScheduleCampaignData>({
    date: "",
    time: "",
    frequency: "",
  });

  const handleTickChange = () => {
    setIsTick(!isTick);
  };

  const handleCampaignDataChange = (data: ScheduleCampaignData) => {
    setCampaignData(data);
  };

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  const differentiatorItems = [
    { value: ",", label: "(Comma)" },
    { value: ";", label: "(Semi colon)" },
    { value: "|", label: "(Bar)" },
    { value: "\t", label: "Tab" },
    { value: "\n", label: "New line" },
  ];

  // starting code for message credit and showing remaining letter
  let messageCount = 160;
  const max = language === "en" ? 160 : 70;
  let messageRemaining =
    language === "en"
      ? max - (message1.length % 160)
      : max - (message1.length % 70);
  // ending code for message credit and showing remaining letter

  // -------------------getting form data ---------------
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/sms/campaign-builder`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        if (data?.status === "success") {
          //------------getting sending servers -----------------
          let tempServers: any = [];
          data?.data?.sending_server.map((items: any, index: any) => {
            tempServers.push({
              id: items?.id,
              uid: items?.uid,
              name: items?.name,
              settings: items?.settings,
            });
          });
          setSmallSendingServerData(tempServers);
          //------------getting sending servers end -------------
          //------------getting sms templates ---------------------------
          let tempTemplates: any = [];
          data?.data?.templates?.map((items: any, index: any) => {
            tempTemplates.push({
              id: items?.id,
              uid: items?.uid,
              name: items?.name,
              message: items?.message,
            });
          });
          setSmallTemplateData(tempTemplates);
          // -----------getting sms Templates end ----------------------
          // -----------getting Contact groups -------------------
          let tempGroups: any = [];
          const contactGroups = data?.data?.contact_groups?.map(
            (items: any, index: any) => {
              return {
                label: `${items?.name} (${items?.subscriberCount} contacts)`,
                value: items?.id,
              };
            }
          );
          setContactGroups(contactGroups);
          setSmallGroupsData(tempGroups);
          // -----------getting Contact groups end --------------
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.response?.data?.message);
      });
  }, []);
  // ----------------getting form data end -----------

  // getting sendier ids
  useEffect(() => {
    getSenderIds();
    getSMSTemplates();
  }, []);

  // ---------------updating form sending server data --------
  useEffect(() => {
    let tempServers: any = [];
    if (smallSendingServerData?.length) {
      smallSendingServerData?.map((items: any, index: any) => {
        tempServers.push({ value: items?.name });
      });
      setSendingServers(tempServers);
    }
  }, [smallSendingServerData]);
  // ---------------updating form sending server data end ------

  // --------------updating form templates --------------------
  useEffect(() => {
    let tempTemplates: any = [];
    if (smallTemplateData?.length) {
      smallTemplateData?.map((items: any, index: any) => {
        tempTemplates.push({ value: items?.name });
      });
      setTemplates(tempTemplates);
    }
  }, [smallTemplateData]);
  // --------------updating form templates end ----------------

  // --------------updating form contact groups -------------

  // -------------getting submitable server ----------------
  useEffect(() => {
    if (smallSendingServerData?.length > 0) {
      smallSendingServerData?.map((items: any, index: any) => {
        if (items?.name === submitableServer) {
          setServerId(items?.id);
        }
      });
    }
  }, [submitableServer]);
  // -------------getting submitable server end ------------

  // -------------getting contact groups ID-------------
  useEffect(() => {
    let temp: any = [];
    if (smallGroupsData?.length) {
      smallGroupsData?.map((items: any, index: any) => {
        if (items?.name === submitableContactGroups) {
          temp?.push([(items?.id).toString()]);
        }
      });
      setContactGroupIDs(temp);
    }
  }, [submitableContactGroups]);
  // -------------getting contact groups ID end --------

  // -------------event handles ----------------------------
  const handleNameChange = (event: any) => {
    setName(event?.target?.value);
  };

  const handleServerChange = (value: any) => {
    setSubmitableServer(value);
  };

  const handleSenderIDChange = (event: any) => {
    setSenderId(event);
  };

  const handleTemplateChange = (value: any) => {
    setSubmitableTemplate(value);
  };

  // const handleMessageChange = (event: any) => {
  //   setMessage(event?.target?.value);
  // };

  const handleChange = (event: any) => {
    const { value } = event.target;
    if (checkUnicode(value)) {
      setLanguage("nep");
    }
    setMessage1(value);
    messageCount = message1.length;
  };
  const handleContactGroupsChange = (selectedIDs: string[]) => {
    setContactGroupIDs(selectedIDs);
  };

  const handleManualInputChange = (event: any) => {
    setManualInput(event?.target?.value);
  };

  const handleDiffentiatorChange = (value: any) => {
    setSelectedDifferentiator(value);
  };

  // -------------event handles end ------------------------

  // ------------- form submit ----------------------------
  const handleSubmit = (event: any) => {
    setLoading(true);
    event.preventDefault();
    // ----------DATA LOGS ---------------
    // ----------DataLogs ----------------

    let data = JSON.stringify({
      name: name,
      // sending_server: serverID,
      sender_id: [`${senderIdData[0]["value"]}`.toString()],
      contact_groups: contactGroupIDs,
      delimiter: selectedDifferentiator,
      message: message1,
      timezone: "Asia/Kathmandu",
      // sms_type: "plain",
      plan_id: "1",
      country_code: "977",
      originator: "sender_id",
      recipients: [manualInput, ...validPhoneNumbers],
      frequency_cycle:
        campaignData.frequency === "" ? null : campaignData.frequency,
      frequency_amount:
        campaignData.frequencyAmount === ""
          ? null
          : campaignData.frequencyAmount,
      frequency_unit:
        campaignData.frequencyAmount === ""
          ? null
          : campaignData.frequencyAmount,
      recurring_date: campaignData.endDate === "" ? null : campaignData.endDate,
      recurring_time: campaignData.endTime === "" ? null : campaignData.endTime,
      schedule_date: campaignData.date === "" ? null : campaignData.date,
      schedule_time: campaignData.time === "" ? null : campaignData.time,
    });
    console.log("campaign data", data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/sms/campaign-builder`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        setLoading(false);
        if (data?.status === "success") {
          showSuccessfulToastAlert(data?.message);
          // window.location.reload();
        } else {
          showFailedToastAlert(data?.message);
          // window.location.reload();
        }
      })
      .catch((error) => {
        setLoading(false);
        showFailedToastAlert(error?.response?.data?.message);
        // window.location.reload();
      });
  };
  // -------------------- form submit end -------------------

  // get sender ids
  const getSenderIds = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/senderid`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        if (data?.status === "success") {
          const returnData = data.data.map((element) => {
            return { value: element.sender_id };
          });

          setSenderIdData(returnData);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.response?.data?.message);
      });
  };




   const getSMSTemplates = () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/customers/templates`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response?.data;
        if (data?.status === "success") {
          const returnData = data.data.map((element) => {
            return { value: element.name };
          });

          setTemplateData(returnData);
        } else {
          showFailedToastAlert(data?.message);
        }
      })
      .catch((error) => {
        showFailedToastAlert(error?.response?.data?.message);
      });
  };

  return (
    <div className="bg-white mt-2 pb-[14px] flex flex-col  rounded-xl shadow">
      <div className="border-b-[1px] border-[#D9D9D9]">
        <h2 className="text-[#515151] text-[16px] leading-[18.75px] font-medium py-[14px] pl-[20px]">
          Campaign Builder
        </h2>
      </div>
      <form action="" className="grid md:grid-cols-2 md:gap-[48px] px-12 ">
        <div>
          <div className="mt-[20px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Name *
            </h2>
            <input
              name="name"
              type="text"
              placeholder="Required"
              required
              className="border border-[#D9D9D9] bg-white pl-[14px] outline-none h-[38px] w-full mt-[8px] placeholder:text-[#000] placeholder:text-[13px] text-[13px] leading-[15.23px] placeholder:opacity-[0.5] placeholder:font-normal"
              onChange={handleNameChange}
            />
          </div>
          <div className="mt-[20px]">
            <div className="mt-[14px] gap-[24px]">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal mb-[8px]">
                    Sender ID
                  </p>
                </div>
                <Select
                  options={senderIdData}
                  style={{ width: "100%" }}
                  aria-required={true}
                  onChange={handleSenderIDChange}
                  // defaultValue={senderIdData[0]}
                  value={senderId}
                />
              </div>
            </div>
          </div>

          <div className="mt-[18px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Message *
            </h2>
            <textarea
              lang={`${language === "en" ? "en" : "ne"}`}
              style={{
                fontFamily: `${
                  language === "en" ? "Arial Helvetica sans-serif" : "Preeti"
                }`,
              }}
              onChange={handleChange}
              name="message"
              id="w3review"
              value={message1}
              // name="w3review"
              className="border w-full bg-white h-[104px] mt-[8px]  rounded-[1px] pt-[12px] pl-[14px] outline-none"
            ></textarea>
            <div className="flex justify-between text-[12px] ">
              <span>Remaining:{messageRemaining}</span>
              <span>
                Credit:{" "}
                {language === "en"
                  ? message1.length >= 160
                    ? Math.ceil(message1.length / 153)
                    : Math.ceil(message1.length / 160)
                  : message1.length >= 70
                  ? Math.ceil(message1.length / 67)
                  : Math.ceil(message1.length / 70)}
              </span>
            </div>
          </div>

          <div className="">
            <FileUpload
              onFileSelect={handleFileSelect}
              setValidPhoneNumbers={setValidPhoneNumbers}
            />
          </div>
          <TextPreview
            file={selectedFile}
            setValidPhoneNumbers={setValidPhoneNumbers}
          />

          {/* schedule campaign */}
        </div>

        <div>
          {/* -------------------Reciepient End----------------- */}
          <div className="mt-[20px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal ">
              Contact Groups
            </h2>
            <Select
              mode="multiple" // Set the mode to allow multiple selections
              style={{ width: "100%" }}
              onChange={handleContactGroupsChange}
              className="mt-[8px]"
              options={contactGroups}
            />
          </div>

          <div className="mt-[20px]">
            <div className="mt-[14px] gap-[24px]">
              <div className="">
                <div className="flex items-center gap-[8px]">
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    SMS Template
                  </p>
                </div>
                {/* <input type="text" className='border w-full h-[38px] outline-none pl-[15px] mt-[8px]' /> */}
                <Select
                  
                  style={{ width: "100%" }}
                  onChange={handleTemplateChange}
                  className="mt-[8px]"
                  options={templateData}
                  // defaultValue={templateData[0]}
                  value={submitableTemlate}
                />
              </div>
              {/* <div className="mt-[14px]">
                <div className="flex items-center gap-[8px]">
                  <p className="text-[13px] text-[#545454] leading-[15.23px] font-normal">
                    Choose Language
                  </p>
                </div>
                
                <select
                  value={language}
                  style={{ width: "100%" }}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="mt-[8px] border-[1px] border-[#D9D9D9] bg-white text-sm p-2"
                >
                  <option className="bg-white " value="en">
                    Plain
                  </option>
                  <option className="bg-white " value="nl">
                    Unicode
                  </option>
                </select>
              </div> */}
            </div>
          </div>
          {/* File upload */}

          <div className="mt-[18px]">
            <h2 className="text-[#545454] text-[13px] leading-[15.23px] font-normal">
              Manual Input
            </h2>
            <textarea
              onChange={handleManualInputChange}
              id="w3review"
              name="manualInput"
              className="border w-full bg-white h-[104px] mt-[8px] rounded-[1px] pt-[12px] pl-[14px] outline-none placeholder:text-xs"
              placeholder="Enter Phone numbers by delimiter ' , ' example: 9812352525,9852415236"
            ></textarea>
          </div>

          <div className="mt-[20px] pt-4 grid grid-cols-3 xl:grid-cols-5  w-full gap-1">
            {differentiatorItems.map((item) => (
              <div
                key={item.value}
                onClick={() => handleDiffentiatorChange(item.value)}
                className={`flex items-center justify-center border  border-[#0092D7] h-[48px] px-4 ${
                  selectedDifferentiator === item.value
                    ? "bg-[#0092D71A]"
                    : "hover:bg-[#0092D71A] group"
                }`}
              >
                <p
                  className={
                    selectedDifferentiator === item.value
                      ? "text-[#0092D7]"
                      : "group-hover:text-[#0092D7]"
                  }
                >
                  {item.value}
                </p>
                <p
                  className={`text-[#5F6368] text-[12px] leading-[15.23px] font-normal whitespace-nowrap ${
                    selectedDifferentiator === item.value
                      ? "text-[#0092D7]"
                      : "group-hover:text-[#0092D7]"
                  }`}
                >
                  {item.label}
                </p>
              </div>
            ))}
          </div>
        </div>
      </form>
      <div className="bg-[#0092D71A] mt-6 mx-12 px-[6px] cursor-pointer">
        <p className="text-[#003D7E] text-[13px] leading-[20.8px font-normal]">
          Note: You can upload a maximum of 1000 rows by copy-pasting. If you
          have more numbers you'd like to send in one go, please create a
          contact group and insert your contacts. Every number needs to include
          a country code for a successful import.
        </p>
      </div>
      <div className="mt-2 mx-12 relative">
        <input
          type="checkbox"
          checked={isTick}
          onChange={handleTickChange}
          className="mr-[10px]"
        />
        <label className="text-[14px]">Schedule Campaign</label>
        <ScheduleCampaign
          isVisible={isTick}
          onCampaignDataChange={handleCampaignDataChange}
        />
      </div>
      <button
        className="w-[120px] h-[38px] flex items-center justify-center self-end text-white rounded-[4px] cursor-pointer bg-[#0092D7] mt-6 mr-[72px]"
        onClick={handleSubmit}
        disabled={loading}
      >
        <p className="text-[14px] leading-[16.41px] font-normal">Save</p>
      </button>


      {loading ? (
          <div className="fullWindow-Spinner">
            <div>
              <Oval visible={loading}></Oval>
            </div>
            <div style={{ fontSize: "16px", marginTop: "15px" }}>
              Please Wait. Submitting...
            </div>
          </div>
        ) : null}
    </div>
  );
};

export default CampaignBuilder;
