import React from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Switch, Space } from 'antd';

const Import = () => {
    return (
        <div className='bg-white mt-[20px] max-w-[549px] rounded-[5px] pb-[42px]'>
            <div className='py-[23px] pl-[20px] border-b-[1px] border-[#D9D9D9] flex items-center justify-between pr-[18px]'>
                <h2 className='text-[#515151] text-[16px] leading-[18.75px] font-medium'>Import List</h2>
                <a href="" className='underline text-[#0092D7] decoration-[#0092D7] text-[13px] leading-[15.23px]'>Download Sample File</a>
            </div>
            <Space direction="vertical" className='mt-[25px] ml-[440px]'>
                <Switch checkedChildren="Import File" unCheckedChildren="Import File" defaultChecked />
               
            </Space>
            <div className='border border-dashed h-[214px]  border-[#0092D7] mt-[24px]  relative cursor-pointer ml-[26px] mr-[20px] bg-[#0092D70D]'>
                <div className='flex items-center justify-center flex-col absolute top-[37px] left-[50%] transform translate-x-[-50%]'>
                    <p className='text-[#0092D7] text-[14px] leading-[16.41px] font-normal'>Click here to upload</p>
                    <svg width="64" height="52" viewBox="0 0 64 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.871 23.1978C6.85087 25.1622 5.85544 27.5311 5.85544 30.3333C5.85544 33.1356 6.85087 35.5044 8.871 37.5556C10.8618 39.4622 13.2626 40.4444 16.1025 40.4444H35.3962C35.2498 41.3978 35.1327 42.3511 35.1327 43.3333C35.1327 44.3156 35.2498 45.2689 35.3962 46.2222H16.1025C11.7109 46.2222 7.87557 44.7778 4.71363 41.6867C1.58097 38.6533 0 34.9267 0 30.5644C0 26.8089 1.14181 23.4578 3.42543 20.5111C5.70906 17.5644 8.78316 15.6867 12.4428 14.8778C13.6725 10.4578 16.1025 6.87556 19.7621 4.13111C23.4218 1.38667 27.5791 0 32.2049 0C37.914 0 42.7447 1.96444 46.7264 5.89333C50.7081 9.82222 52.699 14.5889 52.699 20.2222C56.0659 20.5978 58.8472 22.0422 61.0723 24.5556C62.5654 26.2022 63.5316 28.0511 64 30.1022C60.8525 27.4434 56.8423 25.9877 52.699 26H46.8435V20.2222C46.8435 16.2356 45.3797 12.8267 42.5691 9.99556C39.6999 7.22222 36.2452 5.77778 32.2049 5.77778C28.1647 5.77778 24.71 7.22222 21.8408 9.99556C19.0302 12.8267 17.5663 16.2356 17.5663 20.2222H16.1025C13.2626 20.2222 10.8618 21.2044 8.871 23.1978ZM43.9158 40.4444H49.7713V52H55.6267V40.4444H61.4822L52.699 31.7778L43.9158 40.4444Z" fill="#0092D7" />
                    </svg>

                </div>
                <p className='text-[#5F6368] text-[12px] leading-[19.2px] font-normal max-w-[413px] absolute left-[19px] bottom-[24px]'>The file must contains phone column, include a country code at the beginning for a successful import. Supported file: csv, xls, xlsx</p>

            </div>
            <div className='flex items-center mt-[12px] gap-[10px] ml-[26px]'>
                <input type="checkbox" className='w-[14px] h-[14px]' />
                <p className='text-[#545454] text-[13px] leading-[15.23px] font-normal'>File Contains header Rows ?</p>

            </div>
            <div className='w-[120px] h-[38px] flex items-center justify-center ml-[410px] text-white rounded-[2px] cursor-pointer bg-[#0092D7] mt-[35px] '>
                <p className='text-[14px] leading-[16.41px] font-normal'>Save</p>
            </div>
        </div>
    )
}

export default Import